import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Admin } from 'types/dashboard/Dashboard'
import { admin_role } from 'types/dashboard/Dashboard'

//icons
import caretdown from 'assets/icons/caret-down.svg'
import blackcalendar from 'assets/icons/black-calendar.svg'

import { getOpsAdmins } from 'helpers/getOpsAdmins'

interface OpsMemberType {
  admins: Admin[]
  status: string
  removeDateSection?: boolean
  setStatus: React.Dispatch<React.SetStateAction<string>>
  selectedOpsMember: {
    firstname: string
    lastname: string
    id: string
  }
  setSelectedOpsMember: React.Dispatch<
    React.SetStateAction<{
      firstname: string
      lastname: string
      id: string
    }>
  >
  totalTask?: number
}

const OpsMemberTask = ({ admins, selectedOpsMember, setSelectedOpsMember, status, setStatus, totalTask, removeDateSection }: OpsMemberType) => {
  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  let opsAdmin: { firstname: string; lastname: string; id: string }[] = getOpsAdmins(admins)

  const [showStatus, setShowStatus] = useState<boolean>(false)

  const [showOpsMember, setShowOpsMember] = useState<boolean>(false)

  const options: string[] = ['all tasks', 'completed', 'cancelled', 'incoming', 'ongoing', 'on-track']

  const statusref = useRef<HTMLDivElement>(null)
  const opsref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showStatus && statusref.current && !statusref.current.contains(e.target)) {
        setShowStatus(false)
      }
      if (showOpsMember && opsref.current && !opsref.current.contains(e.target)) {
        setShowOpsMember(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showStatus, showOpsMember])

  return (
    <div className={`flex  items-center gap-x-4 mt-[18px] ${totalTask === 0 ? 'mb-6' : 'mb-8'}`}>
      {admin_role[admin_data.role] === admin_role['super_admin'] ? (
        <div className='relative' ref={opsref}>
          <div
            className='flex items-center gap-x-2 w-fit px-3 py-2 rounded-lg cursor-pointer solid-br bg-[#F9FAFB]'
            onClick={() => setShowOpsMember(!showOpsMember)}
          >
            <div className='text-[10px] text-white flex justify-center items-center h-[20px] w-[20px] rounded-full bg-[#286A3A] uppercase'>
              {selectedOpsMember?.firstname?.charAt(0)}
            </div>
            <p className='text-black font-light text-sm capitalize'>{selectedOpsMember?.firstname}'s Tasks</p>
            <img src={caretdown} alt='caret' />
          </div>
          {showOpsMember && (
            <div className='absolute w-[180px] break-words p-1 text-sm black-text-3 bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded z-30 h-[300px] overflow-y-auto'>
              <div>
                {[{ firstname: 'everyone', lastname: '', id: '' }, ...opsAdmin].map((option) => (
                  <p
                    className={`p-2 cursor-pointer capitalize ${
                      selectedOpsMember?.id === option?.id ? 'bg-green-100 text-[#139C33]' : 'text-black hover:bg-slate-100'
                    } font-light rounded`}
                    onClick={() => {
                      setSelectedOpsMember(option)
                      setShowOpsMember(false)
                    }}
                  >
                    {option.firstname + ' ' + option.lastname}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='flex items-center gap-x-2 bg-[#F9FAFB] w-fit px-3 py-2 rounded-lg'>
          <div className='text-[10px] text-white flex justify-center items-center h-[20px] w-[20px] rounded-full bg-[#286A3A] uppercase font-medium'>
            {admin_data?.firstname?.charAt(0)}
          </div>
          <p className='text-black font-light text-sm capitalize'>{admin_data?.firstname}</p>
        </div>
      )}

      {!removeDateSection && (
        <div className='relative' ref={statusref}>
          <div
            className='flex items-center gap-x-2 bg-[#F9FAFB] w-fit px-3 py-2 cursor-pointer rounded-lg solid-br'
            onClick={() => setShowStatus(!showStatus)}
          >
            <img src={blackcalendar} alt='calendar' />
            <p className='text-black font-light text-sm capitalize'>{status ? status : 'All Tasks'}</p>
            <img src={caretdown} alt='caret' />
          </div>
          {showStatus && (
            <div className='absolute w-[125px] p-1 text-sm black-text-3 bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded'>
              {options.map((option) => (
                <p
                  className={`p-2 cursor-pointer capitalize ${
                    status === option ? 'bg-green-100 text-[#139C33]' : 'text-black hover:bg-slate-100'
                  } font-light rounded`}
                  onClick={() => {
                    setStatus(option)
                    setShowStatus(false)
                  }}
                >
                  {option}
                </p>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default OpsMemberTask
