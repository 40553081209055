import { GET_BOOKINGS, API_ERROR } from 'store/bookings/constants'

const initialState = {
  error: null,
  loading: true,
  shipment_report: [],
  total_shipment_report: null,
  table_loading: false,
  // single_booking: {},
}

const bookings = (state = initialState, action) => {
  // console.log('shipment report', action.payload)
  switch (action.type) {
    case GET_BOOKINGS.SUCCESS:
      return {
        ...state,
        loading: false,
        shipment_report: action.payload.shipment_report,
        total_shipment_report: action.payload.total_shipment_report,
        table_loading: false,
      }

    case GET_BOOKINGS.REQUEST:
      return {
        ...state,
        table_loading: true,
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default bookings
