import { generateActions } from 'helpers/generateActions'

export const GET_ALL_TASKS = generateActions('GET_ALL_TASKS')
export const GET_ALL_TASKS_CSV = generateActions('GET_ALL_TASKS_CSV')

export const GET_TASKS = generateActions('GET_TASKS')

export const GET_ALL_WORKFLOW_TASKS = generateActions('GET_ALL_WORKFLOW_TASKS')

export const GET_WORKFLOW_TASK_BY_ID = generateActions('GET_WORKFLOW_TASK_BY_ID')

export const COMPLETE_WORKFLOW_TASK = generateActions('COMPLETE_WORKFLOW_TASK')

export const SAVE_WORKFLOW_CONTAINER = generateActions('SAVE_WORKFLOW_CONTAINER')

export const ESCALATE_WORKFLOW_TASK = generateActions('ESCALATE_WORKFLOW_TASK')
export const REASSIGN_TASK = generateActions('REASSIGN_TASK')

export const GET_DELAYED_TASKS = generateActions('GET_DELAYED_TASKS')

export const ATTACH_DOC_TO_TASK = generateActions('ATTACH_DOC_TO_TASK')

export const API_ERROR = 'API_ERROR'
