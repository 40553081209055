import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import Layout from 'components/layout/Layout'
import PageLoading from 'components/partials/pageLoading'
import PageTitle from 'components/partials/PageTitle'
import CustomTabs from 'components/customTabs/serviceCustomTab'
import ReportAntTable from 'components/reports/shipments/ReportAntTable'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import ReportFilter from 'components/reports/shipments/ReportsFilter'

import filter from 'assets/icons/Filter-grey.svg'
import search from 'assets/icons/search.svg'
import divider from 'assets/icons/divider.svg'
import tray from 'assets/icons/tray.svg'

import { getShipmentReport, getShipmentReportCsv } from 'store/actions'

import { ReportShipmentType, FilterObjectType, ReportType, ShipmentTabType } from 'types/reports/Shipments'
import { set } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getOpsAdmins } from 'helpers/getOpsAdmins'
import { getServiceCombinations } from 'helpers/getServiceCombinations'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'
import { countryMap } from 'helpers/countryMapping'

// const Shipments = (props: any) => {
const Shipments = ({
  loading,
  shipment_report,
  shipment_report_csv,
  getShipmentReport,
  getShipmentReportCsv,
  total_shipment_report,
  table_loading,
  admins: remoteAdmins,
  getAdmins,
  country_code,
}: ReportType) => {
  // const { loading, shipment_report, getShipmentReport, total_shipment_report, table_loading } = props
  // @ts-ignore

  const location = useLocation()

  const [tab, setTab] = useState('Active')
  const tab_result = ['Active', 'Completed']

  const [isCompleted, setIsCompleted] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const [pageLoading, setPageLoading] = useState(false)
  const [filteredShipmentReports, setfilteredShipmentReports] = useState<ReportShipmentType[]>([])

  const [filterDisplay, setFilterDisplay] = useState<boolean>(false)
  const [mobilefilterDisplay, setMobileFilterDisplay] = useState(false)
  const [exportMain, setExportMain] = useState<Boolean>(false)
  const [importMain, setImportMain] = useState<Boolean>(false)

  const [filteredReport, setFilteredReport] = useState(false)
  const [filterObject, setFilterObject] = useState<FilterObjectType>({ page: '0', count: '10', status: 'active', country: countryMap[country_code] })

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [clear, setClear] = useState(false)

  const [page, setPage] = useState(0)
  const [startNum, setStartNum] = useState(1)
  const [endNum, setEndNum] = useState(10)
  const [delayed, setDelayed] = useState<boolean | string>('')
  const [onTrack, setOnTrack] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)
  const filter_ref = useRef<HTMLDivElement>(null)
  const mobilefilter_ref = useRef<HTMLDivElement>(null)
  const scrollref = useRef<HTMLDivElement>(null)
  const storedCountry = localStorage.getItem('country')

  const navigate = useNavigate()

  const fetchShipmentReport = () => {
    //@ts-ignore
    const filter_string = new URLSearchParams(filterObject).toString()

    setPageLoading(true)
    getShipmentReport(filter_string)
  }

  const handlePageChange = (page) => {
    setFilterObject({ ...filterObject, page: String(page - 1) })
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setFilterObject({
      ...filterObject,
      page: String(page),
      count: String(newPerPage),
    })
  }

  useEffect(() => {
    fetchShipmentReport()
  }, [filterObject, tab])

  useEffect(() => {
    getShipmentReportCsv('')
  }, [])

  useEffect(() => {
    if (shipment_report) {
      setfilteredShipmentReports(shipment_report)
    }
  }, [shipment_report])

  // const switchStatus = (complete: boolean) => {
  //   const status = complete ? 'completed' : 'active'
  //   setFilterObject({ ...filterObject, status })
  //   // console.log(filterObject, 'filterObject')
  // }

  const switchStatus = (complete: boolean) => {
    //@ts-ignore
    setTab(complete ? 'Completed' : 'Active')
    setFilterObject({
      ...filterObject,
      status: complete ? 'completed' : 'active',
    })
  }

  const handleSearch: (value: string, type?: string) => void = (value, type) => {
    console.log('Entering search')
    if (value) {
      setFilterObject({ ...filterObject, page: '0', search: value })
    } else {
      setFilterObject({ ...filterObject, page: '0', search: value })
    }
  }

  const handleFilter = (value: any, type: string) => {
    if (value) {
      setFilterObject({
        ...filterObject,
        ...{
          page: '0',
          service_type: value.service_type,
          shipment_type: value.shipment_type,
          assignee_id: value.assignee_id,
          is_delayed: value.is_delayed,
        },
      })
    }
  }

  // useEffect to handle country filter
  useEffect(() => {
    setFilterObject({
      ...filterObject,
      ...{
        page: '0',

        country: countryMap[country_code],
      },
    })
  }, [country_code])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (filterDisplay && filter_ref.current && !filter_ref.current.contains(e.target)) {
        setFilterDisplay(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filterDisplay])

  useEffect(() => {
    // Check if the URL query parameter is set to 'completed'
    if (location.search === '?completed') {
      // Set the active tab to 'Completed' & status to 'completed'
      switchStatus(true)
      setTab('Completed')
    } else {
      setTab('Active')
      switchStatus(false)
    }
  }, [location.search])

  console.log('loading', loading)
  console.log('table loading', table_loading)
  console.log('-----------------------------')
  return (
    <Layout>
      <div className='dashboard-content-scroll'>
        {loading ? (
          <div>
            <div className='mt-3'>
              <PageLoading title={'Reports'} />
            </div>
          </div>
        ) : (
          <>
            <div ref={scrollref}>
              <div className='mx-7 md:pt-10 lg:mx-14 lg:pt-10 mb-6 lg:mb-0'>
                <PageTitle text={'Shipment Reports'} subtext='Here’s a list of all your shipment reports.' />
              </div>

              <div className='lg:mt-9 w-full h-fit '>
                <div className='mt-4 w-full'>
                  <div className='desktop-only relative md:mx-7 lg:mx-14'>
                    {table_loading && (
                      <div className='absolute h-full w-full bg-[#7c8d788c] z-[999999] flex justify-center'>
                        <div className='pt-5'>
                          <LoadingSpinner top={false} height='10' loadingText='Loading table data' />
                        </div>
                      </div>
                    )}
                    <div className='pb-8 mt-[-12px]'>
                      {/* @ts-ignore */}
                      <CustomTabs
                        className='py-4 px-4'
                        tabs={tab_result}
                        activeTab={tab}
                        setActiveTab={setTab}
                        setIsCompleted={setIsCompleted}
                        switchStatus={switchStatus}
                      />
                      <hr style={{ marginTop: '-1px', marginBottom: '30px' }} />
                      <div ref={filter_ref} className='absolute w-[336px] left-[280px] top-12 z-50 '>
                        <ReportFilter
                          handleFilter={handleFilter}
                          filterDisplay={filterDisplay}
                          filteredShipmentReports={filteredReport}
                          setFilterDisplay={setFilterDisplay}
                          exportMain={exportMain}
                          setExportMain={setExportMain}
                          importMain={importMain}
                          setImportMain={setImportMain}
                          loading={loading}
                          onTrack={onTrack}
                          setOnTrack={setOnTrack}
                          delayed={delayed}
                          setDelayed={setDelayed}
                        />
                      </div>
                      <ReportAntTable
                        filteredShipmentReports={filteredShipmentReports}
                        shipment_report_csv={shipment_report_csv}
                        total_shipment_report={total_shipment_report}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        handleSearch={handleSearch}
                        setFilterDisplay={setFilterDisplay}
                        filterObject={filterObject}
                        tab={tab}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* mobile only */}

              {!loading && (
                <div className='mb-6 mobile-only'>
                  <div className='p-6'>
                    {/* @ts-ignore */}
                    <CustomTabs
                      className='py-4 px-4'
                      tabs={tab_result}
                      activeTab={tab}
                      setActiveTab={setTab}
                      setIsCompleted={setIsCompleted}
                      switchStatus={switchStatus}
                    />
                    <hr style={{ marginTop: '-1px' }} />
                  </div>
                  <div className=' flex sm:items-center flex-col justify-between sm:flex-row sm:justify-start gap-[16px] mx-[24px] mb-[24px] '>
                    {/* search */}
                    <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input w-[100%] sm:w-[auto] black-text mb-5 mr-6'>
                      <div className='min-w-[16px] h-[16px] '>
                        <img src={search} alt=''></img>
                      </div>
                      <input placeholder='Search Shipment Report' className='w-[100%]' onChange={(e) => handleSearch(e.target.value, 'search')} />
                    </div>

                    <div
                      className='cursor-pointer px-4 flex items-center border border-[#e5e7eb] rounded min-w-[90px] w-[15%] h-[37.6px] mt-[-20px]'
                      onClick={() => setMobileFilterDisplay(true)}
                    >
                      <div className='min-w-[16px] h-[16px]'>
                        <img src={filter} alt='' className='w-[100%]'></img>
                      </div>
                      <p className='text-xs text-[#9CA3AF]'>Filter</p>
                    </div>
                  </div>

                  {/* filter page */}
                  <div className='fixed top-0 w-full ' ref={mobilefilter_ref}>
                    <ReportFilter
                      handleFilter={handleFilter}
                      filterDisplay={mobilefilterDisplay}
                      filteredShipmentReports={filteredReport}
                      setFilterDisplay={setMobileFilterDisplay}
                      exportMain={exportMain}
                      setExportMain={setExportMain}
                      importMain={importMain}
                      setImportMain={setImportMain}
                      loading={loading}
                      onTrack={onTrack}
                      setOnTrack={setOnTrack}
                      delayed={delayed}
                      setDelayed={setDelayed}
                    />
                  </div>

                  {filteredShipmentReports?.length > 0
                    ? filteredShipmentReports.map((data, index: number) => {
                        return (
                          <div
                            className={`bg-white mx-[24px] mb-5 border border-grey-3 py-4 top-divider-2 ${index === 0 ? 'top-divider-2' : ''}`}
                            onClick={() => {
                              navigate(`/reports/shipments/${data?.['_id']}`, {
                                state: {
                                  id: data?.['_id'],
                                  reportData: data,
                                },
                              })
                              console.log('reportData', data)
                            }}
                          >
                            <div className='flex  justify-between gap-[20px] items-center px-[16px]'>
                              <div className=''>
                                <div className='flex max-[380px]:flex-col  min-[381px]:flex-row max-[380px]:gap-[5px]  min-[381px]:gap-[15px] max-[380px]:items-start  min-[381px]:items-center mb-2  '>
                                  {/* shipment type */}
                                  <div>
                                    {data?.shipment_details?.shipment_type === 'export' && <p className='text-md black-text-3 font-medium'>Export</p>}
                                    {data?.shipment_details?.shipment_type === 'import' && <p className='text-md black-text-3 font-medium'>Import</p>}
                                  </div>
                                  <img src={divider} alt='divider' className='max-[380px]:hidden  min-[381px]:block' />
                                  <div>{getMultipleServiceCombinations(data?.shipment_details)}</div>
                                </div>
                                <div className='flex justify-between  '>
                                  <p className='text-[#296FD8] text-xs'>{data?.shipment_details?.bl_number}</p>
                                </div>
                              </div>

                              {tab == 'Active' &&
                                (data?.is_delayed ? (
                                  <div className='bg-[#FFBAB333] min-w-fit rounded-[23px] py-[4px] px-[8px] '>
                                    <p className='text-[10px] text-[#AD0013] font-medium'>Delayed</p>
                                  </div>
                                ) : (
                                  <div className='bg-[#99FF7D2E] min-w-fit rounded-[23px] py-[4px] px-[8px] '>
                                    <p className='text-[10px] text-[#005D00] font-medium'>On Track</p>
                                  </div>
                                ))}
                            </div>

                            {/* line */}
                            <div className='w-[100%] h-[1px] bg-[#F3F4F6] my-2'></div>

                            {/* Assignee and Milestone */}
                            <div className='flex gap-[24px] px-4'>
                              {/* Assignee */}
                              <div className='mb-2'>
                                <p className='text-sm grey-text mb-2'>General Assignee</p>
                                <p className='text-xs text-[#1F2937] font-base capitalize'>
                                  {data?.assigned_to_details?.[0]?.firstname === undefined ? 'N/A' : data?.assigned_to_details?.[0]?.firstname}{' '}
                                  {data?.assigned_to_details?.[0]?.lastname === undefined ? '' : data?.assigned_to_details?.[0]?.lastname}
                                </p>
                                <p className='text-[#296FD8] text-xs font-light'>
                                  {data?.assigned_to_details?.[0]?.email === undefined ? 'N/A' : data?.assigned_to_details?.[0]?.email}
                                </p>
                              </div>

                              {/* Milestone */}

                              {
                                //@ts-ignore
                                tab === 'Active' && (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Current Timeline</p>
                                    <p
                                      className={
                                        Number(data?.current_timeline.substring(0, 2)) > data?.actual_timeline
                                          ? 'text-xs capitalize text-[#AD0013]'
                                          : 'text-xs capitalize'
                                      }
                                    >
                                      {data?.current_timeline ? `${data?.current_timeline}(s)` : 'N/A'}
                                    </p>
                                  </div>
                                )
                              }
                              {
                                //@ts-ignore
                                tab === 'Completed' && (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Completed Timeline</p>
                                    <p className='text-xs '>{data?.completion_timeline ? `${data?.completion_timeline}(s)` : 'N/A'}</p>
                                  </div>
                                )
                              }
                            </div>

                            <div className='flex gap-[24px] mt-4 px-4'>
                              {/* Assignee */}
                              <div className='mb-2'>
                                <p className='text-sm grey-text mb-2'>Estimated Timeline</p>
                                <p className='text-[#6B7280] text-xs '>{data?.actual_timeline} Day(s)</p>
                              </div>

                              {/* Milestone */}
                              <div className='mb-2'>
                                <p className='text-sm grey-text mb-2'>Shipment Status</p>
                                <p className='text-[#6B7280] text-xs  '>{data?.shipment_details?.shipment_status}</p>
                              </div>
                            </div>

                            <div className='flex p-4'>
                              <div
                                className={`h-1.5 bg-[#00861E] rounded-md`}
                                style={{ width: `${(data?.percentage_completion / 100) * 100}%`, height: '18px', marginRight: '-10px', zIndex: 0 }}
                              >
                                <span className='text-xs white-text px-4 pb-1' style={{ fontSize: '12px', position: 'absolute' }}>
                                  {' '}
                                  {`${Math.round(data?.percentage_completion)}`}%
                                </span>
                              </div>
                              <div
                                className={`h-1.5 bg-[#F3F4F6] rounded-md`}
                                style={{ width: `${100 - data?.percentage_completion}%`, height: '18px' }}
                              ></div>
                            </div>
                          </div>
                        )
                      })
                    : !loading && (
                        <div className='mx-7 mt-8 block '>
                          <div className='flex justify-center mb-1'>
                            <img src={tray} alt='' />
                          </div>
                          <p className='text-center grey-text-3 font-light'>No data available</p>
                          {total_shipment_report > 0 && (
                            <div
                              className='px-2 py-2 w-fit cursor-pointer rounded bg-[#109B32] mt-6 mx-auto font-light'
                              onClick={() => {
                                handleFilter(
                                  {
                                    shipment_transport_type: '',
                                    shipment_type: '',
                                    shipment_status: '',
                                    start_date: '',
                                    end_date: '',
                                  },
                                  ''
                                )
                                setStartNum(1)
                                setEndNum(10)
                                setPage(0)
                                setFilterObject({ page: '0', count: '10', status: 'active' })
                              }}
                            >
                              <p className='text-white'>Go back to bookings</p>
                            </div>
                          )}
                        </div>
                      )}

                  {/* phone pagination */}
                  {filteredShipmentReports?.length > 0 && (
                    <div className='mt-4 mx-7  py-6 flex justify-between'>
                      {/* left */}
                      {page === 0 ? (
                        <div className='rotate-180 w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='rotate-180 w-[24px] h-[24px]'
                          onClick={() => {
                            handlePageChange(page)
                            setPage(page - 1)
                            setStartNum(startNum - 10)
                            setEndNum(endNum - 10)
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}

                      {/* middle */}
                      <div>
                        <p>
                          {startNum}-{filteredShipmentReports.length >= 10 ? endNum : endNum - (10 - (filteredShipmentReports.length % 10))} of{' '}
                          {total_shipment_report}
                        </p>
                      </div>

                      {/* right */}
                      {page >= Math.ceil(total_shipment_report / 10 - 1) ? (
                        <div className='w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='w-[24px] h-[24px] '
                          onClick={() => {
                            handlePageChange(page + 2)
                            setPage(page + 1)
                            setStartNum(startNum + 10)
                            setEndNum(filteredShipmentReports.length >= 10 ? endNum + 10 : endNum - (10 - (filteredShipmentReports.length % 10)))
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

// export default Shipments

const mapStateToProps = (state: any) => {
  const { loading, shipment_report, total_shipment_report, table_loading, shipment_report_csv } = state.shipment
  const { country_code } = state.additionalDetails
  const { error, dashboard_params, loading_admins, admins } = state.dashboard
  return { loading, shipment_report, total_shipment_report, table_loading, admins, loading_admins, country_code, shipment_report_csv }
}

export default connect(mapStateToProps, { getShipmentReport, getShipmentReportCsv })(Shipments)
