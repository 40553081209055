import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

//services
import dashboardServices from 'api/services/dashboard'

//redux
import { GET_DASHBOARD_PARAMS, GET_ADMINS, GET_PENDING_DASHBOARD_QUOTES } from 'store/dashboard/constants'
import { ResponseGenerator } from 'store/type'
import { getDashboardParamsSuccess, getAdminsSuccess, getPendingDashboardQuotesSuccess, dashboardApiError } from 'store/dashboard/actions'

//helpers
import { errorHandler } from 'helpers/errorHandler'

function* initiatePendingDashboardQuotesRequest() {
  try {
    const response: ResponseGenerator = yield call(dashboardServices.getPendingDashboardQuotes)

    if (response.data) {
      yield put(getPendingDashboardQuotesSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(dashboardApiError(message))
  }
}

function* initiateDashboardParamsRequest({ payload }: any) {
  try {
    const { ops_id, period, isAdmin, country } = payload
    const response: ResponseGenerator = yield call(dashboardServices.getDashboardParams, ops_id, period, isAdmin, country)

    if (response.data) {
      yield put(getDashboardParamsSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(dashboardApiError(message))
  }
}

function* getAdmins() {
  try {
    const response: ResponseGenerator = yield call(dashboardServices.getAdmins)
    if (response.data) {
      yield put(getAdminsSuccess(response.data))
    }
  } catch (error) {
    yield put(dashboardApiError(error))
  }
}

function* dashboardSaga() {
  yield takeEvery(GET_PENDING_DASHBOARD_QUOTES.REQUEST, initiatePendingDashboardQuotesRequest)
  yield takeLatest(GET_DASHBOARD_PARAMS.REQUEST, initiateDashboardParamsRequest)
  yield takeEvery(GET_ADMINS.REQUEST, getAdmins)
}

export default dashboardSaga
