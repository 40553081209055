import React, { useEffect, useState } from 'react'
import { Dropdown, Table } from 'antd'
import { useNavigate } from 'react-router-dom'

// helpers
import { handlePageChange, handlePerRowsChange } from 'helpers/tables/fetchTableData'

// images
import norate from 'assets/icons/norate.svg'
import dot from 'assets/icons/dots.svg'
import reassign from '../../assets/icons/re-assign.svg'
import complete from '../../assets/icons/complete-task.svg'
import escalate from '../../assets/icons/escalate-tssk.svg'

// components
import TasksManagementModal from './TasksManagementModal'
import TaskMangDropdownComp from './TaskMangDropdownComp'
import EscalateModal from './EscalateModal'
import ReassignTaskModal from './ReassignTaskModal'
import moment from 'moment'
import { getServiceCombinations } from 'helpers/getServiceCombinations'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'

const TaskTable = (props: any) => {
  const { filteredTableData, total_data, setFilterObject, filterObject, tab, tasks_csv } = props
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [page, setPage] = useState(parseInt(filterObject.page))
  const [data, setData] = useState(filteredTableData)
  const [escalateModal, setEscalateModal] = useState(false)
  const [reassignTaskModal, setReassignTaskModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [startNum, setStartNum] = useState(1)
  const [endNum, setEndNum] = useState(10)

  useEffect(() => {
    setData(filteredTableData)
  }, [filteredTableData])

  useEffect(() => {
    setPage(parseInt(filterObject.page))
  }, [filterObject])

  const handleEscalateTask = () => {
    setEscalateModal(true)
  }
  const handleReassignTask = () => {
    setReassignTaskModal(true)
  }

  const handleCompleteTask = () => {
    setIsOpen(true)
  }

  const items = [
    { key: '1', label: <TaskMangDropdownComp image={complete} label={'Complete Task'} textColor={'#139C33'} handleOnclick={handleCompleteTask} /> },
    { key: '2', label: <TaskMangDropdownComp image={escalate} label={'Escalate Task'} textColor={'#AD0013'} handleOnclick={handleEscalateTask} /> },
    { key: '3', label: <TaskMangDropdownComp image={reassign} label={'Reassign Task'} textColor={'#374151'} handleOnclick={handleReassignTask} /> },
  ]

  // console.log('tasks-csv', tasks_csv)
  const columns: any = [
    {
      title: 'Task name',
      dataIndex: 'shipment_details',
      key: 'shipment_details',
      render: (_, { name }) => (
        <div>
          <p className='text-xs capitalize'>{name ? name : 'N/A'} </p>
        </div>
      ),
    },
    {
      title: 'Task Assignee',
      dataIndex: 'assignee_details',
      key: 'assignee_details',
      // width: 150,
      render: (_, { assignee_details }) => (
        <>
          <p className='text-xs'>
            {assignee_details?.firstname ? assignee_details?.firstname : 'N/A'}&nbsp;
            {assignee_details?.lastname ? assignee_details?.lastname : 'N/A'}
          </p>
        </>
      ),
    },

    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{getMultipleServiceCombinations(shipment_details)}</p>
          <p className='text-xs capitalize text-[#296FD8]'>#{shipment_details.bl_number ? shipment_details.bl_number : 'N/A'}</p>
        </div>
      ),
    },

    {
      title: 'Shipment Type',
      dataIndex: 'shipment_type',
      key: 'shipment_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{shipment_details.shipment_type ? shipment_details.shipment_type : 'N/A'} </p>
        </div>
      ),
    },
    {
      title: 'Company Name',
      dataIndex: 'company_details',
      key: 'company_details',
      render: (_, { company_details }) => (
        <>
          <p className='text-xs capitalize'>{company_details?.[0]?.company_name ? company_details?.[0]?.company_name : 'N/A'} </p>
        </>
      ),
    },
    {
      title: 'Container Size',
      dataIndex: 'containers',
      key: 'containers',
      render: (_, { containers, containerCount }) => (
        <div className='flex gap-[2px]'>
          <p className='text-xs'>{containerCount ? containerCount : 'N/A'} </p>
          <p className='text-xs'> x</p>
          <p className='text-xs'>{containers[0]?.container_size ? containers[0]?.container_size : 'N/A'}</p>
        </div>
      ),
    },
  ]

  if (tab === 'On-track' || tab === 'Delayed') {
    columns.push({
      title: 'Current Timeline',
      dataIndex: 'current_timeline',
      key: 'current_timeline',
      render: (_, { current_timeline, timeline }) => (
        // @ts-ignore
        <>
          <p className='text-xs capitalize'>{current_timeline ? `${current_timeline}(s)` : 'N/A'}</p>
          {tab === 'Delayed' && <p className='text-[10px] text-[#AD0013]'>{timeline ? timeline : 'N/A'}</p>}
        </>
      ),
    })
  }
  if (tab === 'On-track' || tab === 'Incoming' || tab === 'Delayed' || tab === 'Completed') {
    columns.push({
      title: 'Expected Timeline',
      dataIndex: 'expected_timeline',
      key: 'expected_timeline',
      // width: 150,
      render: (_, { expected_timeline }) => <p className='text-xs capitalize'>{expected_timeline ? `${expected_timeline}(s)` : 'N/A'}</p>,
    })
  }

  if (tab === 'Completed') {
    columns.push({
      title: 'Completion Timeline',
      dataIndex: 'completion_timeline',
      key: 'completion_timeline',
      render: (_, { completion_timeline }) => (
        // @ts-ignore
        <p className='text-xs capitalize'>{completion_timeline ? `${completion_timeline}(s)` : 'N/A'}</p>
      ),
    })
  }
  if (tab === 'Blocked') {
    columns.push(
      {
        title: 'Task Start Date',
        dataIndex: 'task_start_date',
        key: 'task_start_date',
        render: (_, { start_date }) => (
          // @ts-ignore
          <p className='text-xs capitalize'>{start_date ? moment(start_date).format('DD/MM/YYYY') : 'N/A'}</p>
        ),
      },
      {
        title: 'Blocked By',
        dataIndex: 'blocked_by',
        key: 'blocked_by',
        render: (_, { milestone_details }) => (
          // @ts-ignore
          <p className='text-xs capitalize'>{milestone_details.milestone_name ? milestone_details.milestone_name : 'N/A'}</p>
        ),
      }
    )
  }
  columns.push({
    title: '',
    dataIndex: 'operation',
    fixed: 'right',
    width: '100px',
    key: 'operation',
    render: (_, { _id }) => (
      <div
        onClick={() => {
          setSelectedData(data.filter((item) => item._id === _id)?.[0])
          setIsOpen(true)
        }}
      >
        <div className='text-[#3ab44a] cursor-pointer underline'>View</div>
      </div>
    ),
  })

  return (
    <div className=''>
      <div className='desktop-only'>
        <Table
          //   @ts-ignore
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            total: total_data,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: page + 1,
            showSizeChanger: true,
            pageSizeOptions: ['10', '15', '20', '30'],
            onChange: (page, pageSize) => {
              handlePageChange(page - 1, setFilterObject, filterObject)
              handlePerRowsChange(pageSize, page - 1, setFilterObject, filterObject)
            },
          }}
          scroll={{ x: 1500 }}
          onRow={(record) => ({
            // onClick: () => handleRowClick(record),
          })}
          locale={{
            emptyText: (
              <div className='flex flex-col justify-center items-center my-5'>
                <div>
                  <img src={norate} alt='noconfigurations' />
                </div>
                <p className='black-text-4 text-sm mt-12'>No Available Tasks</p>
              </div>
            ),
          }}
        />
      </div>
      <div className='mobile-only'>
        {data?.length > 0 ? (
          data.map((item, idx) => (
            <div key={item?._id} className={`bg-white  mb-5 border border-grey-3 px-4 py-6 top-divider-2 ${idx === 0 ? 'top-divider-2' : ''}`}>
              <div className='flex items-center justify-between gap-[24px]'>
                <div className=''>
                  <div className='flex gap-[15px] items-center mb-2'>
                    <p className='text-lg black-text-3 font-medium'>{item.name ? `${item.name}` : 'N/A'} </p>
                  </div>

                  <div className='flex justify-between'>
                    <p className='text-[#296FD8] text-sm'>{item.shipment_details.bl_number ? `${item.shipment_details.bl_number}` : 'N/A'}</p>
                    {/* <div className='cursor-pointer'>
                                <img src={arrowR} alt='' />
                              </div> */}
                  </div>
                </div>
                <div
                  onClick={() => {
                    setSelectedData(item)
                    setIsOpen(true)
                  }}
                  className='text-[#3ab44a] cursor-pointer underline'
                >
                  View
                </div>
              </div>

              {/* line */}
              <div className='w-[100%] h-[1px] bg-[#F3F4F6] my-4'></div>

              <div className='flex justify-between gap-[24px]  sm:gap-[164px] sm:justify-normal'>
                {/* Assignee and Milestone */}
                <div className=' flex flex-col gap-[24px]'>
                  {/* timeline */}
                  {(tab === 'On-track' || tab === 'Delayed') && (
                    <div className='mb-2'>
                      <p className='text-sm grey-text mb-2'>Current Timeline</p>
                      <p className='text-sm black-text-3 font-medium '>{item.current_timeline ? `${item.current_timeline}(s)` : 'N/A'}</p>
                      {tab === 'Delayed' && <p className='text-[10px] text-[#AD0013]'>{item.timeline}</p>}
                    </div>
                  )}

                  {tab === 'Completed' && (
                    <div className='mb-2'>
                      <p className='text-sm grey-text mb-2'>Completion Timeline</p>
                      <p className='text-sm black-text-3 font-medium '>{item.completion_timeline ? `${item.completion_timeline}(s)` : 'N/A'}</p>
                    </div>
                  )}

                  {tab === 'Blocked' && (
                    <div className='mb-2'>
                      <p className='text-sm grey-text mb-2'>Task Start Date</p>
                      <p className='text-sm black-text-3 font-medium '>{item.start_date ? moment(item.start_date).format('DD/MM/YYYY') : 'N/A'}</p>
                    </div>
                  )}

                  {/* Assignee */}
                  <div className='mb-2'>
                    <p className='text-sm grey-text mb-2'>Task Assignee</p>
                    <p className='text-[#6B7280] text-xs font-light'>
                      {item?.assignee_details?.firstname ? item?.assignee_details?.firstname : 'N/A'}&nbsp;
                      {item?.assignee_details?.lastname ? item?.assignee_details?.lastname : 'N/A'}
                    </p>
                  </div>
                </div>

                <div className=' flex flex-col gap-[24px]'>
                  {/* Milestone */}
                  {(tab === 'On-track' || tab === 'Incoming' || tab === 'Delayed' || tab === 'Completed') && (
                    <div className='mb-2'>
                      <p className='text-sm grey-text mb-2'>Expected Timeline</p>
                      <p className='text-sm black-text-3 font-medium '>{item.expected_timeline ? `${item.expected_timeline}(s)` : 'N/A'}</p>
                    </div>
                  )}

                  {tab === 'Blocked' && (
                    <div className='mb-2'>
                      <p className='text-sm grey-text mb-2'>Blocked By</p>
                      <p className='text-sm black-text-3 font-medium '>
                        {item.milestone_details.milestone_name ? item.milestone_details.milestone_name : 'N/A'}
                      </p>
                    </div>
                  )}

                  {/* company name */}
                  <div className='mb-2'>
                    <p className='text-sm grey-text mb-2'>Company Name</p>
                    <p className='text-[#6B7280] text-xs font-light'>
                      {item.company_details[0].company_name ? `${item.company_details[0].company_name}` : 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='flex flex-col justify-center items-center mt-40'>
            <div>
              <img src={norate} alt='noconfigurations' />
            </div>
            <p className='black-text-4 text-sm mt-12'>No Available Tasks</p>
          </div>
        )}
        <div>
          {data?.length > 0 && (
            <div className='mt-4 mx-7 py-6 flex justify-between items-center'>
              {/* left */}
              {page === 0 ? (
                <div className='rotate-180 pt-2' onClick={() => {}}>
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#d6dfed'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className='rotate-180'
                  onClick={() => {
                    handlePageChange(page, setFilterObject, filterObject)
                    setPage(page - 1)
                    setStartNum(startNum - 10)
                    setEndNum(endNum - 10)
                  }}
                >
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#374151'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              )}

              {/* middle */}
              <p>
                {startNum} - {data.length >= 10 ? endNum : endNum - (10 - (data.length % 10))} of {total_data.length}
              </p>

              {/* right */}
              {page >= Math.ceil(total_data.length / 10 - 1) ? (
                <div className='' onClick={() => {}}>
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#d6dfed'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className=''
                  onClick={() => {
                    handlePageChange(page + 2, setFilterObject, filterObject)
                    setPage(page + 1)
                    setStartNum(startNum + 10)
                    setEndNum(data.length >= 10 ? endNum + 10 : endNum - (10 - (data.length % 10)))
                  }}
                >
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#374151'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isOpen && <TasksManagementModal isOpen={isOpen} setIsOpen={setIsOpen} selectedTask={selectedData} />}
      {escalateModal && <EscalateModal escalateModal={escalateModal} setEscalateModal={setEscalateModal} task_data={selectedData} />}
      {reassignTaskModal && (
        <ReassignTaskModal reassignTaskModal={reassignTaskModal} setReassignTaskModal={setReassignTaskModal} workflow_task_data={[selectedData]} />
      )}
    </div>
  )
}

export default TaskTable
