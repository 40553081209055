import { ReactNode } from 'react'

export type TasksCardType = {
  maintext: string | ReactNode
  subtext: string | ReactNode
  action?: string | ReactNode
  isRanking?: boolean
  class_names?: string
  loading?: boolean
}

interface DashboardShipments {
  total_shipments: number
  completed_shipments: number
  active_shipments: number
  percentage_completion: number
}

interface DashboardTask {
  total_task: number
  delayed_task: number
  ranking: number
}

interface DashboardParamsType {
  shipments: DashboardShipments
  task: DashboardTask
}
export interface DashboardProps {
  loading: boolean
  getDashboardParams: (
    ops_id: string,
    period: string,
    isAdmin: boolean,
    country_code: string
  ) => {
    type: string
    payload: {
      ops_id: string
      period: string
    }
  }
  country_code: string
  dashboard_params: DashboardParamsType
  loading_admins: boolean
  admins: Admin[]
  getAdmins: () => {
    type: string
  }
}

export interface Admin {
  is_deleted: boolean
  is_first_login: number
  is_active: boolean
  role: string
  department: string[]
  _id: string
  firstname: string
  lastname: string
  email: string
  createdAt: string
  updatedAt: string
  __v: number
  admin_token: string
  token_expiry: string
  last_login_date: string
  deleted_at: string
  deleted_by: string
}

export const admin_role = {
  agent: 'Agent',
  admin: 'Admin',
  super_admin: 'Super Admin',
}
