import React, { useState, useEffect } from 'react'
import { set, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { getWorkflowTaskById, completeWorkflowTask, escalateWorkflowTask, saveWorkflowContainer } from 'store/actions'

// images
import DocIcon from 'assets/icons/docIcon.svg'
import reassign from '../../assets/icons/re-assign.svg'
import complete from '../../assets/icons/complete-task.svg'
import escalate from '../../assets/icons/escalate-tssk.svg'

// components
import DocumentModal from './DocumentModal'
import EscalateModal from './EscalateModal'
import CustomCheckBox from 'components/checkBox/TaskCustomCheckbox'
import disclaimerIcon from 'assets/icons/disclaimer-info-doc-upload.svg'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import TaskMangDropdownComp from './TaskMangDropdownComp'
import CompleTaskModal from './CompleTaskModal'
import ReassignTaskModal from './ReassignTaskModal'
import { Admin } from 'types/dashboard/Dashboard'
import { useSelector } from 'react-redux'
import { getCustomStyles } from 'helpers/getCustomeStyles'
import NoDocumentUploadedModal from './NoDocumentUploadedModal'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'

function TasksManagementModal(props: any) {
  const {
    isOpen,
    setIsOpen,
    selectedTask,
    setSelectedTask,
    workflow_task_data,
    getWorkflowTaskById,
    task_modal_loading,
    completeWorkflowTask,
    saveWorkflowContainer,
  } = props
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  const [checkboxStates, setCheckboxStates] = useState<boolean[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [reloadModal, setReloadModal] = useState(false)
  const [selectedContainers, setSelectedContainers] = useState([])
  const [isCompleteButtonEnabled, setIsCompleteButtonEnabled] = useState(false)
  const [documentModal, setDocumentModal] = useState(false)
  const [noDocumentUploadedModal, setNoDocumentUploadedModal] = useState(false)
  const [isCompleteModal, setIsCompleteModal] = useState(false)
  const [escalateModal, setEscalateModal] = useState(false)
  const [reassignTaskModal, setReassignTaskModal] = useState(false)

  // responsive styles
  const [modalStyles, setModalStyles] = useState(getCustomStyles(window.innerWidth))

  useEffect(() => {
    const handleResize = () => {
      setModalStyles(getCustomStyles(window.innerWidth))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (workflow_task_data?.[0]?.container_task) {
      setCheckboxStates(new Array(workflow_task_data[0].container_task.length).fill(false))
    }
  }, [workflow_task_data])

  const handleReassignTask = () => {
    setReassignTaskModal(true)
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    setCheckboxStates(new Array(checkboxStates.length).fill(!selectAll))

    const allContainerIds = workflow_task_data?.[0]?.container_task?.map((container) => container._id) || []
    setSelectedContainers(!selectAll ? allContainerIds : [])
    setIsCompleteButtonEnabled(!selectAll)
  }

  const handleCheckboxChange = (index, container_id) => {
    const newCheckboxStates = [...checkboxStates]
    newCheckboxStates[index] = !newCheckboxStates[index]
    setCheckboxStates(newCheckboxStates)

    const allChecked = newCheckboxStates.every((checked) => checked)
    setIsCompleteButtonEnabled(allChecked)

    if (newCheckboxStates[index]) {
      //@ts-ignore
      setSelectedContainers([...selectedContainers, container_id])
    } else {
      setSelectedContainers(selectedContainers.filter((id) => id !== container_id))
    }
  }

  const [showTaskAction, setShowTaskAction] = useState(false)

  const task_id = selectedTask?._id

  useEffect(() => {
    getWorkflowTaskById(task_id)
    setReloadModal(false)
  }, [task_id, reloadModal])

  const openDocumentModal = () => {
    setDocumentModal(true)
  }

  const handleCompleteTask = () => {
    // completeWorkflowTask(task_id, { status: 'completed' }, onCompleteTask)
    const newData = {
      id: workflow_task_data?.[0]?._id,
      container_ids: selectedContainers,
    }
    console.log('newdata', newData)
    saveWorkflowContainer(newData)
  }

  const handleCompleteTaskWithContainers = () => {
    completeWorkflowTask(task_id, { status: 'completed' })
  }

  const handleEscalateTask = () => {
    setEscalateModal(true)
  }

  const onSubmit = (data) => {
    const newData = {
      id: workflow_task_data?.[0]?._id,
      container_ids: selectedContainers,
    }
    console.log('newdata', newData)
    saveWorkflowContainer(newData)
    saveWorkflowContainer(newData)
    !task_modal_loading &&
      setTimeout(() => {
        setReloadModal(true)
      }, 1500)
  }
  useEffect(() => {
    if (workflow_task_data?.[0]?.container_task) {
      const initialCheckboxStates = workflow_task_data[0].container_task.map((container) => container.status === 'completed')
      setCheckboxStates(initialCheckboxStates)
    }
  }, [workflow_task_data])

  const allRequiredDocsUploaded = workflow_task_data?.[0]?.task_files?.every((file) => file.is_uploaded)
  const disableCompleteButton = !allRequiredDocsUploaded || !isCompleteButtonEnabled ? true : false
  console.log('disable?', disableCompleteButton)

  console.log('task data >>>>>', selectedTask)

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel='Task Management Modal'
      onRequestClose={() => {
        setSelectedTask(null)
        console.log(' ', selectedTask)
        setIsOpen(false)
      }}
    >
      {task_modal_loading ? (
        <div className='flex justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className='px-[24px] bg-[white] rounded-t-[10px] '>
            <div className='flex pt-4  items-center '>
              <div className='pt-[16px] flex items-center'>
                <p className='text-[12px] text-[#6B7280] font-[400] text-left capitalize'>
                  {workflow_task_data?.[0]?.shipment_details?.[0]?.shipment_type}, {getMultipleServiceCombinations(selectedTask?.shipment_details)} /{' '}
                  {''}
                  <span className='text-sm text-[#296FD8] font-light text-left mb-0'>{selectedTask?.shipment_details?.[0]?.bl_number} </span>
                </p>
                <p className='text-[12px] ml-1 text-[#139C33] font-[400] text-left capitalize'>
                  {' '}
                  {workflow_task_data?.[0]?.shipment_details?.[0]?.job_number}
                </p>
              </div>
              <div className='ml-auto'>
                <div className='flex items-center'>
                  {workflow_task_data?.[0]?.status !== 'completed' && (
                    <i className='px-4 cursor-pointer relative' onClick={() => setShowTaskAction(!showTaskAction)}>
                      <svg width='25' height='12' viewBox='0 0 25 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <circle cx='19.5' cy='6' r='1.5' transform='rotate(-180 19.5 6)' fill='#374151' />
                        <circle cx='12.5' cy='6' r='1.5' transform='rotate(-180 12.5 6)' fill='#374151' />
                        <circle cx='5.5' cy='6' r='1.5' transform='rotate(-180 5.5 6)' fill='#374151' />
                      </svg>
                    </i>
                  )}
                  <i
                    className='ion-ios-close py-1 text-3xl black-text cursor-pointer'
                    onClick={() => {
                      setIsOpen(false)
                      console.log('selected Task>>>', selectedTask)
                    }}
                  ></i>
                </div>
              </div>
            </div>
            {/* Task Action */}
            {showTaskAction && (
              <span className='absolute  z-20 max-md:ml-[150px] ml-[450px]'>
                <div className=' bg-white shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded-md  '>
                  <TaskMangDropdownComp
                    image={complete}
                    label={'Complete Task'}
                    textColor={'#139C33'}
                    handleOnclick={
                      allRequiredDocsUploaded === false
                        ? () => setNoDocumentUploadedModal(true)
                        : !isCompleteButtonEnabled
                        ? () => setIsCompleteModal(true)
                        : handleCompleteTask
                    }
                  />
                  <TaskMangDropdownComp image={escalate} label={'Escalate Task'} textColor={'#AD0013'} handleOnclick={handleEscalateTask} />
                  {admin_data?.role === 'super_admin' && (
                    <TaskMangDropdownComp image={reassign} label={'Reassign Task'} textColor={'#374151'} handleOnclick={handleReassignTask} />
                  )}
                </div>
              </span>
            )}
            <div className='pb-6'>
              <p className='text-[#111827] text-[24px] font-[500] pt-2 capitalize'>{workflow_task_data?.[0]?.name}</p>
            </div>
            <div className='flex items-center pb-[20px]'>
              <p className='text-[#6B7280] text-[14px] font-[400]'>Milestone</p>
              <div className='px-14'>
                <p className='text-[#1F2937] text-[14px] font-[400] capitalize'>{workflow_task_data?.[0]?.milestone_details?.[0]?.milestone_name}</p>
              </div>
            </div>

            <div className='flex items-center pb-[20px]'>
              <p className='text-[#6B7280] text-[14px] font-[400]'>Company Name</p>
              <div className='px-4'>
                <p className='text-[#1F2937] text-[14px] font-[400] capitalize'>
                  {selectedTask?.company_details?.[0]?.company_name ? selectedTask?.company_details?.[0]?.company_name : 'N/A'}
                </p>
              </div>
            </div>

            <div className='flex items-center pb-[20px]'>
              <p className='text-[#6B7280] text-[14px] font-[400]'>Assignee</p>
              <div className='px-14'>
                <p className='text-[#1F2937] font-medium text-[12px] font-[400] bg-[#E6EEFF] rounded-[3px] py-[4px] px-[6px]'>
                  {workflow_task_data?.[0]?.assignee_details?.firstname} {workflow_task_data?.[0]?.assignee_details?.lastname}
                </p>
              </div>
            </div>

            <div className='flex items-center pb-[32px]'>
              <p className='text-[#6B7280] text-[14px] font-[400]'>
                Status<span className='text-white'>lss</span>
              </p>
              <div className='max-sm:px-12 px-14'>
                <div className='h-[20px] flex items-center rounded-sm justify-center mr-2'>
                  {workflow_task_data?.[0]?.status === 'completed' ? (
                    <span className=' bg-[#059C010D] text-[#059C01] text-[12px] font-medium py-[4px] px-[8px] capitalize'>Completed Task</span>
                  ) : workflow_task_data?.[0]?.status === 'cancelled' ? (
                    <span className=' bg-light-red red-text text-[12px] font-medium py-[4px] px-[8px] capitalize'>
                      {workflow_task_data?.[0]?.status}
                    </span>
                  ) : workflow_task_data?.[0]?.status === 'on-track' ? (
                    <span className=' bg-[#D0F5FF4D] text-[#4B83F0] text-[12px] font-medium py-[4px] px-[6px] capitalize'>open shipment</span>
                  ) : workflow_task_data?.[0]?.status === 'incoming' ? (
                    <span className=' bg-light-yellow yellow-text text-[12px] font-medium py-[4px] px-[8px] capitalize'>
                      {workflow_task_data?.[0]?.status}
                    </span>
                  ) : workflow_task_data?.[0]?.status === 'delayed' ? (
                    <span className='bg-[#FFE4E1] text-[#8A0000] text-[12px] font-medium py-[4px] px-[8px] capitalize'>
                      {workflow_task_data?.[0]?.status}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                {/* <p className='bg-light-green text-[#00861E] text-[12px] font-[400] py-[4px] px-[6px] capitalize'>{workflow_task_data?.[0]?.status}</p> */}
              </div>
            </div>
            <div className='modal-bottom-divider'></div>

            <div className='pt-[24px] pb-[40px] '>
              <div className='pb-[32px]'>
                <p className='text-[14px] font-[400]'>Documents({workflow_task_data?.[0]?.task_files?.length})</p>
              </div>

              {workflow_task_data?.[0]?.task_files?.length === 0 ? (
                <>
                  <div className='flex p-5 border rounded-[4px] items-center gap-[10px]'>
                    <div className='text-[#1F2937] max-sm:flex-col flex pt-[1px] font-[500] text-[13px]'>
                      <span className='text-[#1F2937] max-sm:mb-2 font-bold flex gap-1'>
                        <img className='mb-1 mr-1' src={disclaimerIcon} alt='info' />
                        Note,
                      </span>
                      <p>This task does not require a document to be completed</p>
                    </div>
                  </div>
                </>
              ) : (
                <div className='flex flex-col sm:flex-row items-start gap-4  '>
                  <div
                    className={`${
                      (allRequiredDocsUploaded || workflow_task_data?.[0]?.status === 'incoming') && 'opacity-50 solid-br cursor-not-allowed'
                    } 'solid-br border bg-[#F3F4F680] cursor-pointer rounded p-5 cursor-pointer'`}
                    onClick={!allRequiredDocsUploaded ? openDocumentModal : () => {}}
                  >
                    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M17 8.99984H1' stroke='#4B83F0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
                      <path d='M8.99984 17V1' stroke='#4B83F0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
                    </svg>
                  </div>
                  <div>
                    <div className='flex item-center gap-x-6 '>
                      {workflow_task_data?.[0]?.task_files.every((file: any) => !file.is_uploaded) ? (
                        <div className='flex p-5 border rounded-[4px] items-center gap-[10px]'>
                          <img className='mb-1' src={disclaimerIcon} alt='info' />
                          <div className='text-[#1F2937] flex items-center pt-[1px] font-[500] text-[13px] gap-[10px]'>
                            <p className='text-[#1F2937] pr-1 font-bold'>Note,</p>
                            <p className='text-xs'> No documents have been uploaded for this shipment</p>
                          </div>
                        </div>
                      ) : (
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 w-full'>
                          {workflow_task_data?.[0]?.task_files?.map((task_file: any, index) => {
                            return (
                              <>
                                {task_file?.is_uploaded === true ? (
                                  <>
                                    <div className='flex items-center solid-br rounded bg-[#F3F4F680] p-[12px] w-full h-[58px] '>
                                      <div className='pl-[6px] pr-[12px]'>
                                        <img src={DocIcon} />
                                      </div>
                                      <div>
                                        <p className='text-[#1F2937] font-[500] text-[13px]'>{task_file?.document_name}</p>
                                        <a
                                          href={task_file?.document_location}
                                          target='_blank'
                                          rel='noreferrer'
                                          className='hover:bg-[#139c330f] items-center gap-x-2 rounded cursor-pointer'
                                        >
                                          <p key={task_file._id} className='text-[#4B5563] text-[12px]'>
                                            view
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='bg-[#F3F4F6] border '>
            <p className='text-[14px] font-[400] text-[#00861E] px-[24px] py-4'>Containers({workflow_task_data?.[0]?.containerCount})</p>
            <hr />
          </div>
          <div className='px-[24px] bg-[#F3F4F6] rounded-b-[10px] '>
            <div className='pb-[32px]'>
              <div className='flex items-center'>
                <p className='py-[24px] text-[14px] font-[500]'>Active Container</p>
                <p className='py-[24px] text-[14px] font-[500] text-[#C70024] ml-auto px-3 cursor-pointer' onClick={handleSelectAll}>
                  {' '}
                  {selectAll ? 'Deselect All' : 'Select All'}
                </p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex flex-wrap items-center gap-4 '>
                  <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 w-full'>
                    {workflow_task_data?.[0]?.container_task?.map((container, index) => (
                      <div key={container._id} className='solid-br rounded py-[12px] bg-[white] w-full h-[50px] px-[4px]'>
                        <p className='px-3 text-[#374151] text-[14px] flex'>
                          <span className='px-[10px] pt-0.5'>
                            <CustomCheckBox
                              name=''
                              id=''
                              label=''
                              isRequired={true}
                              // prettier-ignore
                              defaultChecked={container?.status === "completed"} // Set defaultChecked based on container status
                              isDisabled={false}
                              onChange={() => handleCheckboxChange(index, container._id)}
                              checked={checkboxStates[index]}
                            />
                          </span>
                          {container?.container_details?.[0]?.container_number}
                          <span className='text-[14px] text-[#9CA3AF] font-[400]'> </span>
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className='w-full mt-3 text-center'>
                    <button
                      disabled={workflow_task_data?.[0]?.status === 'completed' || workflow_task_data?.[0]?.status === 'incoming'}
                      type='submit'
                      className={`${
                        workflow_task_data?.[0]?.status === 'completed' || workflow_task_data?.[0]?.status === 'incoming'
                          ? 'bg-[#D1D5DB] w-7/12 max-sm:w-full'
                          : 'bg-[#296FD8] w-7/12 max-sm:w-full'
                      } 'disabled:cursor-not-allowed disabled:cursor-not-allowed rounded transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-white text-sm font-normal'`}
                    >
                      Mark Container as Done
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {documentModal && (
            <DocumentModal
              setReloadModal={setReloadModal}
              setSelectedTask={setSelectedTask}
              documentModal={documentModal}
              setDocumentModal={setDocumentModal}
              workflow_task_data={workflow_task_data}
            />
          )}
          {isCompleteModal && (
            <CompleTaskModal
              handleCompleteTaskWithContainers={handleCompleteTaskWithContainers}
              completeTaskModal={isCompleteModal}
              setCompleteTaskModal={setIsCompleteModal}
            />
          )}
          {escalateModal && <EscalateModal escalateModal={escalateModal} setEscalateModal={setEscalateModal} task_data={workflow_task_data} />}
          {reassignTaskModal && (
            <ReassignTaskModal
              reassignTaskModal={reassignTaskModal}
              setReassignTaskModal={setReassignTaskModal}
              workflow_task_data={workflow_task_data}
            />
          )}
          {noDocumentUploadedModal && (
            <NoDocumentUploadedModal
              setDocumentModal={setDocumentModal}
              noDocumentUploadedModal={noDocumentUploadedModal}
              setNoDocumentUploadedModal={setNoDocumentUploadedModal}
              workflow_task_data={workflow_task_data}
            />
          )}
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  const { workflow_task_data, task_modal_loading, singleWorkflowLoading } = state.tasks
  return { workflow_task_data, task_modal_loading, singleWorkflowLoading }
}

export default connect(mapStateToProps, { getWorkflowTaskById, completeWorkflowTask, escalateWorkflowTask, saveWorkflowContainer })(
  TasksManagementModal
)
