import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

//style
import './index.css'

//icons
import close from 'assets/icons/close.svg'
import caret from 'assets/icons/chevron-down.svg'

//logo
import onePortLogo from 'assets/logos/oneport-logo.svg'

//redux
import { logoutUser } from 'store/actions'

//components
import LogoutModal from 'components/partials/LogoutModal'

interface Props {
  handleToggle?: () => void
  logoutUser: () => void
}

const Sidebar = ({ handleToggle, logoutUser }: Props) => {
  const [logOutIsOpen, setLogOutIsOpen] = useState(false)

  const location = useLocation()

  const [showReport, setShowReport] = useState<boolean>(false)

  //close modal
  const closeLogoutModal = () => {
    setLogOutIsOpen(false)
  }

  const logout = () => {
    logoutUser()
    window.location.reload()
  }

  // console.log('showReport>>>', showReport)

  return (
    <aside className='sidebar dashboard-aside flex flex-col border-r-[1px] border-[#eaecf080] desktop-only relative z-10'>
      <div className='pl-8 py-3 border-y-[1px] border-[#eaecf080] max-h-[60px] flex justify-between items-center'>
        <NavLink to='/'>
          <img src={onePortLogo} alt='oneport logo' />
        </NavLink>
        <div className='pr-6 hidden mobile-close'>
          <img src={close} alt='close' onClick={handleToggle} className='cursor-pointer' />
        </div>
      </div>
      <div className='px-4'>
        <NavLink to='/dashboard' className='flex items-center pl-4 py-2 gap-x-3 mt-8 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='M6.106 13.85v-2.044a.95.95 0 0 1 .95-.946h1.923a.95.95 0 0 1 .956.946v2.05c0 .442.356.803.802.813h1.282c1.28 0 2.316-1.026 2.316-2.292v0-5.816a1.626 1.626 0 0 0-.642-1.27L9.306 1.793a2.12 2.12 0 0 0-2.63 0L2.31 5.298c-.4.303-.636.771-.641 1.27v5.81c0 1.265 1.037 2.291 2.315 2.291h1.283a.823.823 0 0 0 .827-.819v0'
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Dashboard</span>
            </>
          )}
        </NavLink>
        <NavLink to='/tasks' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='M13.16 4.1c0-1.834-1.254-2.569-3.059-2.569h-4.24c-1.749 0-3.06.685-3.06 2.447v9.816c0 .484.52.788.942.552l4.255-2.387 4.218 2.383a.633.633 0 0 0 .944-.551V4.1Z'
                  clip-rule='evenodd'
                />
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='M5.516 6.014h4.879'
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Tasks</span>
            </>
          )}
        </NavLink>
        <div
          className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded cursor-pointer max-w-[176px]'
          onClick={() => setShowReport(!showReport)}
        >
          <>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
              <path
                stroke={showReport || location.pathname.includes('reports') ? '#109B32' : '#4B5563'}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.1'
                d='M4.911 6.805v4.573M8.024 4.617v6.762M11.083 9.219v2.156'
              />
              <path
                stroke={showReport || location.pathname.includes('reports') ? '#109B32' : '#4B5563'}
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.1'
                d='M11.123 1.336H4.875c-2.178 0-3.543 1.541-3.543 3.723v5.887c0 2.182 1.359 3.723 3.543 3.723h6.248c2.184 0 3.542-1.541 3.542-3.723V5.059c0-2.182-1.358-3.723-3.542-3.723Z'
                clip-rule='evenodd'
              />
            </svg>
            <span className={`hover:green-text font-light ${showReport || location.pathname.includes('reports') ? 'green-text' : 'grey-text'}`}>
              Reports
            </span>
            <img src={caret} className={`ml-auto ${showReport ? 'rotate-180' : ''}`} alt='' />
          </>
        </div>
        {showReport && (
          <>
            <NavLink
              to='/reports/shipments'
              className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded'
              onClick={(e) => {
                handleToggle && handleToggle()
                e.stopPropagation()
              }}
            >
              {({ isActive }) => (
                <>
                  <span className={`${isActive ? 'green-text font-light' : 'grey-text font-light'} ml-7`}>Shipments</span>
                </>
              )}
            </NavLink>
            <NavLink
              to='/reports/tasks'
              className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded'
              onClick={(e) => {
                handleToggle && handleToggle()
                e.stopPropagation()
              }}
            >
              {({ isActive }) => (
                <>
                  <span className={`${isActive ? 'green-text font-light' : 'grey-text font-light'} ml-7`}>Tasks</span>
                </>
              )}
            </NavLink>
          </>
        )}
        {/* <NavLink to='/messaging' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='m11.934 5.898-2.962 2.41a1.531 1.531 0 0 1-1.907 0l-2.987-2.41'
                />
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='M11.271 14c2.028.006 3.394-1.66 3.394-3.708V5.713C14.665 3.666 13.3 2 11.271 2H4.726C2.7 2 1.332 3.666 1.332 5.713v4.58c0 2.047 1.367 3.713 3.394 3.707h6.545Z'
                  clip-rule='evenodd'
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Messaging</span>
            </>
          )}
        </NavLink>
        <NavLink to='/escalations' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='M10.477 10.818H5.664M10.477 8.029H5.664M7.5 5.24H5.665'
                />
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='m10.605 1.836-5.126.003C3.64 1.849 2.5 3.06 2.5 4.907v6.13c0 1.857 1.148 3.072 3.004 3.072l5.126-.002c1.84-.011 2.98-1.222 2.98-3.07v-6.13c0-1.856-1.149-3.071-3.005-3.071Z'
                  clip-rule='evenodd'
                />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Escalations</span>
            </>
          )}
        </NavLink> */}
        <NavLink to='/workflow-configuration' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='m13.873 5.08-.415-.72a1.275 1.275 0 0 0-1.74-.47v0a1.27 1.27 0 0 1-1.738-.452 1.22 1.22 0 0 1-.171-.61v0a1.275 1.275 0 0 0-1.276-1.312h-.836A1.27 1.27 0 0 0 6.428 2.79v0a1.275 1.275 0 0 1-1.275 1.257 1.22 1.22 0 0 1-.61-.17v0a1.275 1.275 0 0 0-1.74.47l-.445.731a1.275 1.275 0 0 0 .464 1.74v0a1.275 1.275 0 0 1 0 2.208v0a1.27 1.27 0 0 0-.464 1.734v0l.421.726a1.276 1.276 0 0 0 1.74.494v0a1.263 1.263 0 0 1 1.732.464c.11.184.169.395.171.61v0c0 .704.571 1.275 1.275 1.275h.836c.702 0 1.272-.567 1.276-1.269v0a1.27 1.27 0 0 1 1.275-1.275 1.3 1.3 0 0 1 .61.17v0c.609.351 1.386.144 1.74-.463v0l.439-.732a1.27 1.27 0 0 0-.464-1.74v0a1.27 1.27 0 0 1 0-2.203v0c.604-.353.81-1.125.464-1.733v0-.006Z'
                  clip-rule='evenodd'
                />
                <circle cx='8.117' cy='7.921' r='1.757' stroke='#4B5563' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.1' />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Workflow Config.</span>
            </>
          )}
        </NavLink>
      </div>
      <div className='mx-4 mt-auto mb-8 lg:mb-0 border-t-[1px] border-[#eaecf080]'>
        <NavLink to='/settings' className='flex items-center pl-4 py-2 gap-x-3 hover:bg-[#F5FDF2] rounded' onClick={handleToggle}>
          {({ isActive }) => (
            <>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
                <path
                  stroke={isActive ? '#109B32' : '#4B5563'}
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='1.1'
                  d='m13.873 5.08-.415-.72a1.275 1.275 0 0 0-1.74-.47v0a1.27 1.27 0 0 1-1.738-.452 1.22 1.22 0 0 1-.171-.61v0a1.275 1.275 0 0 0-1.276-1.312h-.836A1.27 1.27 0 0 0 6.428 2.79v0a1.275 1.275 0 0 1-1.275 1.257 1.22 1.22 0 0 1-.61-.17v0a1.275 1.275 0 0 0-1.74.47l-.445.731a1.275 1.275 0 0 0 .464 1.74v0a1.275 1.275 0 0 1 0 2.208v0a1.27 1.27 0 0 0-.464 1.734v0l.421.726a1.276 1.276 0 0 0 1.74.494v0a1.263 1.263 0 0 1 1.732.464c.11.184.169.395.171.61v0c0 .704.571 1.275 1.275 1.275h.836c.702 0 1.272-.567 1.276-1.269v0a1.27 1.27 0 0 1 1.275-1.275 1.3 1.3 0 0 1 .61.17v0c.609.351 1.386.144 1.74-.463v0l.439-.732a1.27 1.27 0 0 0-.464-1.74v0a1.27 1.27 0 0 1 0-2.203v0c.604-.353.81-1.125.464-1.733v0-.006Z'
                  clip-rule='evenodd'
                />
                <circle cx='8.117' cy='7.921' r='1.757' stroke='#4B5563' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.1' />
              </svg>
              <span className={isActive ? 'green-text font-light' : 'grey-text font-light'}>Settings</span>
            </>
          )}
        </NavLink>
        <p className='flex items-center pl-4 py-2 gap-x-3 mb-6 hover:bg-[#F5FDF2] rounded cursor-pointer' onClick={() => setLogOutIsOpen(true)}>
          <>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
              <path
                stroke='#4B5563'
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='1.1'
                d='M10.01 4.93v-.622c0-1.356-1.1-2.456-2.456-2.456h-3.25a2.457 2.457 0 0 0-2.456 2.456v7.42c0 1.357 1.1 2.457 2.456 2.457H7.56a2.45 2.45 0 0 0 2.45-2.45v-.628M14.54 8.02H6.511M12.586 6.078l1.952 1.943-1.952 1.944'
              />
            </svg>
            <span className='hover:green-text font-light grey-text'>Logout</span>
          </>
        </p>
        <LogoutModal isOpen={logOutIsOpen} closeModal={closeLogoutModal} logoutUser={logoutUser} logout={logout} />
      </div>
    </aside>
  )
}
//@ts-ignore
export default connect(null, { logoutUser })(Sidebar)
