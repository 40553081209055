import { call, put, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify'

//services
import authServices from 'api/services/auth'
import mixpanel from 'helpers/mixpanel'

//redux
import { LOGIN_USER, LOGOUT_USER, VALIDATE_REGISTRATION, RESEND_VERIFICATION } from './constants'
import { apiError, loginSuccess, validateRegistrationSuccess, resendVerificationSuccess } from './actions'
import { ResponseGenerator } from 'store/type'

let admin_data = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null

function* loginUser({ payload }: any) {
  try {
    const { user, redirect } = payload
    const response: ResponseGenerator = yield call(authServices.login, user)
    if (response.data) {
      yield put(loginSuccess(response.data.data))
      console.log(response.data.data)
      window.location.replace(`${window.location.origin}${redirect}&email=${response.data.data.email}&token=${response.data.data.verify_token}`)
    } else {
      mixpanel.track('Login Completed', {
        email: admin_data.email,
      })
    }
  } catch (error) {
    const message = error.message || error['response']['data']['message'] || 'network error'

    yield put(apiError(message))
  }
}

function* validateRegistration({ payload }: any) {
  try {
    const { data, redirect } = payload
    const response: ResponseGenerator = yield call(authServices.validateRegistration, data)
    if (response.data) {
      yield put(validateRegistrationSuccess(response.data.data))
      console.log(response.data.data.admin)

      localStorage.setItem('token', response.data.data.token)
      const admin_data = JSON.stringify(response.data.data.admin)
      localStorage.setItem('admin_data', admin_data)
      window.location.replace('/dashboard')
    }
  } catch (error) {
    const message = error['response']['data']['message'] || error.message || 'network error'

    yield put(apiError(message))
  }
}

function* resendVerification({ payload }: any) {
  try {
    const { data, redirect } = payload

    const response: ResponseGenerator = yield call(authServices.resendVerification, data)

    if (response.data) {
      yield put(resendVerificationSuccess(response.data.data))

      // toast.success("otp resent, check your e-mail").then(() => {
      //     window.location.replace(`${window.location.origin}${redirect}&email=${response.data.data.email}&token=${response.data.data.verify_token}`);
      // });
    }
  } catch (error) {
    const message = error['response']['data']['message'] || error.message || 'network error'

    yield put(apiError(message))
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem('token')
  } catch (error) {
    yield put(apiError(error))
  }
  // localStorage.removeItem('token')
}

function* authSaga() {
  yield takeEvery(LOGIN_USER.REQUEST, loginUser)
  yield takeEvery(LOGOUT_USER.REQUEST, logoutUser)
  yield takeEvery(VALIDATE_REGISTRATION.REQUEST, validateRegistration)
  yield takeEvery(RESEND_VERIFICATION.REQUEST, resendVerification)
}

export default authSaga
