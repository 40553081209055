import React from 'react'
import { useSelector } from 'react-redux'
import { Admin, admin_role } from 'types/dashboard/Dashboard'

//components
import Tasks from './Tasks'
import SuperAdminTasks from './SuperAdminTasks'
import SuperAdminTableView from './SuperAdminTableView'

const IndexTask = () => {
  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  return <>{admin_role[admin_data.role] === admin_role['super_admin'] ? <SuperAdminTableView /> : <Tasks />}</>
}

export default IndexTask
