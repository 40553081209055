import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import docs from 'assets/icons/icons/Paper.svg'
import report from 'assets/icons/report.svg'
import Modal from 'react-modal'
import { getTaskReportById } from 'store/actions'
import PageLoading from 'components/partials/pageLoading'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import BlueArrow from 'assets/icons/blueArrow.svg'
import { TaskModalType } from 'types/reports/Tasks'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Use percentage for responsiveness
    maxWidth: '450px', // Max width for larger screens
    borderRadius: '10px',
    border: '0.01px solid #888',
    maxHeight: '80vh', // Ensure it doesn't overflow vertically
    // overflowY: 'auto', // Enable scrolling if needed
  },
  overlay: {
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
    zIndex: '99999999999',
  },
}

const TaskModal = ({ isOpen, setIsOpen, taskData, task_report_data, getTaskReportById, loading }: TaskModalType) => {
  console.log('taskData', taskData)

  const task_id = taskData?.milestone_details?._id
  const shipment_id = taskData?.shipment_details?._id

  console.log('shipment is>>', shipment_id)

  useEffect(() => {
    getTaskReportById(task_id, shipment_id)
  }, [getTaskReportById, shipment_id])

  useEffect(() => {
    if (task_id && shipment_id) {
      getTaskReportById(task_id, shipment_id)
    }
  }, [getTaskReportById, task_id, shipment_id])

  console.log(task_report_data, 'task_report_data')

  const [showDocs, setShowDocs] = useState([])
  const ref = useRef<HTMLDivElement>(null)
  const toggle = (index) => {
    const temp = [...showDocs]
    //@ts-ignore
    temp[index] = !temp[index]
    setShowDocs([...temp])
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        contentLabel='Single Report Modal'
        onRequestClose={() => {
          setIsOpen(false)
        }}
      >
        <div>
          <div className='px-8 py-5 flex'>
            <div>
              <p className='text-[#005C00] font-medium text-md'>Task Report</p>
              <p className='text-[#374151] font-base text-sm uppercase'>Task Milestone: {task_report_data?.milestone_name}</p>
            </div>
            <div className='ml-auto'>
              <i
                className='ion-ios-close px-4 text-3xl black-text cursor-pointer'
                onClick={() => {
                  setIsOpen(false)
                }}
              ></i>
            </div>
          </div>
          <div className='modal-bottom-divider mb-3'></div>
          <div className='' style={{ maxHeight: '60vh', overflowY: 'auto' }}>
            <div className='px-8 py-4'>
              {/* {task_report_data?.tasks_details?.map((taskItem: any, idx) => { */}
              {task_report_data?.tasks_details
                ?.filter((_, idx) => idx === 0)
                .map((taskItem: any, idx) => {
                  return (
                    <div
                      //  className='solid-br p-4 rounded-lg mb-4'
                      className={`new-solid-br p-4 rounded-lg mb-4 ${idx === 0 ? 'bg-[#F4FFF5]' : ''}`}
                      key={taskItem?._id}
                    >
                      <p className='font-medium text-[#374151] capitalize'>{taskItem?.name}</p>
                      <p>
                        <span className='text-[#6B7280] font-normal'>
                          {taskItem?.assignee_details?.[0]?.firstname} {taskItem?.assignee_details?.[0]?.lastname}{' '}
                        </span>
                        <span className='text-[#139C33] capitalize'>
                          &nbsp;
                          {/* {taskItem?.status} */}
                          {taskItem?.status === 'completed' ? (
                            <span className='text-[#059C01] p-2 text-sm font-light'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'cancelled' ? (
                            <span className='red-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'on-track' ? (
                            <span className='yellow-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'incoming' ? (
                            <span className=' purple-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'delayed' ? (
                            <span className=' blue-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </p>
                      {taskItem?.task_files?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className='cursor-pointer flex items-center gap-x-1 rounded-full mr-2 p-1.5 px-2' onClick={() => toggle(idx)}>
                            <div className='pt-2 flex cursor-pointer'>
                              <p className='pr-2 text-[#296FD8] text-sm my-0'>View Uploaded Document</p>
                              <img src={BlueArrow} width={'5%'} />
                            </div>
                          </div>

                          {showDocs[idx] && (
                            <>
                              <div className='modal-bottom-divider px-2'></div>
                              {taskItem?.task_files?.map((docItem: any) => {
                                console.log('taskItem', docItem)
                                return (
                                  <a
                                    href={docItem?.document_location}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='hover:bg-[#139c330f] items-center gap-x-2 rounded cursor-pointer'
                                  >
                                    <p key={docItem._id} className='text-[#4B5563] text-[12px] px-2 py-1 cursor-pointer'>
                                      {docItem?.document_name}
                                    </p>
                                  </a>
                                )
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )
                })}
            </div>
            <div className='px-8'>
              <div className='modal-bottom-divider'></div>
              <p className='pt-4 text-[14px] font-[600] text-[#9CA3AF]'>
                Other Tasks within <span className='text-[#005C00]'>{task_report_data?.milestone_name} Milestone</span>
              </p>
            </div>

            <div className='px-8 py-4'>
              {/* {task_report_data?.tasks_details?.map((taskItem: any, idx) => { */}
              {task_report_data?.tasks_details
                ?.filter((_, idx) => idx > 0)
                .map((taskItem: any, idx) => {
                  return (
                    <div
                      //  className='solid-br p-4 rounded-lg mb-4'
                      className={`new-solid-br p-4 rounded-lg mb-4`}
                      key={taskItem?._id}
                    >
                      <p className='font-medium text-[#374151] capitalize'>{taskItem?.name}</p>
                      <p>
                        <span className='text-[#6B7280] font-normal'>
                          {taskItem?.assignee_details?.[0]?.firstname} {taskItem?.assignee_details?.[0]?.lastname}{' '}
                        </span>
                        <span className='text-[#139C33] capitalize'>
                          &nbsp;
                          {/* {taskItem?.status} */}
                          {taskItem?.status === 'completed' ? (
                            <span className='text-[#059C01] p-2 text-sm font-light'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'cancelled' ? (
                            <span className='red-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'on-track' ? (
                            <span className='yellow-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'incoming' ? (
                            <span className=' purple-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : taskItem?.status === 'delayed' ? (
                            <span className=' blue-text p-2 text-sm font-light capitalize'>{taskItem?.status}</span>
                          ) : (
                            <></>
                          )}
                        </span>
                      </p>
                      {taskItem?.task_files?.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className='cursor-pointer flex items-center gap-x-1 rounded-full mr-2 p-1.5 px-2' onClick={() => toggle(idx)}>
                            <div className='pt-2 flex cursor-pointer'>
                              <p className='pr-2 text-[#296FD8] text-sm my-0'>View Uploaded Document</p>
                              <img src={BlueArrow} width={'5%'} />
                            </div>
                          </div>

                          {showDocs[idx] && (
                            <>
                              <div className='modal-bottom-divider px-2'></div>
                              {taskItem?.task_files?.map((docItem: any) => {
                                console.log('taskItem', docItem)
                                return (
                                  <a
                                    href={docItem?.document_location}
                                    target='_blank'
                                    rel='noreferrer'
                                    className='hover:bg-[#139c330f] items-center gap-x-2 rounded cursor-pointer'
                                  >
                                    <p key={docItem._id} className='text-[#4B5563] text-[12px] px-2 py-1 cursor-pointer'>
                                      {docItem?.document_name}
                                    </p>
                                  </a>
                                )
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )
                })}
            </div>
          </div>

          <div className='bg-[#F9FAFB] py-5 pb-10 px-8'>
            <div
              className='w-full greener-br rounded-md'
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <div className='px-10'>
                <div className='green-text flex' style={{ justifyContent: 'center' }}>
                  <small className='text-sm py-3.5'>Close Task Reports</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

// export default TaskModal

const mapStateToProps = (state: any) => {
  const { task_report_data, loading } = state.shipment
  return { task_report_data, loading }
}
// @ts-ignore
export default connect(mapStateToProps, { getTaskReportById })(TaskModal)
