import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { connect, useSelector } from 'react-redux'

import { getOpsAdmins } from 'helpers/getOpsAdmins'

//icons
import close from 'assets/icons/close.svg'

import { AppRoutes } from 'routes/Approutes'

import { MultiSelect } from 'react-multi-select-component'
import CustomInput from 'components/textInputs/CustomInput'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'

import {
  WorkflowConfigurationModalType,
  confirmOptions,
  servicesOptions,
  departmentOptions,
  // portOptions,
  // portsObject,
} from 'types/workflowConfiguration/WorkflowConfiguration'

import { getFullPort } from 'helpers'

import { clearWorkflow, getAdmins, editWorkflow } from 'store/actions'

import { getAllowedPorts } from 'helpers'
import CustomSearchSelect from 'components/selectInputs/CustomSearchSelect'

const _Json = require('sea-ports')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '10px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '90',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

const services = {
  ocean_freight: 'Ocean Freight',
  customs_brokerage: 'Customs Brokerage',
  haulage: 'Haulage',
  tracking: 'Tracking',
  warehousing: 'Warehousing',
  air_freight: 'Air Freight',
}

const WorkflowConfigurationModal = ({
  closeModal,
  isOpen,
  id,
  loading,
  singleWorkflow,
  loading_admins,
  admins: remoteAdmins,
  getAdmins,
  clearWorkflow,
  isNotEditableConfigured = false,
  edit_loading,
  editWorkflow,
}: WorkflowConfigurationModalType) => {
  let local_admin = useSelector((state: any) => state.dashboard.admins)

  const [admins, setAdmins] = useState([])

  const [allAfricanPorts, setAllAfricanPorts] = useState([])

  //fetches the ports
  useEffect(() => {
    const parsePorts = getAllowedPorts(_Json.JSON)
    setAllAfricanPorts(parsePorts)
  }, [])

  console.log('all>>>', allAfricanPorts)

  useEffect(() => {
    if (local_admin?.length > 0) {
      setAdmins(getOpsAdmins(local_admin, true))
    } else {
      setAdmins(getOpsAdmins(remoteAdmins, true))
    }
  }, [local_admin, remoteAdmins])

  useEffect(() => {
    getAdmins()
  }, [])

  const navigate = useNavigate()

  const [selected, setSelected] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    if (singleWorkflow?.processes && singleWorkflow?.processes?.length > 0) {
      const obj = singleWorkflow?.processes?.[0]
      const result = Object.keys(services)
        .filter((key) => obj?.[key] === true)
        .map((key) => ({ label: services[key], value: key }))
      setSelected(result)
    }
  }, [singleWorkflow?.processes])

  const onEditSuccess = () => {
    closeModal()
    window.location.reload()
  }

  const onSubmit = (data) => {
    console.log('data>>>', data)
    const __data = {
      port_location: data?.port_location?.[0]?.value || data?.port_location?.value,
      escalation_contact: data?.escalation_contact?.value || data?.escalation_contact?.[0]?.value,
      ocean_freight: selected.find((i) => i?.value === 'ocean_freight') ? true : false,
      customs_brokerage: selected.find((i) => i?.value === 'customs_brokerage') ? true : false,
      haulage: selected.find((i) => i?.value === 'haulage') ? true : false,
      warehousing: selected.find((i) => i?.value === 'warehousing') ? true : false,
      air_freight: selected.find((i) => i?.value === 'air_freight') ? true : false,
      tracking: selected.find((i) => i?.value === 'tracking') ? true : false,
      process_description: 'Random',
      workflow_name: data?.workflow_name,
      department: singleWorkflow?.department,
      shipment_type: singleWorkflow?.shipment_type,
      milestones: singleWorkflow?.milestones,
    }
    if (isNotEditableConfigured) {
      editWorkflow &&
        editWorkflow(
          {
            id: id,
            data: __data,
          },
          onEditSuccess
        )
    } else {
      clearWorkflow()
      const _data = { ...data, services: selected.map((service) => service.label).join('/') }
      navigate(AppRoutes.workflowConfiguration.workflowCreate, {
        state: {
          data: {
            workflow_name: _data?.workflow_name,
            department: _data?.department?.label,
            port_location: _data?.port_location?.value,
            shipment_type: _data?.shipment_type?.value,
            ocean_freight: selected.find((i) => i?.value === 'ocean_freight') ? true : false,
            customs_brokerage: selected.find((i) => i?.value === 'customs_brokerage') ? true : false,
            haulage: selected.find((i) => i?.value === 'haulage') ? true : false,
            warehousing: selected.find((i) => i?.value === 'warehousing') ? true : false,
            air_freight: selected.find((i) => i?.value === 'air_freight') ? true : false,
            tracking: selected.find((i) => i?.value === 'tracking') ? true : false,
            escalation_contact: _data?.escalation_contact?.value,
            process_description: 'Random',
          },
          services: selected.map((service) => service.label).join('/'),
        },
      })
    }
  }

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange' })

  return (
    <Modal isOpen={isOpen} style={customStyles} className={'uploadmodal'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex justify-between items-center pt-6 px-6 pb-4 border-solid border-b-[1px] border-[#e6e7ec80]'>
          <div>
            <p className='text-xl black-text-3 font-medium'>Configuration Details</p>
            <p className='text-sm grey-text font-light'>Please input the following details</p>
          </div>
          <>
            <img
              className='cursor-pointer'
              onClick={() => {
                closeModal()
                reset()
                setSelected([])
              }}
              src={close}
              alt='close'
            />
          </>
        </div>
        <div className='py-4 md:py-6 px-6 flex flex-col gap-y-2 max-h-[calc(100vh_-_300px)] bg-[#F9FAFB] overflow-auto '>
          <CustomInput
            control={control}
            name={`workflow_name`}
            id={`workflow_name`}
            label={'Workflow Name'}
            placeholder={'Enter Workflow Name'}
            isRequired={true}
            type={'text'}
            errors={errors}
            isDisabled={false}
            defaultValue={singleWorkflow?.workflow_name ? singleWorkflow?.workflow_name : ''}
            min={''}
            max={''}
            icon=''
          />
          <CustomDefaultSelect
            control={control}
            name={`shipment_type`}
            id={`shipment_type`}
            label={'Shipment Type'}
            placeholder={''}
            isRequired={isNotEditableConfigured}
            errors={errors}
            isDisabled={isNotEditableConfigured}
            options={confirmOptions}
            defaultValue={
              singleWorkflow?.shipment_type
                ? [
                    {
                      label: singleWorkflow?.shipment_type?.charAt(0)?.toUpperCase() + singleWorkflow?.shipment_type?.slice(1),
                      value: singleWorkflow?.shipment_type,
                    },
                  ]
                : ''
            }
            icon=''
          />
          <div className='mb-3'>
            <p className='text-sm grey-text font-light mb-1.5'>Service Type</p>
            <MultiSelect
              hasSelectAll={false}
              options={servicesOptions}
              value={selected}
              onChange={setSelected}
              labelledBy='Select'
              className=''
              disabled={isNotEditableConfigured}
            />
          </div>
          <CustomSearchSelect
            control={control}
            name={`port_location`}
            id={`port_location`}
            label={'Port Location'}
            placeholder={'Select Port Location'}
            isRequired={true}
            errors={errors}
            isDisabled={false}
            options={allAfricanPorts}
            defaultValue={
              singleWorkflow?.processes?.[0]?.port_location
                ? [
                    {
                      label: getFullPort(_Json.JSON, singleWorkflow?.processes?.[0]?.port_location),
                      value: singleWorkflow?.processes?.[0]?.port_location,
                    },
                  ]
                : ''
            }
          />
          <CustomDefaultSelect
            control={control}
            name={`department`}
            id={`department`}
            label={'Department'}
            placeholder={''}
            isRequired={true}
            errors={errors}
            isDisabled={isNotEditableConfigured}
            options={departmentOptions}
            defaultValue={singleWorkflow?.department ? [{ label: singleWorkflow?.department, value: singleWorkflow?.department }] : ''}
            icon=''
          />
          <CustomSearchSelect
            control={control}
            name={`escalation_contact`}
            id={`escalation_contact`}
            label={'Escalation Contact'}
            placeholder={'Enter contact info'}
            isRequired={true}
            errors={errors}
            isDisabled={false}
            defaultValue={
              singleWorkflow?.escalation_contact_id
                ? [
                    {
                      label: `${singleWorkflow?.escalation_contact?.firstname} ${singleWorkflow?.escalation_contact?.lastname}`,
                      value: singleWorkflow?.escalation_contact_id,
                    },
                  ]
                : ''
            }
            options={admins}
          />
        </div>
        <div className='p-4 flex flex-col gap-y-4 border-solid border-t-[1px] border-[#e6e7ec80] shadow-[0_-3px_32px_-12px_rgba(0,0,0,0.18)]'>
          <PrimaryButtons
            title={isNotEditableConfigured ? 'Edit Details' : 'Continue'}
            style={{ color: '#FFF' }}
            disabled={!isDirty || !isValid || selected?.length <= 0}
            loading={edit_loading}
            icon={''}
            class_names={'newratebg'}
          />
        </div>
      </form>
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading_admins, admins } = state.dashboard

  const { edit_loading } = state.workflows

  return { error, loading_admins, admins, edit_loading }
}

export default connect(mapStateToProps, { getAdmins, clearWorkflow, editWorkflow })(WorkflowConfigurationModal)
