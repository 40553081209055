import React, { useRef, useState, useEffect } from 'react'
import star from 'assets/dock/star.svg'

const TipsAndSuggestionItem = ({ title }: { title: string }) => {
  const myElementRef = useRef<HTMLParagraphElement>(null)
  const [height, setHeight] = useState<number>()

  useEffect(() => {
    if (myElementRef.current) {
      const height = myElementRef.current.getBoundingClientRect().height
      height && setHeight(height)
    }
  }, [])

  return (
    <div className='mt-4 flex items-center gap-x-2'>
      <div style={{ height }} className='min-w-1 rounded-full bg-[#37B248] pb-6' />
      <p ref={myElementRef} className='grey-text-2 pl-1 text-xs 2xl:text-sm leading-[18px] font-light'>
        {title}
      </p>
    </div>
  )
}

const TipsAndSuggestion = () => {
  return (
    <div className='bg-[#022002] rounded-2xl p-5 pb-6'>
      <div className='flex items-center gap-x-2 bg-[#37B248] rounded-[21px] py-2 px-3 w-fit'>
        <img src={star} alt='' />
        <p className='text-[#F9FAFB] font-light'>Tips & Suggestions</p>
      </div>
      <div className='flex flex-col gap-y-4 mt-6'>
        <TipsAndSuggestionItem title='Adding a document or image to a task would require a person to provide a document or image as proof for completing that task.' />
        <TipsAndSuggestionItem title='The total workflow duration is calculated by summing the time it takes to complete all milestones within the workflow.' />
        <TipsAndSuggestionItem title='“Save as draft” allows you to save a config to edit later without losing all inputted data' />
        <TipsAndSuggestionItem title='When a task assignee is set to “Default”, the task will be assigned to the shipment assignee whenever a shipment with the same workflow conditions is created and assigned.' />
      </div>
    </div>
  )
}

export default TipsAndSuggestion
