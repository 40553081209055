import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import CustomInput from 'components/textInputs/CustomInputDocs'
import ShipmentUploadInput from 'components/docUploader/TaskUploader'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import Modal from 'react-modal'
import CustomSelect from 'components/selectInputs/CustomSelect'
import info from 'assets/icons/disclaimer-grey.svg'
import Delete from 'assets/icons/delete.svg'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { attachDocsToTask } from 'store/actions'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    transform: 'translate(-50%, -50%)',
    width: '425px',
    height: '650px',
    borderRadius: '10px',
    border: '0.01px solid #888',
    backgroundColor: '#F3F4F6',
  },
  overlay: {
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
    zIndex: '99999999999',
  },
}

function TaskDocumentModal(props: any) {
  const {
    workflow_task_data,
    documentModal,
    setDocumentModal,
    setSelectedTask,
    setReloadModal,
    modalMode,
    updateDocDetails,
    upload_loading,
    task_modal_loading,
    attachDocsToTask,
    callAction = true,
  } = props
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm()
  const [docName, setDocName] = useState<any>(updateDocDetails || undefined)
  const params = useParams()
  const navigate = useNavigate()

  interface Name {
    label: string
    value: string
  }

  interface UploadedDocument {
    name: Name
    file: Record<string, any>
  }

  const [docToBeUploaded, setDocToBeUploaded] = useState<UploadedDocument[]>([])
  const [disableFileInput, setDisableFileInput] = useState(modalMode === 'update' ? false : true)
  const [aliasDocName, setAliasDocName] = useState<any>(updateDocDetails || undefined)
  const [otherDocName, setOtherDocName] = useState('')

  console.log('workflow_task_data', workflow_task_data)
  console.log('doc_task_id', workflow_task_data?.[0]?._id)

  useEffect(() => {
    if (modalMode === 'update') {
      setValue('doc_upload', updateDocDetails)
      setDocName(updateDocDetails)
      setAliasDocName(updateDocDetails)
    } else {
      setValue('doc_upload', '')
    }
  }, [modalMode, setValue, updateDocDetails])

  useEffect(() => {
    if (!docName || Object.keys(docName)?.length < 1 || (docName?.value === 'other' && !otherDocName)) {
      setDisableFileInput(true)
    } else {
      setDisableFileInput(false)
    }
  }, [docName, otherDocName])

  const onUploadSuccess = () => {
    setDocToBeUploaded([])
    if (callAction) {
      //   setModalMode("")
      setOtherDocName('')
    }
    // closeModal && closeModal()
    setUploadError('')
  }

  const customOnChangeOption = (option) => {
    setDocName(option)
    setAliasDocName(option)
    !uploadError && resetField('document_name')
    setUploadError('')
    setOtherDocName('')
  }

  const onSubmit = (data: any) => {
    const uploadData = new FormData()

    data.forEach((doc, idx) => {
      uploadData.append('shipment_file', doc.file, doc.name.value)
      uploadData.append('document_name', doc.name.value)
      uploadData.append('document_type', doc.name.type)
    })

    const finalData = {
      id: workflow_task_data?.[0]?._id,
      data: uploadData,
    }

    resetField('doc_upload')
    setDocName('')

    callAction ? attachDocsToTask(finalData, onUploadSuccess) : attachDocsToTask(finalData, onUploadSuccess, false)
    setTimeout(() => {
      !task_modal_loading && setDocumentModal(false)
      setReloadModal(true)
    }, 1500)
  }

  const [uploadError, setUploadError] = useState('')

  const handleFileChange = (val) => {
    if (
      docToBeUploaded.map((item) => item.name.label?.toLowerCase()).includes(otherDocName?.toLowerCase()) ||
      //@ts-ignore
      Options.map((item) => item.label?.toLowerCase()).includes(otherDocName?.toLowerCase())
    ) {
      setUploadError('Other Document name already exists in selected documents or options, please update it')
      val.target.value = null
      return
    }

    if (val.target.files[0].size > 10000000) {
      setUploadError('Maximum file size of 10MB')
      val.target.value = null
      return
    }

    if (!['application/pdf', 'image/jpeg', 'image/png'].includes(val.target.files[0]?.type)) {
      setUploadError('Only PDF, JPEG, and PNG formats allowed.')
      val.target.value = null
      return
    }

    const data = modalMode === 'update' ? [] : [...docToBeUploaded]
    data.unshift({
      name: otherDocName
        ? {
            label: otherDocName?.toLowerCase(),
            value: otherDocName?.toLowerCase(),
            type: 'other',
          }
        : { ...docName, type: 'main' },
      file: val.target.files[0],
    })

    setDocToBeUploaded(data)
    //@ts-ignore
    setOptions((prevOptions) => prevOptions.filter((doc) => doc.value !== docName.value))

    resetField('document_name')
    setOtherDocName('')

    if (modalMode !== 'update') {
      setDocName('') //we do this so that the docName is empty and triggers the effect to disable state
      resetField('doc_upload')
    }

    val.target.value = null
    setUploadError('')
  }

  const [Options, setOptions] = useState([])
  const allRequiredDocsUploaded = workflow_task_data?.[0]?.task_files?.every((file) => file.is_uploaded)

  useEffect(() => {
    if (workflow_task_data) {
      const documentOptions = !allRequiredDocsUploaded
        ? workflow_task_data[0].task_files
            .filter((file) => !file.is_uploaded)
            .map((file) => ({
              label: file.document_name,
              value: file.document_name,
            }))
        : {}
      setOptions(documentOptions)
    }
  }, [workflow_task_data])

  console.log('docName', docName?.value)
  console.log('docToBeUploaded', docToBeUploaded)

  return (
    <Modal
      isOpen={documentModal}
      onRequestClose={() => {
        setDocumentModal(false)
      }}
      style={customStyles}
      contentLabel='Upload Other Docs Modal'
    >
      <div className=' flex flex-col'>
        <div className='pt-3'>
          <div className='flex px-8'>
            <p className='text-md font-semibold quote-text my-0'> Upload Document</p>
            <i className='ion-ios-close text-3xl ml-auto cursor-pointer' onClick={() => setDocumentModal(false)}></i>
          </div>
          <p className='font-light mb-2 px-8 text-sm'>Select documents to upload for this shipment</p>
          <div className=''>
            <div className=''>
              <div className='max-h-[calc(100vh_-_300px)] overflow-auto'>
                <div className='px-8 py-1 bg-[#F3F4F6]'>
                  <div className='bg-[#1F2937] rounded-md flex gap-x-2 mt-3'>
                    <div className='flex p-3'>
                      <span>
                        <img src={info} alt='' className='mt-[3px] min-w-[13px]' />
                      </span>
                      <p className='text-white text-sm font-light'>
                        <small className='font-medium ml-2'>Note, </small>
                        <small className='text-xs '>only upload PDF, PNG, JPG, JPEG, XLX files with a maximum file size of 10mb.</small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='pb-5 bg-[#F3F4F6]'>
                  <form className='px-8 justify-between gap-x-6' onSubmit={handleSubmit(onSubmit)}>
                    <div className=''>
                      <>
                        <div className='w-[100%]'>
                          <CustomSelect
                            control={control}
                            name={`doc_upload`}
                            id={`doc_upload`}
                            label={''}
                            placeholder={`Select document`}
                            isRequired={true}
                            errors={errors}
                            isDisabled={modalMode === 'update' ? true : false}
                            options={Options}
                            // options={workflow_task_data?.[0]?.task_files?.map((data: any) => {
                            //   console.log('workflow_task_data', data)
                            //   return {
                            //     label: data.document_name,
                            //     value: data.document_name,
                            //   }
                            // })}
                            defaultValue={modalMode === 'update' ? updateDocDetails : ''}
                            icon=''
                            customOnChange={customOnChangeOption}
                          />
                        </div>
                      </>
                      {/* @ts-ignore */}
                      {docName?.value === 'other' && (
                        <div>
                          <p className='text-xs black-text font-medium'>Document Name</p>
                          <CustomInput
                            control={control}
                            name={`document_name`}
                            id={`document_name`}
                            label={''}
                            placeholder={'input document name'}
                            isRequired={true}
                            type={'text'}
                            errors={errors}
                            isDisabled={disableFileInput}
                            defaultValue={''}
                            min={''}
                            max={''}
                            icon=''
                            customOnChange={setOtherDocName}
                          />
                        </div>
                      )}
                    </div>
                    <div className={`ml-auto self-center ${upload_loading ? 'mt-0' : 'mt-3'}`}>
                      {upload_loading ? (
                        <LoadingSpinner />
                      ) : (
                        <ShipmentUploadInput
                          control={control}
                          id={'ship_doc'}
                          name={'ship_doc'}
                          label={''}
                          placeholder={''}
                          defaultValue={''}
                          errors={errors}
                          isRequired={true}
                          disabled={disableFileInput}
                          handleFileChange={handleFileChange}
                        />
                      )}
                    </div>
                  </form>
                </div>
                <div className='px-8 flex flex-col gap-y-4 py-4'>
                  Document(s) selected {docToBeUploaded?.length}
                  {docToBeUploaded?.length > 0 ? (
                    docToBeUploaded.map((item, idx) => {
                      console.log(item)
                      return (
                        <div key={idx} className='p-4 solid-br rounded flex justify-between items-center relative'>
                          <div>
                            <p className='black-text-4 text-sm mb-1'>{item?.name.label}</p>
                            <p className='grey-text-1 text-sm font-light'>{Math.ceil(item?.file?.size / 1000)}kb</p>
                          </div>
                          <div className='cursor-pointer flex items-center gap-x-1.5'>
                            <img src={Delete} alt='' />
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <p className='black-text-3 text-[15px] text-center py-16'>
                      {modalMode === 'update' ? 'Choose file to be updated' : 'Choose documents to be uploaded'}
                    </p>
                  )}
                </div>
              </div>
              <div className='p-6 top-divider'>
                <PrimaryButtons
                  title={modalMode === 'update' ? 'Update Document' : 'Upload Document(s)'}
                  disabled={!(docToBeUploaded?.length > 0) || upload_loading}
                  onClick={() => onSubmit(docToBeUploaded)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
// export default TaskDocumentModal
const mapStateToProps = (state: any) => {
  const { loading, task_modal_loading } = state.tasks
  return { loading, task_modal_loading }
}
export default connect(mapStateToProps, { attachDocsToTask })(TaskDocumentModal)
