import React, { useState } from 'react'

import { Control, FieldErrors, useForm } from 'react-hook-form'

import CustomInput from 'components/textInputs/CustomInput'
import MilestoneFormTasks from './MilestoneFormTasks'

import { useWorkflowConfigurationContext } from 'pages/reports/shipments/workflowConfiguration/CreateWorkflowConfiguration'

interface FormData {
  firstName: string
  lastName: string
  // Add other form fields here
}

interface MilestoneFormType {
  control: Control<any>
  errors: FieldErrors<any>
}

const MilestoneForm = () => {
  const { milestones, selectedMilestoneId, updateMilestone } = useWorkflowConfigurationContext()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: 'onChange', values: { milestone_name: milestones?.find((i) => i?.uid === selectedMilestoneId)?.milestone_name } })

  return (
    <>
      <form>
        <CustomInput
          control={control}
          name={`milestone_name`}
          id={`milestone_name`}
          label={'Milestone Name'}
          placeholder={'Container Setup'}
          isRequired={false}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={milestones?.find((i) => i.uid === selectedMilestoneId)?.milestone_name}
          min={''}
          max={''}
          icon=''
          customOnChange={(value) => updateMilestone(selectedMilestoneId, 'milestone_name', value)}
        />
      </form>
      <div className='bottom-divider-2 pt-3 mb-6' />
      <div>
        <p className='black-text-2 font-normal '>Tasks for this milestone</p>
        {milestones
          ?.find((i) => i.uid === selectedMilestoneId)
          ?.milestone_tasks?.map((task, idx) => {
            return <MilestoneFormTasks key={task?.uid} idx={idx} uid={task?.uid} />
          })}
      </div>
    </>
  )
}

export default MilestoneForm
