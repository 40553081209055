import {
  GET_ALL_TASKS,
  GET_ALL_WORKFLOW_TASKS,
  GET_DELAYED_TASKS,
  GET_WORKFLOW_TASK_BY_ID,
  COMPLETE_WORKFLOW_TASK,
  ESCALATE_WORKFLOW_TASK,
  ATTACH_DOC_TO_TASK,
  SAVE_WORKFLOW_CONTAINER,
  API_ERROR,
  GET_TASKS,
  REASSIGN_TASK,
  GET_ALL_TASKS_CSV,
} from 'store/tasks/constants'

interface TaskInitialState {
  error: any
  loading: boolean
  table_loading: boolean
  task_modal_loading: boolean
  singleWorkflowLoading: boolean
  tasks: any
  tasks_csv: any
  total_workflow_task: any
  admin_tasks: any
  workflow_tasks: any
  delayed_workflow_tasks: any
  workflow_task_data: any
  re_assign_data: any
}

const initialState: TaskInitialState = {
  error: '',
  loading: true,
  table_loading: false,
  task_modal_loading: false,
  singleWorkflowLoading: false,
  tasks: [],
  tasks_csv: [],
  admin_tasks: [],
  total_workflow_task: null,
  workflow_tasks: [],
  delayed_workflow_tasks: [],
  workflow_task_data: [],
  re_assign_data: [],
}

const tasks = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ALL_TASKS.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_TASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        tasks: action.payload,
      }

    case GET_ALL_TASKS_CSV.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_ALL_TASKS_CSV.SUCCESS:
      return {
        ...state,
        loading: false,
        tasks_csv: action.payload,
      }

    case GET_TASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        admin_tasks: action.payload,
      }

    case GET_ALL_WORKFLOW_TASKS.REQUEST:
      return {
        ...state,
        table_loading: true,
      }

    case GET_ALL_WORKFLOW_TASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        table_loading: false,
        workflow_tasks: action.payload?.tasks,

        total_workflow_task: action.payload?.total_tasks,
      }

    case GET_DELAYED_TASKS.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_DELAYED_TASKS.SUCCESS:
      return {
        ...state,
        loading: false,
        delayed_workflow_tasks: action.payload,
      }

    case GET_WORKFLOW_TASK_BY_ID.REQUEST:
      return {
        ...state,
        task_modal_loading: true, // You might want to set loading to true here
        error: '',
      }

    case GET_WORKFLOW_TASK_BY_ID.SUCCESS:
      return {
        ...state,
        task_modal_loading: false,
        workflow_task_data: action.payload.data,
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        singleWorkflowLoading: false,
      }

    case COMPLETE_WORKFLOW_TASK.REQUEST:
      return {
        ...state,
        task_modal_loading: true,
      }

    case COMPLETE_WORKFLOW_TASK.SUCCESS:
      return {
        ...state,
        task_modal_loading: false,
        workflow_task_data: action.payload,
        tasks: [...state.tasks.filter((task) => task?._id !== action.payload._id)],
      }

    case COMPLETE_WORKFLOW_TASK.FAILURE:
      return {
        ...state,
        task_modal_loading: false,
      }

    case ESCALATE_WORKFLOW_TASK.REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ESCALATE_WORKFLOW_TASK.SUCCESS:
      return {
        ...state,
        loading: false,
        workflow_task_data: action.payload,
      }

    case REASSIGN_TASK.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case REASSIGN_TASK.SUCCESS:
      return {
        ...state,
        loading: false,
        re_assign_data: action.payload,
      }

    case ATTACH_DOC_TO_TASK.REQUEST:
      return {
        ...state,
        task_modal_loading: true,
      }
    case ATTACH_DOC_TO_TASK.SUCCESS:
      return {
        ...state,
        task_modal_loading: false,
      }
    case SAVE_WORKFLOW_CONTAINER.REQUEST:
      return {
        ...state,
        task_modal_loading: true,
      }
    case SAVE_WORKFLOW_CONTAINER.SUCCESS:
      return {
        ...state,
        task_modal_loading: false,
        workflow_task_data: action.payload,
      }

    default:
      return state
  }
}

export default tasks
