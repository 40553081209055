import { get, post, put, patch, del } from 'helpers/axios'

import {
  workflowUrl,
  createWorkflowUrl,
  getSingleWorkflowUrl,
  activateWorkflowUrl,
  deactivateWorkflowUrl,
  editWorkflowUrl,
  deleteWorkflowUrl,
  workflowCsvUrl,
} from 'api/endpoints'

const getWorkflows = (query: string) => {
  return get(`${workflowUrl}?${query}`)
}

const getWorkflowsCsv = (query: string) => {
  return get(`${workflowCsvUrl}?${query}`)
}

const createWorkflow = (data: object) => {
  return post(createWorkflowUrl, data)
}

const deleteWorkflow = (id: string) => {
  return del(`${deleteWorkflowUrl}/${id}`)
}

const getSingleWorkflow = (id: string) => {
  return get(`${getSingleWorkflowUrl}/${id}`)
}

const activateWorkflow = (id: string) => {
  return patch(`${activateWorkflowUrl}/${id}`)
}

const deactivateWorkflow = (id: string) => {
  return patch(`${deactivateWorkflowUrl}/${id}`)
}

const editWorkflow = (data: any) => {
  if (data?.type) {
    return put(editWorkflowUrl + `/${data.id}?type=${data?.type}`, data.data)
  }
  return put(editWorkflowUrl + `/${data.id}?type=${''}`, data.data)
}

const workflowServices = {
  getWorkflows,
  getWorkflowsCsv,
  createWorkflow,
  getSingleWorkflow,
  editWorkflow,
  activateWorkflow,
  deactivateWorkflow,
  deleteWorkflow,
}

export default workflowServices
