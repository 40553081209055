export function getCustomStyles(width) {
  if (width < 768) {
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        borderRadius: '10px',
        border: '0.01px solid #888',
        backgroundColor: '#F3F4F6',
      },
      overlay: {
        backgroundColor: 'rgba(6, 24, 2, 0.55)',
        zIndex: '99999999999',
      },
    }
  } else {
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        paddingRight: '0px',
        paddingLeft: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        transform: 'translate(-50%, -50%)',
        width: '676px',
        height: '90%',
        borderRadius: '10px',
        border: '0.01px solid #888',
        backgroundColor: '#F3F4F6',
      },
      overlay: {
        backgroundColor: 'rgba(6, 24, 2, 0.55)',
        zIndex: '99999999999',
      },
    }
  }
}
