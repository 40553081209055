import React, { ReactNode } from 'react'

interface PageTitleProp {
  text: string | ReactNode
  subtext: string
}

const PageTitle = ({ text, subtext }: PageTitleProp) => {
  return (
    <div className='pb-8 md:pb-10'>
      <h2 className='text-[#344336] font-medium text-2xl capitalize mb-1'>{text}</h2>
      <p className='grey-text text-sm'>{subtext}</p>
    </div>
  )
}

export default PageTitle
