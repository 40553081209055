import {
  GET_SHIPMENTS,
  GET_SHIPMENTS_BY_ID,
  GET_PUBLIC_SHIPMENT_BY_ID,
  GET_CONTAINERS,
  API_ERROR,
  GET_CONTAINER_BY_ID,
  GET_CONTAINER_BY_JOBNUMBER,
  GET_SHIPMENT_REPORT,
  GET_SHIPMENT_REPORT_BY_ID,
  GET_TASK_REPORT,
  GET_TASK_REPORT_BY_ID,
  UPDATE_SHIPMENT_STATUS,
  UPDATE_SHIPMENT_STATUS_SUCCESS,
  GET_TASK_REPORT_CSV,
  GET_SHIPMENT_REPORT_CSV,
} from 'store/shipment/constants'

const initialState = {
  error: '',
  loading: true,
  table_loading: false,
  booking_loading: true,
  my_shipments: [],
  total_shipments: null,
  shipment_data: {},
  public_shipment_data: {},
  upload_loading: false,
  uploaded_data: {},
  document_data: [],
  my_containers: [],
  total_containers: null,
  getting_containers: false,
  container_data: {},
  jobnumber_containers: [],
  jobnumber_loading: true,
  totaljob_containers: null,
  shipment_report: [],
  shipment_report_csv: [],
  shipment_report_data: {},
  total_shipment_report: null,
  tasks: [],
  task_report_csv: [],
  total_tasks: null,
  task_report_data: {},
}

const shipment = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SHIPMENTS_BY_ID.REQUEST || GET_PUBLIC_SHIPMENT_BY_ID.REQUEST:
      state = {
        ...state,
        loading: true,
        error: '',
      }
      break

    case GET_SHIPMENTS.SUCCESS:
      state = {
        ...state,
        booking_loading: false,
        table_loading: false,
        error: '',
        my_shipments: action.payload.data.shipments,
        total_shipments: action.payload.data.total_shipments,
      }
      break

    case GET_CONTAINERS.SUCCESS:
      state = {
        ...state,
        booking_loading: false,
        table_loading: false,
        error: '',
        my_containers: action.payload.data[0].containers,
        total_containers: action.payload.data[0].total_containers,
      }
      break

    case GET_SHIPMENTS.REQUEST:
      state = {
        ...state,
        table_loading: true,
      }
      break

    case GET_CONTAINERS.REQUEST:
      state = {
        ...state,
        table_loading: true,
      }
      break

    case GET_CONTAINER_BY_ID.REQUEST:
      state = {
        ...state,
        getting_containers: true,
      }
      break

    case GET_CONTAINER_BY_ID.SUCCESS:
      state = {
        ...state,
        getting_containers: false,
        container_data: action.payload.data,
      }
      break

    case GET_SHIPMENTS_BY_ID.SUCCESS:
      state = {
        ...state,
        loading: false,
        shipment_data: action.payload.data,
      }
      break

    case GET_PUBLIC_SHIPMENT_BY_ID.SUCCESS:
      state = {
        ...state,
        loading: false,
        public_shipment_data: action.payload.data,
      }
      break

    case GET_CONTAINER_BY_JOBNUMBER.REQUEST:
      state = {
        ...state,
        table_loading: true,
      }
      break

    case GET_CONTAINER_BY_JOBNUMBER.SUCCESS:
      state = {
        ...state,
        error: '',
        table_loading: false,
        jobnumber_loading: false,
        jobnumber_containers: action.payload.data[0].containers,
        totaljob_containers: action.payload.data[0].total_containers,
      }
      break

    // case UPLOAD_SHIPMENT_DOC.REQUEST:
    //   state = {
    //     ...state,
    //     upload_loading: true,
    //     error: "",
    //   };
    //   break;

    // case UPLOAD_SHIPMENT_DOC.SUCCESS:
    //   state = {
    //     ...state,
    //     upload_loading: false,
    //     uploaded_data: action.payload.data,
    //     document_data: action.payload.data.document_details,
    //     shipment_data: {}
    //   };
    //   break;
    case GET_SHIPMENT_REPORT.REQUEST:
      return {
        ...state,
        table_loading: true,
      }

    case GET_SHIPMENT_REPORT.SUCCESS:
      return {
        ...state,

        table_loading: false,
        shipment_report: action.payload.shipment_report,
        total_shipment_report: action.payload.total_shipment_report,
      }
    case GET_SHIPMENT_REPORT_CSV.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_SHIPMENT_REPORT_CSV.SUCCESS:
      return {
        ...state,
        loading: false,

        shipment_report_csv: action.payload.shipment_report,
      }

    case GET_SHIPMENT_REPORT_BY_ID.REQUEST:
      state = {
        ...state,
        loading: false,
        error: '',
      }
      break
    case GET_SHIPMENT_REPORT_BY_ID.SUCCESS:
      state = {
        ...state,
        loading: false,
        shipment_report_data: action.payload.data,
      }
      break

    case GET_TASK_REPORT.REQUEST:
      return {
        ...state,
        table_loading: true,
      }

    case GET_TASK_REPORT.SUCCESS:
      return {
        ...state,
        loading: false,
        table_loading: false,
        tasks: action.payload.tasks,
        total_tasks: action.payload.total_tasks,
      }

    case GET_TASK_REPORT_CSV.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_TASK_REPORT_CSV.SUCCESS:
      return {
        ...state,
        loading: false,
        task_report_csv: action.payload.tasks,
      }
    case GET_TASK_REPORT_BY_ID.REQUEST:
      state = {
        ...state,
        loading: false,
        error: '',
      }
      break
    case GET_TASK_REPORT_BY_ID.SUCCESS:
      state = {
        ...state,
        loading: false,
        task_report_data: action.payload.data,
      }
      break

    case UPDATE_SHIPMENT_STATUS:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_SHIPMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        shipment_report_data: action.payload,
      }

    case API_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break

    default:
      break
  }
  return state
}

export default shipment
