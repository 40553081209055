import React from 'react'

const CustomTabsTwo = (props: any) => {
  const { tabs, activeTab, setActiveTab } = props
  return (
    <div className=' w-100  mt-[18px] mb-[32px] overflow-x-auto'>
      <div className='tabs flex gap-[16px]  min-w-[398px]'>
        {tabs &&
          tabs.map((tab: string, index: number) => {
            return (
              <>
                <div className='tab cursor-pointer' onClick={() => setActiveTab(tab)} key={index}>
                  <div className='flex items-center mb-[12px] text-center'>
                    <p
                      className={`${activeTab === tab ? 'font-medium text-[#139C33]  mx-auto ' : 'grey-text-1'} 
                      text-sm lg:text-sm  `}
                    >
                      {tab}
                    </p>
                  </div>

                  <hr className={`${activeTab === tab ? 'border border-[#139C33]' : 'hidden'}`} />
                </div>
              </>
            )
          })}
      </div>
    </div>
  )
}

export default CustomTabsTwo
