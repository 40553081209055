import React from 'react'

const TasksHeader = ({ text, color, number }: { text: string; color: string; number: string | number }) => {
  return (
    <div className='py-2 px-4 rounded-lg bg-white flex items-center gap-x-1'>
      <svg xmlns='http://www.w3.org/2000/svg' width='7' height='7' fill='none'>
        <circle cx='3.5' cy='3.5' r='3.5' fill={color} />
      </svg>
      <p className='text-black text-sm'>
        {text} <span className='black-text-4'>({number})</span>
      </p>
    </div>
  )
}

export default TasksHeader
