import { useState } from 'react'

import TaskItem from 'components/tasks/TaskItem'
import { SingleTaskProp } from 'types/tasks/Tasks'
import TasksManagementModal from './TasksManagementModal'
import DocumentModal from './DocumentModal'
import EscalateModal from './EscalateModal'
import { connect } from 'react-redux'
import { getWorkflowTaskById } from 'store/actions'

interface TasksColumnProp {
  tasks: SingleTaskProp[]
  iscompletedTasks?: boolean
}

const TasksColumn = ({
  tasks,
  iscompletedTasks,
  workflow_task_data,
  singleWorkflowLoading,
}: TasksColumnProp & { workflow_task_data; singleWorkflowLoading }) => {
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false)
  const [isDocModalOpen, setIsDocModalOpen] = useState(false)
  const [isEscalsteModalOpen, setIsEscalsteModalOpen] = useState(false)
  const [selectedTask, setSelectedTask] = useState<SingleTaskProp | null>(null)

  const handleOpenTaskModal = (task) => {
    setSelectedTask(task)
    setIsTaskModalOpen(true)
  }

  const handleOpenDocUpload = (task) => {
    setSelectedTask(task)
    setIsDocModalOpen(true)
  }

  const handleOpenEscalateModal = (task) => {
    setSelectedTask(task)
    setIsEscalsteModalOpen(true)
  }

  return (
    <>
      <div className={`${iscompletedTasks ? 'lg:grid  lg:grid-cols-2 gap-x-6' : 'flex flex-col'} mt-3.5 gap-y-4 h-[calc(100vh-310px)] tasks-column`}>
        {tasks.map((task) => (
          <div>
            <TaskItem
              id={task?._id}
              openTaskModal={() => handleOpenTaskModal(task)}
              openDocUpload={() => handleOpenDocUpload(workflow_task_data)}
              openEscalateModal={() => handleOpenEscalateModal(workflow_task_data)}
              key={task?._id}
              job_number={task?.shipment_details.job_number}
              status={task?.status}
              timeline={task?.timeline}
              assignee={task?.assignee_details?.firstname?.charAt(0) + task?.assignee_details?.lastname?.charAt(0)}
              title={task?.name}
              bl_number={task?.shipment_details?.[0]?.bl_number}
              company_name={task?.company_details?.[0]?.company_name}
              filesCount={task?.filesCount}
              commentCount={task?.commentCount}
            />
          </div>
        ))}
      </div>
      {isTaskModalOpen && (
        <TasksManagementModal isOpen={isTaskModalOpen} setIsOpen={setIsTaskModalOpen} selectedTask={selectedTask} setSelectedTask={setSelectedTask} />
      )}
      {isDocModalOpen && (
        <DocumentModal documentModal={isDocModalOpen} setDocumentModal={setIsDocModalOpen} workflow_task_data={workflow_task_data} />
      )}
      {isEscalsteModalOpen && (
        <EscalateModal escalateModal={isEscalsteModalOpen} setEscalateModal={setIsEscalsteModalOpen} workflow_task_data={workflow_task_data} />
      )}
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { workflow_task_data, singleWorkflowLoading } = state.tasks
  return { workflow_task_data, singleWorkflowLoading }
}

export default connect(mapStateToProps, { getWorkflowTaskById })(TasksColumn)
