import React from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import Sidebar from './Sidebar'
import CountrySwitch from 'components/dock/CountrySwitch'

import SlidingPane from 'react-sliding-pane'
import 'react-sliding-pane/dist/react-sliding-pane.css'

//icons
import divider from 'assets/icons/grey-divider.svg'
import mobileMenu from 'assets/icons/mobile-menu.svg'

const Topnav = () => {
  let admin_data = useSelector((state: any) => state.auth.admin_data)
  let localStorageUser = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : localStorageUser

  //country flags
  const admin_role = {
    agent: 'Agent',
    admin: 'Admin',
    super_admin: 'Super Admin',
  }

  const [toggle, setToggle] = useState(false)

  //function to close the sidebar
  const handleToggle = () => {
    setToggle(false)
  }

  return (
    <>
      <div className='flex items-center h-full border-[1px] bg-white border-[#eaecf080] border-l-0 max-h-[60px] desktop-only'>
        <div className='flex items-center w-full'>
          <div className='ml-auto flex items-center text-sm pr-11'>
            <img
              src={`https://ui-avatars.com/api/?background=286A3A&color=ffffff&name=${admin_data?.firstname + admin_data?.lastname}`}
              height={30}
              width={30}
              alt='avatar'
              className='rounded-full'
            />
            <p className='text-sm text-[#667085] font-normal tracking-[-0.1px] ml-1 capitalize'>{admin_data?.firstname}</p>
            <p className='py-1 px-2 ml-2 text-xs bg-[#E5E7EB] text-[#667085] rounded-full capitalize'>
              {admin_role[admin_data?.role] || admin_role['admin']}
            </p>
            <div className='mx-4'>
              <img src={divider} alt='divider' />
            </div>
            <CountrySwitch />
          </div>
        </div>
      </div>
      {/* mobile top bar */}
      <>
        <div className='top-bar mobile-only bg-[#F9F9FA] px-5 py-6'>
          <div className='flex justify-between items-center'>
            <img src={mobileMenu} alt='mobile icon' onClick={() => setToggle(true)} className='cursor-pointer' />
            <p className='green-text-2 font-medium tracking-[0.04em]'>Dashboard</p>
            <CountrySwitch />
          </div>
        </div>
        {toggle && (
          <SlidingPane
            from='left'
            isOpen={toggle}
            width='70%'
            hideHeader={true}
            className='custom-slider'
            overlayClassName='some-custom-overlay-class'
            onRequestClose={() => setToggle(false)}
          >
            <div className='mobile'>
              <Sidebar handleToggle={handleToggle} />
            </div>
          </SlidingPane>
        )}
      </>
    </>
  )
}

export default Topnav
