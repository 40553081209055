import { Admin } from 'types/dashboard/Dashboard'

export const servicesOptions = [
  { label: 'Ocean Freight', value: 'ocean_freight' },
  { label: 'Haulage', value: 'haulage' },
  { label: 'Customs Brokerage', value: 'customs_brokerage' },
  { label: 'Tracking', value: 'tracking' },
]
export const confirmOptions = [
  { label: 'Import', value: 'import' },
  { label: 'Export', value: 'export' },
]
export const locationOptions = [
  { label: 'Lagos', value: 'lagos' },
  { label: 'Kano', value: 'kano' },
  { label: 'Port Harcourt', value: 'port_hacourt' },
]
export const departmentOptions = [{ label: 'Operations Team', value: 'operations' }]

export const departmentMapping = {
  operations: 'Operations Team',
  sales: 'Sales Team',
  tech_product: 'Tech and Product',
}

export const portMapping = {}

export const portOptions = [
  { label: 'Apapa port', value: 'NGAPP' },
  { label: 'Lagos port', value: 'NGLOS' },
  { label: 'Onne port', value: 'NGONN' },
  { label: 'Tincan port', value: 'NGTIN' },
  { label: 'Port-Harcourt port', value: 'NGPHC' },
  { label: 'Abuja port', value: 'NGABV' },
  { label: 'Forcados port', value: 'NGFOR' },
  { label: 'Brass port', value: 'NGBRS' },
  { label: 'Okrika port', value: 'NGOKR' },
  { label: 'Calabar port', value: 'NGCBQ' },
  { label: 'Akassa port', value: 'NGAKA' },
  { label: 'Warri port', value: 'NGWAR' },
  { label: 'Escravos port', value: 'NGESC' },
  { label: 'Burutu port', value: 'NGBUR' },
  { label: 'Bonny port', value: 'NGBON' },
  { label: 'Badagry port', value: 'NGBAD' },
  { label: 'Kaduna port', value: 'NGKAD' },
  { label: 'Sapele port', value: 'NGSAP' },
  { label: 'Qua Iboe port', value: 'NGQIB' },
  { label: 'Pennington Terminal port', value: 'NGPNT' },
  { label: 'Koko port', value: 'NGKOK' },
  { label: 'Degema port', value: 'NGDEG' },
  { label: 'Onitsha port', value: 'NGONI' },
]

export const portsObject = portOptions.reduce((acc, port) => {
  acc[port.value] = port.label
  return acc
}, {})

export const durationOptions = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
]

interface ProcessItem {
  _id: string
  ocean_freight: boolean
  customs_brokerage: boolean
  haulage: boolean
  tracking: boolean
  warehousing: boolean
  air_freight: boolean
  port_location: string
  created_by: string
  createdAt: string
  updatedAt: string
  __v: number
}

export type ProcessArray = ProcessItem[]

export type ProcessMapType = {
  [key in 'ocean_freight' | 'customs_brokerage' | 'haulage' | 'air_freight' | 'tracking']: string
}

export const processMap: ProcessMapType = {
  ocean_freight: 'Ocean',
  customs_brokerage: 'Customs',
  haulage: 'Haulage',
  air_freight: 'Air Freight',
  tracking: 'Tracking',
}

export interface WorkflowType {
  _id: string
  is_deleted: boolean
  is_draft: boolean
  is_active: boolean
  workflow_name: string
  duration: number
  duration_type: string
  department: string
  process_id: string
  shipment_type: string
  created_by: string
  createdAt: string
  updatedAt: string
  __v: number
  description: string
  escalation_contact: string
  updated_by: string
  deactivated_at: string
  deactivated_by: string
  activated_at: string
  activated_by: string
  workflow_created_by: {
    firstname: string
    lastname: string
  }[]
  process_details: ProcessArray
}

export type TableType = Pick<
  WorkflowType,
  'workflow_name' | 'department' | 'createdAt' | 'workflow_created_by' | 'process_details' | 'shipment_type' | '_id' | 'is_draft' | 'is_active'
>

export interface FilterObjectType {
  page: string
  count: string
  search?: string
}

export interface WorkflowconfigurationType {
  workflows: WorkflowType[]
  workflows_csv: WorkflowType[]
  total_workflow: number
  getWorkflows: (data: string) => {
    type: string
    payload: {
      data: string
    }
  }
  getWorkflowsCsv: (data: string) => {
    type: string
    payload: {
      data: string
    }
  }
  table_loading?: boolean
  loading: boolean
}
export interface WorkflowConfigurationTableType {
  filteredWorkflow: WorkflowType[]
  workflows_csv: WorkflowType[]
  total_workflow: number
  handlePerRowsChange: (newPerPage: number, page: number) => void
  handlePageChange: (page: number) => void
  handleSearch: (value: string, type?: string) => void
  setFilterDisplay: React.Dispatch<React.SetStateAction<boolean>>
  filterObject: FilterObjectType
}

export interface WorkflowConfigurationModalType {
  id?: string
  closeModal: () => void
  isOpen: boolean
  loading?: boolean
  edit_loading?: boolean
  singleWorkflow?: SingleWorkflow
  loading_admins?: boolean
  isNotEditableConfigured?: boolean
  getAdmins: () => {
    type: string
  }
  admins: Admin[]
  clearWorkflow: () => {
    type: string
    payload: null
  }
  editWorkflow?: (
    data: any,
    callback?: any
  ) => {
    type: string
    payload: {
      data: any
      callback?: any
    }
  }
}

////////////////////////////////////

type AssigneeDetails = {
  _id: string
  firstname: string
  lastname: string
}

type MilestoneTaskFile = any // Define this according to the actual structure of the milestone task files if needed

export type MilestoneTask = {
  _id: string
  duration_type: string
  milestone_task_name: string
  duration: number
  is_file_upload: boolean
  milestone_task_files: MilestoneTaskFile[]
  milestones_task_files_count: number
  assignee_details: AssigneeDetails
}

export type Milestone = {
  _id: string
  duration: string
  milestone_duration_type?: string
  milestone_name: string
  milestone_tasks: MilestoneTask[]
}

type Process = {
  _id: string
  ocean_freight: boolean
  customs_brokerage: boolean
  haulage: boolean
  tracking: boolean
  warehousing: boolean
  air_freight: boolean
  port_location: string
  created_by: string
  createdAt: string
  updatedAt: string
  __v: number
}

export type SingleWorkflow = {
  _id: string
  duration_type: string
  is_deleted: boolean
  is_draft: boolean
  is_active: boolean
  workflow_name: string
  escalation_contact: { firstname: string; lastname: string }
  escalation_contact_id: string
  department: string
  process_id: string
  shipment_type: string
  created_by: string
  createdAt: string
  updatedAt: string
  __v: number
  processes: Process[]
  milestones: Milestone[]
  milestonesCount: number
}
