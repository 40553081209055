import React from 'react'
interface Props {
  taskItem: any
}
const StatusComp = ({ taskItem }: Props) => {
  return (
    <div
      className={
        taskItem?.task_status === 'completed'
          ? 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#059C010D] text-[#059C01] capitalize  text-[10px] '
          : taskItem?.task_status === 'cancelled'
          ? 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#FFBAB333] text-[#8A0000] capitalize  text-[10px] '
          : taskItem?.task_status === 'on-track'
          ? 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#FFE75D33]  text-[#914D00] capitalize  text-[10px] '
          : taskItem?.task_status === 'incoming'
          ? 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#FFE75D33]  text-[#914D00]  capitalize text-[10px] '
          : taskItem?.task_status === 'delayed'
          ? 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#FFBAB333] text-[#8A0000] capitalize  text-[10px] '
          : 'min-w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#F3F4F6] text-[#374151] capitalize  text-[10px] '
      }
    >
      <p className={''}>{taskItem?.task_status}</p>
    </div>
  )
}

export default StatusComp
