import React, { useState } from 'react'
interface Props {
  selectedMilestoneId: string
  uid: string
  updateMilestoneTask: (selectedMilestoneId, uid, file, value) => void
}
const TaskToggle = ({ selectedMilestoneId, uid, updateMilestoneTask }: Props) => {
  const [toggle, setToggle] = useState<boolean>(false)
  console.log('toggle', toggle)
  return (
    <div
      className={
        toggle
          ? 'bg-[#139C33] rounded-full w-[18px] h-[10px] flex flex-col justify-center items-end px-[3px] cursor-pointer'
          : 'bg-[white] rounded-full w-[18px] h-[10px] flex flex-col justify-center px-[3px] cursor-pointer border border-[#139C33]'
      }
      onClick={() => {
        setToggle(!toggle)
        updateMilestoneTask(selectedMilestoneId, uid, 'customer_task', !toggle)
      }}
    >
      <div className={toggle ? 'bg-[white] w-[4px] h-[4px] rounded-full' : 'bg-[#139C33] w-[4px] h-[4px] rounded-full'}></div>
    </div>
  )
}

export default TaskToggle
