import Modal from 'react-modal'
import upload from 'assets/icons/upload-required-docs.svg'
import dot from 'assets/icons/dot.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    transform: 'translate(-50%, -50%)',
    width: '434px',
    // height: '485px',
    borderRadius: '10px',
    border: '0.01px solid #888',
    backgroundColor: '#F3F4F6',
  },
  overlay: {
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
    zIndex: '99999999999',
  },
}

const NoDocumentUploadedModal = (props: any) => {
  const { setDocumentModal, noDocumentUploadedModal, setNoDocumentUploadedModal, workflow_task_data } = props

  console.log('workflow dets', workflow_task_data)

  return (
    <Modal
      isOpen={noDocumentUploadedModal}
      style={customStyles}
      //   contentLabel='Task Management Modal'
      onRequestClose={() => {
        setNoDocumentUploadedModal(false)
      }}
    >
      <div className='px-6 py-14'>
        <div className='flex flex-col mb-4 items-center justify-center '>
          <img className='mb-6' src={upload} alt='upload doc' />
          <h1 className='text-[#005C00] font-medium text-xl mb-3'>Upload Required Files</h1>
          <p className='text-[#4B5563] max-w-[234px] text-center text-sm'>Upload the files below to mark this task as completed</p>
        </div>
        <div className='pt-4'>
          <h3 className='px-4 py-3 font-medium text-[#1F2937]'>Required Documents</h3>
          <div className='mt-2 flex flex-col'>
            {workflow_task_data[0].task_files
              .filter((file) => !file.is_uploaded)
              .map((file) => (
                <div className='text-[#007003] px-4 py-2'>
                  <img src={dot} alt='' className='inline-block mr-2' />
                  {file.document_name}
                </div>
              ))}
          </div>
        </div>
        <div className='pt-10 text-center'>
          <button
            onClick={() => {
              setNoDocumentUploadedModal(false)
              setDocumentModal(true)
            }}
            className='bg-transparent border border-[#139C33] font-medium text-sm transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-[#139C33] rounded w-full mb-3'
          >
            Upload documents
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default NoDocumentUploadedModal
