import {
  getMyShipmentsUrl,
  getPublicShipmentByIdUrl,
  getShipmentByIdUrl,
  getContainersUrl,
  getContainerByIdUrl,
  getContainerByJobNumberUrl,
  getShipmentReportUrl,
  getShipmentReportByIdUrl,
  getTaskReportUrl,
  getTaskReportByIdUrl,
  updateShipmentRemarkUrl,
  getShipmentReportCsvUrl,
  getTaskReportCsvUrl,
} from 'api/endpoints'
import { get, put } from 'helpers/axios'

const getShipments = (data: any) => {
  /**if theres a query parameter in the url */
  if (data.filter_shipment !== undefined || data.job !== undefined) {
    return get(`${getMyShipmentsUrl}?${data.filter_string}`)
  }
  return get(getMyShipmentsUrl)
}

const getContainers = (data: any) => {
  /**if theres a query parameter in the url */
  if (data.filter_shipment !== undefined || data.job !== undefined) {
    return get(`${getContainersUrl}?${data.filter_string}`)
  }
  return get(getContainersUrl)
}

const getContainerById = (id: string) => {
  return get(getContainerByIdUrl + `/${id}`)
}
// const uploadShipmentDoc = (data: any) => {
//   console.log("datahere>>>", data)
//   return post(uploadShipmentDocUrl + `/${data.id}`, data.data)
// }
const getShipmentById = (id: string) => {
  return get(`${getShipmentByIdUrl}/${id}`)
}

const getPublicShipmentById = (id: string) => {
  return get(`${getPublicShipmentByIdUrl}/${id}`)
}

const getContainerByJobNumber = (data: any) => {
  /**if theres a query parameter in the url */
  if (data.filter_shipment !== undefined || data.job !== undefined) {
    return get(`${getContainerByJobNumberUrl}/${data.id}?${data.filter_string}`)
  }
  return get(`${getContainerByJobNumberUrl}/${data.id}`)

  // return get(`${getContainerByJobNumberUrl}/${id}`);
}

const getShipmentReport = (query: string) => {
  return get(`${getShipmentReportUrl}?${query}`)
}

const getShipmentReportCsv = (query: string) => {
  return get(`${getShipmentReportCsvUrl}?${query}`)
}

const getShipmentReportById = (id: string) => {
  return get(`${getShipmentReportByIdUrl}/${id}`)
}

const getTaskReport = (query: string) => {
  return get(`${getTaskReportUrl}?${query}`)
}
const getTaskReportCsv = (query: string) => {
  return get(`${getTaskReportCsvUrl}?${query}`)
}

const getTaskReportById = (id: string, shipment_id: string) => {
  return get(`${getTaskReportByIdUrl}/${id}/${shipment_id}`)
}

const updateShipmentRemark = (data: any) => {
  return put(updateShipmentRemarkUrl + `/${data.id}`, data)
}

const shipmentServices = {
  getShipments,
  getShipmentById,
  getPublicShipmentById,
  getContainers,
  getContainerById,
  getContainerByJobNumber,
  getShipmentReport,
  getShipmentReportCsv,
  getShipmentReportById,
  getTaskReport,
  getTaskReportCsv,
  getTaskReportById,
  updateShipmentRemark,
}

export default shipmentServices
