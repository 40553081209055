import {
  GET_WORKFLOWS,
  CREATE_WORKFLOW,
  GET_SINGLE_WORKFLOW,
  EDIT_WORKFLOW,
  ACTIVATE_WORKFLOW,
  DEACTIVATE_WORKFLOW,
  DELETE_WORKFLOW,
  API_ERROR,
  CLEAR_WORKFLOW,
  GET_WORKFLOWS_CSV,
} from 'store/workflow/constants'

export const getWorkflows = (data: string) => {
  return {
    type: GET_WORKFLOWS.REQUEST,
    payload: { data },
  }
}

export const getWorkflowsSuccess = (response) => {
  return {
    type: GET_WORKFLOWS.SUCCESS,
    payload: response,
  }
}

export const getWorkflowsCsv = (data: string) => {
  return {
    type: GET_WORKFLOWS_CSV.REQUEST,
    payload: { data },
  }
}

export const getWorkflowsCsvSuccess = (response) => {
  return {
    type: GET_WORKFLOWS_CSV.SUCCESS,
    payload: response,
  }
}

export const createWorkflow = (data: any, callback: any) => {
  return {
    type: CREATE_WORKFLOW.REQUEST,
    payload: { data, callback },
  }
}

export const createWorkflowSuccess = (response: any) => {
  return {
    type: CREATE_WORKFLOW.SUCCESS,
    payload: { response },
  }
}

export const getSingleWorkflow = (id: string) => {
  return {
    type: GET_SINGLE_WORKFLOW.REQUEST,
    payload: { id },
  }
}

export const getSingleWorkflowSuccess = (response: any) => {
  return {
    type: GET_SINGLE_WORKFLOW.SUCCESS,
    payload: response,
  }
}

export const activateWorkflow = (id: string, callback?: () => void) => {
  console.log('activate workflow action', id)
  return {
    type: ACTIVATE_WORKFLOW.REQUEST,
    payload: { id, callback },
  }
}

export const activateWorkflowSuccess = (response: any) => {
  return {
    type: ACTIVATE_WORKFLOW.SUCCESS,
    payload: response,
  }
}

export const deactivateWorkflow = (id: string, callback?: () => void) => {
  return {
    type: DEACTIVATE_WORKFLOW.REQUEST,
    payload: { id, callback },
  }
}

export const deactivateWorkflowSuccess = (response: any) => {
  return {
    type: DEACTIVATE_WORKFLOW.SUCCESS,
    payload: response,
  }
}

export const deleteWorkflow = (id: string, callback: () => void) => {
  console.log('delete workflow action', id)
  return {
    type: DELETE_WORKFLOW.REQUEST,
    payload: { id, callback },
  }
}

export const deleteWorkflowSuccess = (response: any) => {
  return {
    type: DELETE_WORKFLOW.SUCCESS,
    payload: response,
  }
}

export const editWorkflow = (data: any, callback?: any) => {
  return {
    type: EDIT_WORKFLOW.REQUEST,
    payload: { data, callback },
  }
}

export const editWorkflowSuccess = (response: any) => {
  return {
    type: EDIT_WORKFLOW.SUCCESS,
    payload: response,
  }
}

export const clearWorkflow = () => {
  return {
    type: CLEAR_WORKFLOW,
    payload: null,
  }
}

export const workflowsApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
