import React from 'react'

interface Props {
  image: any
  label: string
  handleOnclick: () => void
  textColor: string
}
const TaskMangDropdownComp = ({ image, label, handleOnclick, textColor }: Props) => {
  return (
    <button className={`px-[10px] py-[15px] flex gap-[8px] hover:bg-gray-100 rounded-md items-center cursor-pointer`} onClick={handleOnclick}>
      <img src={image} alt='' className='w-[15px] h-[15px]'></img>
      <p className={`text-[${textColor}] font-normal text-[12px]`}>{label}</p>
    </button>
  )
}

export default TaskMangDropdownComp
