import { get, put, post, patch } from 'helpers/axios'

import {
  getAllTasksUrl,
  getTasksUrl,
  getAllWorkflowTasksUrl,
  getDelayedTasksUrl,
  getWorkflowTaskByIdUrl,
  completeWorkflowTaskUrl,
  escalateWorkflowTaskUrl,
  attachDocsToTaskUrl,
  saveWorkflowContainerUrl,
  reAssignTaskUrl,
  getAllTasksCsvUrl,
} from 'api/endpoints'

const getAllTasks = () => {
  return get(`${getAllTasksUrl}`)
}

const getAllTasksCsv = () => {
  return get(`${getAllTasksCsvUrl}`)
}

const getTasks = () => {
  return get(`${getTasksUrl}`)
}

const getAllWorkflowTasks = (query: string) => {
  // return get(`${getAllWorkflowTasksUrl}`)\
  return get(`${getAllWorkflowTasksUrl}?${query}`)
}

const getDelayedTasks = (query: string) => {
  // return get(`${getDelayedTasksUrl}`)\
  return get(`${getDelayedTasksUrl}?${query}`)
}

const getWorkflowTaskById = (id: string) => {
  return get(`${getWorkflowTaskByIdUrl}/${id}`)
}

const completeWorkflowTask = (id: string, data: any) => {
  // return put(completeWorkflowTaskUrl + `/${data.id}`, data)
  return put(`${completeWorkflowTaskUrl}/${id}`, data)
}

const escalateWorkflowTask = (data: any) => {
  return post(escalateWorkflowTaskUrl + `/${data.id}`, data)
}

const attachDocsToTask = (data: any) => {
  console.log('data>>>', data.document_name)
  return post(attachDocsToTaskUrl + `/${data.id}`, data.data)
}

const saveWorkflowContainer = (data: any) => {
  return put(saveWorkflowContainerUrl + `/${data.id}`, data)
}

const reAssignTask = (data: any) => {
  return patch(reAssignTaskUrl + `/${data.id}`, data)
}
const tasksServices = {
  getAllTasks,
  getAllTasksCsv,
  getTasks,
  getAllWorkflowTasks,
  getDelayedTasks,
  getWorkflowTaskById,
  completeWorkflowTask,
  escalateWorkflowTask,
  attachDocsToTask,
  saveWorkflowContainer,
  reAssignTask,
}

export default tasksServices
