import { get } from 'helpers/axios'

import { getDashboardParametersUrl, getPendingDashboardQuotesUrl, getAdminsUrl } from 'api/endpoints'

const getPendingDashboardQuotes = () => {
  return get(getPendingDashboardQuotesUrl)
}

const getDashboardParams = (id, period, isAdmin, country) => {
  if (isAdmin) return get(`${getDashboardParametersUrl}?ops_id=${id}&time_period=${period}&country=${country}`)
  return get(`${getDashboardParametersUrl}?time_period=${period}&country=${country}`)
}

const getAdmins = () => {
  return get(getAdminsUrl)
}

const dashboardServices = {
  getPendingDashboardQuotes,
  getDashboardParams,
  getAdmins,
}

export default dashboardServices
