import { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getShipmentReportById, updateShipmentStatus } from 'store/actions'
import PageLoading from 'components/partials/pageLoading'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import Layout from 'components/layout/Layout'
import arrowL from 'assets/icons/arrow-left2.svg'
import stick from 'assets/icons/stick.svg'
import Moment from 'react-moment'
import { ReportShipmentType, ReportType } from 'types/reports/Shipments'
import ServiceDocs from 'assets/icons/service-docs.svg'

const ShipmentById = ({ loading, shipment_report_data, getShipmentReportById, updateShipmentStatus }: ReportType) => {
  const location = useLocation()
  const nav = useNavigate()
  const { id, reportData } = location.state || {}
  const [accordion, setAccordion] = useState({})
  const [showAccordion, setShowAccordion] = useState(false)
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const toggleAccordion = (id) => {
    setAccordion((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])), // Close all accordions
      [id]: !prevState[id], // Toggle the clicked accordion
    }))
  }

  console.log('reportData', location)

  console.log('reportData', reportData)
  const reportData_id = reportData?.shipment_id
  const params_id = reportData?._id
  console.log(params_id)

  useEffect(() => {
    getShipmentReportById(reportData_id)
  }, [])

  console.log(shipment_report_data, 'shipment_report_data')

  const assignee_members = shipment_report_data?.milestones?.flatMap(({ tasks }) =>
    tasks.flatMap(({ assignee_details }) => assignee_details?.map(({ firstname, lastname }) => ({ firstname, lastname })))
  )

  const goToShipmentReport = (params_id) => {
    // nav(`/reports/shipments/${params_id}`)
    window.location.reload()
  }
  const [showDocs, setShowDocs] = useState([])
  const ref = useRef<HTMLDivElement>(null)
  const toggle = (index) => {
    const temp = [...showDocs]
    //@ts-ignore
    temp[index] = !temp[index]
    setShowDocs([...temp])
  }

  const onSubmit = (data) => {
    const newData = {
      id: reportData_id,
      remarks: data?.remarks,
      shipment_status: reportData?.shipment_details?.shipment_status,
      assigned_to: reportData?.shipment_assignee_details?.assigned_to,
    }
    //@ts-ignore
    updateShipmentStatus(newData, goToShipmentReport)
  }

  return (
    <>
      <Layout>
        <div className='dashboard-content-scroll'>
          {loading ? (
            <div className='mt-3'>
              <PageLoading title={'report'} />
            </div>
          ) : (
            <>
              <div className='flex'>
                <div className='pt-8 ml-5'>
                  <img src={arrowL} width='15px' onClick={() => nav(-1)} />
                </div>
                <div className='py-5 px-5'>
                  <p className='text-base text-[#004300] text-left mb-0 capitalize'>
                    {reportData?.shipment_details?.shipment_type},
                    {reportData?.shipment_details?.shipment_transport_type === 'air_freight' && 'Air Freight'}
                    {reportData?.shipment_details?.shipment_transport_type === 'ocean_freight' && 'Ocean Freight'}
                    {reportData?.shipment_details?.shipment_transport_type === 'warehousing' && 'Warehousing'}
                    {reportData?.shipment_details?.shipment_transport_type === 'customs_brokerage' && 'Customs Brokerage'}
                    {reportData?.shipment_details?.shipment_transport_type === 'haulage' && 'Haulage'}
                  </p>
                  <p className='text-sm text-[#4B5563] font-light text-left mb-0'>
                    {reportData?.shipment_details?.origin_port_code} ({reportData?.shipment_details?.bl_number})
                  </p>
                </div>
                <div className='ml-auto'></div>
              </div>
              <div className='bg-[#F9FAFB] px-3'>
                <p className='text-[#374151] uppercase font-base px-3 pt-6 text-sm'>
                  Shipment Timeline | <span className='font-light text-[#6B7280'>{reportData?.workflow_details?.duration} Days Spent</span>
                </p>

                {shipment_report_data?.milestones?.map((item: any, index) => {
                  console.log('taskItem', item)
                  return (
                    <div className='py-3' key={item._id}>
                      <div className='p-2 flex bg-white rounded-lg'>
                        <i className='ion-ios-add px-4 text-3xl black-text cursor-pointer' onClick={() => toggleAccordion(item.milestone_id)}></i>

                        <p className='text-[#374151] font-light Uppercase mb-0 pt-1.5'>
                          Milestone : <span className='text-[#374151] text-sm uppercase font-base'>{item?.milestone_name}</span>
                        </p>
                        <p className='ml-auto text-sm text-[#139C33] pt-1.5 mx-2'>{shipment_report_data?.percentage_completion}%</p>
                      </div>
                      {/* {accordion[item._id] && ( */}
                      {accordion[item.milestone_id] && (
                        <>
                          {item?.tasks?.map((taskItem: any, idx) => {
                            return (
                              <div key={taskItem._id}>
                                <div className='py-4 px-4 bg-[#FFFFFF] rounded-lg' style={{ marginTop: '-9px' }}>
                                  <div className='px-5 bg-[#F9FAFB] rounded-lg flex'>
                                    <div className='pr-5 py-5'>
                                      <p className='text-2xl font-base text-[#1F2937] text-center'>
                                        <Moment format='DD'>{taskItem?.createdAt}</Moment>
                                      </p>
                                      <p className='font-base text-[#4B5563] text-base text-center'>
                                        <Moment format='MMMM-YYYY'>{taskItem?.createdAt}</Moment>
                                      </p>
                                    </div>
                                    <div>
                                      <img src={stick} width={'0.5rem'} />
                                    </div>
                                    <div className='pl-3 py-5'>
                                      <div className='py-1'>
                                        <p className='font-light text-[#1F2937] capitalize text-sm'>{taskItem?.task_name}</p>
                                        <div className='flex items-center py-1'>
                                          <div className='h-[25px] w-[25px] items-center rounded-full bg-[#D0F5FF] mr-2'>
                                            <p className='text-[#1F2937] text-[10px] font-light text-center py-1'>
                                              {taskItem?.assignee_details?.[0]?.firstname.charAt(0)}
                                              {taskItem?.assignee_details?.[0]?.lastname.charAt(0)}
                                            </p>
                                          </div>
                                          <div className='h-[20px] w-[68px] flex items-center justify-center mr-2'>
                                            {taskItem?.task_status === 'completed' ? (
                                              <span className='capitalize rounded-full bg-[#059C010D] text-[#059C01] text-xs font-light p-1 px-2'>
                                                {taskItem?.task_status}
                                              </span>
                                            ) : taskItem?.task_status === 'cancelled' ? (
                                              <span className='capitalize rounded-full bg-light-red red-text text-xs font-light p-1.5 px-2'>
                                                {taskItem?.task_status}
                                              </span>
                                            ) : taskItem?.task_status === 'on-track' ? (
                                              <span className='capitalize rounded-full bg-light-yellow yellow-text text-xs font-light p-1.5 px-2'>
                                                {taskItem?.task_status}
                                              </span>
                                            ) : taskItem?.task_status === 'incoming' ? (
                                              <span className='capitalize rounded-full bg-light-purple purple-text text-xs font-light p-1.5 px-2'>
                                                {taskItem?.task_status}
                                              </span>
                                            ) : taskItem?.task_status === 'delayed' ? (
                                              <span className='capitalize rounded-full background-blue blue-text text-xs font-light p-1.5 px-2'>
                                                {taskItem?.task_status}
                                              </span>
                                            ) : (
                                              <></>
                                            )}
                                          </div>

                                          {taskItem?.is_file_upload === true ? (
                                            <>
                                              <div
                                                className='cursor-pointer justify-center gap-x-1 rounded-[23px] bg-[#F3F4F6] mr-2 py-[4px] px-[8px]'
                                                onClick={() => toggle(idx)}
                                              >
                                                <img src={ServiceDocs} width={'14px'} height={'14px'} />
                                                <p className='text-[##6B7280] text-xs font-light'> </p>

                                                {showDocs[idx] && (
                                                  <div className='absolute z-20'>
                                                    <div className='pt-2'>
                                                      <div className='p-1 w-[130px] bg-white shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded-md'>
                                                        {taskItem?.task_documents?.map((docItem: any) => {
                                                          console.log('taskItem', docItem)
                                                          return (
                                                            <a
                                                              href={docItem?.document_location}
                                                              target='_blank'
                                                              rel='noreferrer'
                                                              className='hover:bg-[#139c330f] items-center gap-x-2 rounded cursor-pointer'
                                                            >
                                                              <p key={docItem._id} className='text-[#4B5563] text-[12px] p-2'>
                                                                {docItem?.document_name}
                                                              </p>
                                                            </a>
                                                          )
                                                        })}
                                                      </div>
                                                      {/* <Docs linkView={docItem?.document_url} documentName={docItem?.document_name} /> */}
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          ) : null}
                                          <div className='flex items-center gap-x-1 rounded-full bg-[#F3F4F6] text-[##6B7280] text-xs font-light p-1.5 px-2'>
                                            <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                              <rect
                                                x='1'
                                                y='1'
                                                width='10'
                                                height='10'
                                                rx='2'
                                                stroke='#6B7280'
                                                stroke-linecap='round'
                                                stroke-linejoin='round'
                                              />
                                              <path d='M4 4V8' stroke='#6B7280' stroke-linecap='round' />
                                              <path d='M6 4V8' stroke='#6B7280' stroke-linecap='round' />
                                              <path d='M8 4V8' stroke='#6B7280' stroke-linecap='round' />
                                            </svg>

                                            <p>
                                              {taskItem?.container_details?.[0]?.completed_containers}/
                                              {taskItem?.container_details?.[0]?.total_containers}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className='mx-3'>
                <div className='bg-[#FFFFFF] py-4'>
                  <div className='p-4'>
                    <div>
                      <p className='text-[#007003] uppercase font-base py-3 text-sm'>GENERAL ASSIGNEE</p>
                      <span className='rounded-full bg-[#F3F4F6] text-xs px-3 py-2'>
                        <span className='rounded-full bg-[#FFFFFF] p-1'>
                          {shipment_report_data?.escalation_contact?.[0]?.firstname.charAt(0)}
                          {shipment_report_data?.escalation_contact?.[0]?.lastname.charAt(0)}
                        </span>
                        {shipment_report_data?.escalation_contact?.[0]?.firstname} {shipment_report_data?.escalation_contact?.[0]?.lastname}
                      </span>
                    </div>
                    <div className='modal-bottom-divider p-3'></div>
                    <div>
                      <p className='text-[#374151] uppercase font-base py-3 text-sm'>MEMBERS INVOLVED({assignee_members?.length})</p>

                      {assignee_members?.map((assignee, index) => {
                        // Check if the current assignee?'s first name and last name are the same as the next assignee?'s first name and last name
                        const isDuplicate =
                          index < assignee_members.length - 1 &&
                          assignee?.firstname === assignee_members[index + 1].firstname &&
                          assignee?.lastname === assignee_members[index + 1].lastname

                        if (!isDuplicate) {
                          return (
                            <div key={index} className='py-2'>
                              <span className='rounded-full bg-[#F3F4F6] text-xs px-3 py-2'>
                                <span className='rounded-full bg-[#FFFFFF] p-1'>
                                  {assignee?.firstname.charAt(0)}
                                  {assignee?.lastname.charAt(0)}
                                </span>
                                {assignee?.firstname} {assignee?.lastname}
                              </span>
                            </div>
                          )
                        } else {
                          return null // Return null for duplicates to skip rendering
                        }
                      })}
                    </div>
                  </div>
                  <div className='modal-bottom-divider'></div>
                  <div className='p-4'>
                    <p className='text-[#374151] uppercase font-base py-3 text-sm'>STATUS REMARK</p>
                    <div className='py-5 flex'>
                      <div>
                        <span className='rounded-full bg-[#D0F5FF] p-1 text-sm'>
                          {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.firstname?.charAt(0)}
                          {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.lastname?.charAt(0)}
                        </span>
                      </div>
                      <div>
                        <span className='text-xs px-2'>
                          {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.firstname}{' '}
                          {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.lastname}
                        </span>
                        <p className='p-2 font-light text-[#4B5563] text-sm'> {shipment_report_data?.remark_details?.[0]?.remarks}</p>
                        <p className='px-2 font-light text-[#9CA3AF] text-xs'>
                          {' '}
                          <Moment format='hh:mm a, MMMM DD YYYY'>{shipment_report_data?.createdAt}</Moment>
                        </p>
                      </div>
                    </div>
                    <form className='pt-14' onSubmit={handleSubmit(onSubmit)}>
                      <CustomTextarea
                        control={control}
                        name={'remarks'}
                        id={'remarks'}
                        label={''}
                        placeholder={'Add a comment or update'}
                        isRequired={false}
                        errors={errors}
                        isDisabled={false}
                        defaultValue={''}
                        icon=''
                        // style={{ height: '88px' }}
                      />
                      <div className='text-end'>
                        <PrimaryButtons title={'Comment'} style={{ width: 'max-content' }} disabled={false} loading={loading} />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  )
}

// export default ShipmentById

const mapStateToProps = (state: any) => {
  const { shipment_report_data, loading } = state.shipment
  return { shipment_report_data, loading }
}
//@ts-ignore
export default connect(mapStateToProps, { getShipmentReportById, updateShipmentStatus })(ShipmentById)
