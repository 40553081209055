import {
  GET_TASKS,
  GET_ALL_TASKS,
  GET_ALL_WORKFLOW_TASKS,
  GET_WORKFLOW_TASK_BY_ID,
  COMPLETE_WORKFLOW_TASK,
  ESCALATE_WORKFLOW_TASK,
  GET_DELAYED_TASKS,
  ATTACH_DOC_TO_TASK,
  SAVE_WORKFLOW_CONTAINER,
  REASSIGN_TASK,
  API_ERROR,
  GET_ALL_TASKS_CSV,
} from 'store/tasks/constants'

export const getAllTasks = () => {
  return {
    type: GET_ALL_TASKS.REQUEST,
    payload: null,
  }
}

export const getAllTasksCsv = () => {
  return {
    type: GET_ALL_TASKS_CSV.REQUEST,
    payload: null,
  }
}

export const getTasks = () => {
  return {
    type: GET_TASKS.REQUEST,
    payload: null,
  }
}

export const getTasksSuccess = (response) => {
  return {
    type: GET_TASKS.SUCCESS,
    payload: response,
  }
}

export const getAllTasksSuccess = (response) => {
  return {
    type: GET_ALL_TASKS.SUCCESS,
    payload: response,
  }
}

export const getAllTasksCsvSuccess = (response) => {
  return {
    type: GET_ALL_TASKS_CSV.SUCCESS,
    payload: response,
  }
}

export const getAllWorkflowTasks = (query: string) => {
  return {
    type: GET_ALL_WORKFLOW_TASKS.REQUEST,
    payload: { query },
  }
}

export const getAllWorkflowTasksSuccess = (response) => {
  return {
    type: GET_ALL_WORKFLOW_TASKS.SUCCESS,
    payload: response,
  }
}

export const getDelayedWorkflowTasks = (query: string) => {
  return {
    type: GET_DELAYED_TASKS.REQUEST,
    payload: { query },
  }
}

export const getDelayedWorkflowTasksSuccess = (response) => {
  return {
    type: GET_DELAYED_TASKS.SUCCESS,
    payload: response,
  }
}

export const getWorkflowTaskById = (id: string) => {
  return {
    type: GET_WORKFLOW_TASK_BY_ID.REQUEST,
    payload: { id }, // Pass both task_id and shipment_id in payload
  }
}

export const getWorkflowTaskByIdSuccess = (response: any) => {
  return {
    type: GET_WORKFLOW_TASK_BY_ID.SUCCESS,
    payload: response,
  }
}

export const completeWorkflowTask = (id: any, data: any, callback: () => void) => {
  return {
    type: COMPLETE_WORKFLOW_TASK.REQUEST,
    payload: { id, data, callback },
  }
}

export const completeWorkflowTaskSuccess = (response: any) => {
  return {
    type: COMPLETE_WORKFLOW_TASK.SUCCESS,
    payload: response,
  }
}

export const completeWorkflowTaskError = (error: any) => {
  return {
    type: COMPLETE_WORKFLOW_TASK.FAILURE,
    payload: error,
  }
}

export const escalateWorkflowTask = (data: any, callback: any) => {
  return {
    type: ESCALATE_WORKFLOW_TASK.REQUEST,
    payload: { data, callback },
  }
}

export const escalateWorkflowTaskSuccess = (response: any) => {
  return {
    type: ESCALATE_WORKFLOW_TASK.SUCCESS,
    payload: response,
  }
}

export const reAssignTask = (data: any, callback: any) => {
  return {
    type: REASSIGN_TASK.REQUEST,
    payload: { data, callback },
  }
}

export const reAssignTaskSuccess = (response: any) => {
  return {
    type: REASSIGN_TASK.SUCCESS,
    payload: response,
  }
}

export const attachDocsToTask = (data: any, callback: any) => {
  console.log(data)
  // console.log(callback);
  return {
    type: ATTACH_DOC_TO_TASK.REQUEST,
    payload: { data, callback },
  }
}
export const attachDocsToTaskSuccess = (response: any) => {
  return {
    type: ATTACH_DOC_TO_TASK.SUCCESS,
    payload: response,
  }
}

export const saveWorkflowContainer = (data: any, callback: any) => {
  return {
    type: SAVE_WORKFLOW_CONTAINER.REQUEST,
    payload: { data, callback },
  }
}

export const saveWorkflowContainerSuccess = (respose: any) => {
  return {
    type: SAVE_WORKFLOW_CONTAINER.SUCCESS,
    payload: respose,
  }
}

export const tasksApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
