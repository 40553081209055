import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { AppRoutes } from 'routes/Approutes'

//icons
import deactivate from 'assets/icons/deactivate.svg'

//components
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { clearBooking, deactivateWorkflow, editWorkflow } from 'store/actions'
import { connect } from 'react-redux'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '30px 30px',
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    // WebkitOverflowScrolling: "touch",
    transform: 'translate(-50%, -50%)',
    width: 'calc(100vw - 10%)',
    borderRadius: '10px',
    border: '0.01px solid #888',
  },
  overlay: {
    zIndex: '99999999999',
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
  },
}

interface Props {
  id?: string
  modalIsOpen?: any
  closeModal: () => void
  deactivate_loading: true
  deactivateWorkflow: (
    id: string,
    callback?: () => void
  ) => {
    type: string
    payload: {
      id: string
      callback: (() => void) | undefined
    }
  }
}

const DeactivateModal = ({ modalIsOpen, closeModal, deactivate_loading, deactivateWorkflow, id }: Props) => {
  const navigate = useNavigate()

  const onDeactivated = () => navigate(AppRoutes.workflowConfiguration.workflowView)

  const handleDeactivate = (id: string) => {
    deactivateWorkflow(id, onDeactivated)
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={customStyles}
        className={'successmodal'}
        contentLabel='Booking successful modal'
      >
        <div className='flex flex-col'>
          <img src={deactivate} alt='' width={95} className='mx-auto' />
          <div className='mt-6 mb-12'>
            <p className='text-[#AD0013] text-center my-3'>Deactivate Workflow?</p>
            <p className='text-sm font-light black-text-4 text-center mt-2'>
              Deactivating the workflow will prevent new tasks from generating with this workflow configuration.
            </p>
          </div>

          <div>
            <PrimaryButtons
              title='Yes, I want to deactivate this workflow'
              style={{ backgroundColor: '#AD0013' }}
              onClick={() => {
                id && handleDeactivate(id)
              }}
              disabled={false}
              loading={deactivate_loading}
              icon={''}
            />
            <div onClick={closeModal} className='black-text-3 text-sm text-center font-sm py-3 cursor-pointer'>
              Cancel
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { deactivate_loading } = state.workflows
  return { deactivate_loading }
}

export default connect(mapStateToProps, { deactivateWorkflow, editWorkflow })(DeactivateModal)
