import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Layout from 'components/layout/Layout'
import PageLoading from 'components/partials/pageLoading'
import PageTitle from 'components/partials/PageTitle'
import CustomTabs from 'components/customTabs/shipmentTasktab'
import TaskAntTable from '../../../components/reports/tasks/TaskAntTable'
import TaskFilter from 'components/reports/tasks/TaskFilter'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import TaskModal from 'components/reports/tasks/TaskModal'
import { getTaskReport, getTaskReportCsv } from 'store/actions'
import filter from 'assets/icons/Filter-grey.svg'
import search from 'assets/icons/search.svg'
import tray from 'assets/icons/tray.svg'
import { ReportTaskType, FilterObjectType, TaskType, TaskTabType } from 'types/reports/Tasks'
import { useSelector } from 'react-redux'
import { countryMap } from 'helpers/countryMapping'

// const Tasks = (props: any) => {
const Tasks = ({ loading, tasks, getTaskReport, total_tasks, table_loading, country_code, task_report_csv, getTaskReportCsv }: TaskType) => {
  // const { loading, tasks, getShipmentReport, total_tasks, table_loading } = props
  // @ts-ignore
  const [tab, setTab] = useState<TaskTabType[]>('Ongoing')
  const tab_result = ['Ongoing', 'Completed']

  const [isCompleted, setIsCompleted] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [filteredShipmentTasks, setfilteredShipmentTasks] = useState<ReportTaskType[]>([])
  // console.log(filteredShipmentTasks)
  const [filterDisplay, setFilterDisplay] = useState<boolean>(false)
  const [mobilefilterDisplay, setMobileFilterDisplay] = useState(false)
  const [exportMain, setExportMain] = useState<Boolean>(false)
  const [importMain, setImportMain] = useState<Boolean>(false)
  const [filteredTasks, setFilteredTasks] = useState<ReportTaskType[]>([])
  const [filterObject, setFilterObject] = useState<FilterObjectType>({ page: '0', count: '10', type: 'ongoing', country: countryMap[country_code] })

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [clear, setClear] = useState(false)

  const [page, setPage] = useState(0)
  const [startNum, setStartNum] = useState(1)
  const [endNum, setEndNum] = useState(10)

  const ref = useRef<HTMLDivElement>(null)
  const filter_ref = useRef<HTMLDivElement>(null)
  const mobilefilter_ref = useRef<HTMLDivElement>(null)
  const scrollref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [clickedData, setClickedData] = useState<ReportTaskType>()

  const navigate = useNavigate()
  const storedCountry = localStorage.getItem('country')
  console.log('userC >>>', storedCountry)

  const fetchShipmentReport = () => {
    // @ts-ignore
    const filter_string = new URLSearchParams(filterObject).toString()

    setPageLoading(true)
    getTaskReport(filter_string)
  }

  // handlepagechange
  const handlePageChange = (page) => {
    setFilterObject({ ...filterObject, ...{ page: String(page - 1) } })
  }

  // handle per rows change
  const handlePerRowsChange = async (newPerPage, page) => {
    setFilterObject({
      ...filterObject,
      ...{ page: String(page), count: String(newPerPage) },
    })
  }

  useEffect(() => {
    fetchShipmentReport()
  }, [filterObject])

  useEffect(() => {
    getTaskReportCsv('')
  }, [])

  useEffect(() => {
    if (tasks) {
      setfilteredShipmentTasks(tasks)
    }
  }, [tasks])

  const switchStatus = (complete: boolean) => {
    //@ts-ignore
    setTab(complete ? 'Completed' : 'Ongoing')
    setFilterObject({
      ...filterObject,
      type: complete ? 'completed' : 'ongoing',
    })
  }

  const handleSearch: (value: string, type?: string) => void = (value, type) => {
    if (value) {
      setFilterObject({ ...filterObject, page: '0', search: value })
    } else {
      setFilterObject({ ...filterObject, page: '0', search: value })
    }
  }

  const handleFilter = (value: any, type: string) => {
    if (value) {
      setFilterObject({
        ...filterObject,
        ...{
          page: '0',

          status: value.status,
          assignee_id: value.assignee_id,
        },
      })
    }
  }

  // useEffect to handle country filter
  useEffect(() => {
    setFilterObject({
      ...filterObject,
      ...{
        page: '0',

        country: countryMap[country_code],
      },
    })
  }, [country_code])

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (filterDisplay && filter_ref.current && !filter_ref.current.contains(e.target)) {
        setFilterDisplay(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filterDisplay])

  //creating the click outside to close drop down effect
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (mobilefilterDisplay && mobilefilter_ref.current && !mobilefilter_ref.current.contains(e.target)) {
        setMobileFilterDisplay(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [mobilefilterDisplay])

  console.log('taskreportcsv', task_report_csv)

  return (
    <Layout>
      <div className='dashboard-content-scroll'>
        {loading ? (
          <div>
            <div className='mt-3'>
              <PageLoading title={'Tasks'} />
            </div>
          </div>
        ) : (
          <>
            <div ref={scrollref}>
              <div className='mx-7 pt-4 md:pt-10 lg:mx-14 lg:pt-10 lg:mb-0'>
                <PageTitle text={'Task Reports'} subtext='Here’s a list of all your task reports.' />
              </div>

              <div className='lg:mt-9 w-full h-fit '>
                <div className='mt-4 w-full'>
                  <div className='desktop-only relative md:mx-7 lg:mx-14'>
                    {table_loading && (
                      <div className='absolute h-full w-full bg-[#7c8d788c] z-[999999] flex justify-center'>
                        <div className='pt-5'>
                          <LoadingSpinner top={false} height='10' loadingText='Loading table data' />
                        </div>
                      </div>
                    )}
                    <div className='pb-8 mt-[-12px]'>
                      {/* @ts-ignore */}
                      <CustomTabs
                        className='py-4 px-4'
                        tabs={tab_result}
                        activeTab={tab}
                        setActiveTab={setTab}
                        setIsCompleted={setIsCompleted}
                        switchStatus={switchStatus}
                      />
                      <hr style={{ marginTop: '-1px', marginBottom: '30px' }} />
                      <div ref={filter_ref} className=' absolute w-[336px] left-[280px] top-12 z-50'>
                        <TaskFilter
                          handleFilter={handleFilter}
                          filterDisplay={filterDisplay}
                          filteredShipmentTasks={filteredTasks}
                          setFilterDisplay={setFilterDisplay}
                          exportMain={exportMain}
                          setExportMain={setExportMain}
                          importMain={importMain}
                          setImportMain={setImportMain}
                          loading={loading}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                          startDate={startDate}
                          endDate={endDate}
                          setClear={setClear}
                          clear={clear}
                          tab={tab}
                        />
                      </div>
                      <TaskAntTable
                        filteredShipmentTasks={filteredShipmentTasks}
                        task_report_csv={task_report_csv}
                        total_tasks={total_tasks}
                        handlePerRowsChange={handlePerRowsChange}
                        handlePageChange={handlePageChange}
                        handleSearch={handleSearch}
                        setFilterDisplay={setFilterDisplay}
                        filterObject={filterObject}
                        tab={tab}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* mobile only */}

              {!loading && (
                <div className='mb-6 mobile-only'>
                  <div className='p-6'>
                    {/* @ts-ignore */}
                    <CustomTabs
                      className='py-4 px-4'
                      tabs={tab_result}
                      activeTab={tab}
                      setActiveTab={setTab}
                      setIsCompleted={setIsCompleted}
                      switchStatus={switchStatus}
                    />
                    <hr style={{ marginTop: '-1px' }} />
                  </div>
                  <div className=' flex sm:items-center flex-col justify-between sm:flex-row sm:justify-start gap-[16px] mx-[24px] mb-[24px]'>
                    {/* search */}
                    <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input w-[100%] sm:w-[auto] black-text mb-5'>
                      <div className='min-w-[16px] h-[16px] '>
                        <img src={search} alt=''></img>
                      </div>
                      <input placeholder='Search Shipment Task' className='w-[100%]' onChange={(e) => handleSearch(e.target.value, 'search')} />
                    </div>

                    <div
                      className='cursor-pointer px-4 flex items-center gap-x-1 border border-[#e5e7eb] rounded min-w-[90px] w-[15%] h-[37.6px] mt-[-20px]'
                      onClick={() => setMobileFilterDisplay(true)}
                    >
                      <div className='min-w-[16px] h-[16px] '>
                        <img src={filter} alt='' className='w-[100%]'></img>
                      </div>
                      <p className='text-xs text-[#9CA3AF]'>Filter</p>
                    </div>
                  </div>

                  {/* filter page */}
                  <div className='fixed top-0 w-full' ref={mobilefilter_ref}>
                    <TaskFilter
                      handleFilter={handleFilter}
                      filterDisplay={mobilefilterDisplay}
                      filteredShipmentTasks={filteredTasks}
                      setFilterDisplay={setMobileFilterDisplay}
                      loading={loading}
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      startDate={startDate}
                      endDate={endDate}
                      setClear={setClear}
                      clear={clear}
                      tab={tab}
                    />
                  </div>

                  {filteredShipmentTasks?.length > 0
                    ? filteredShipmentTasks.map((data, index: number) => {
                        return (
                          <div
                            className={`bg-white mx-[24px] mb-5 border border-grey-3 px-4 py-6 top-divider-2 ${index === 0 ? 'top-divider-2' : ''}`}
                            onClick={() => {
                              setIsOpen(true)
                              setClickedData(data)
                            }}
                          >
                            <div className='flex gap-[15px] items-center mb-2'>
                              <p className='text-lg black-text-3 font-medium'>{data?.name ? data?.name : 'N/A'}</p>
                            </div>

                            <div className='flex justify-between'>
                              <p className='text-[#296FD8] text-sm'>{data?.shipment_details.bl_number ? data?.shipment_details.bl_number : 'N/A'}</p>
                              {/* <div className='cursor-pointer'>
                                <img src={arrowR} alt='' />
                              </div> */}
                            </div>

                            {/* line */}
                            <div className='w-[80%] h-[1px] bg-[#F3F4F6] my-4'></div>

                            {/* Assignee and Milestone */}
                            <div className='flex gap-[24px]'>
                              {/* Assignee */}
                              {
                                //@ts-ignore
                                tab === 'Ongoing' ? (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Current Timeline</p>
                                    <p className='text-sm black-text-3 font-medium '>
                                      {data?.current_timeline ? `${data?.current_timeline}(s)` : 'N/A'}
                                    </p>
                                  </div>
                                ) : (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Actual Timeline</p>
                                    <p className='text-sm black-text-3 font-medium '>
                                      {data?.actual_timeline ? `${data?.actual_timeline}(s)` : 'N/A'}
                                    </p>
                                  </div>
                                )
                              }

                              {
                                //@ts-ignore
                                tab === 'Ongoing' ? (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Milestone</p>
                                    <p className='text-sm black-text-3 font-medium capitalize'>
                                      {data?.milestone_details?.milestone_name ? data?.milestone_details?.milestone_name : 'N/A'}
                                    </p>
                                  </div>
                                ) : (
                                  <div className='mb-2'>
                                    <p className='text-sm grey-text mb-2'>Completion Timeline</p>
                                    <p className='text-sm black-text-3 font-medium '>
                                      {data?.completion_timeline ? `${data?.completion_timeline}(s)` : 'N/A'}
                                    </p>
                                  </div>
                                )
                              }
                            </div>

                            <div className='flex gap-[24px] mt-4'>
                              {/* Assignee */}
                              <div className='mb-2'>
                                <p className='text-sm grey-text mb-2'>Assignee</p>
                                <p className='text-[#6B7280] text-xs font-light'>
                                  {' '}
                                  {data?.assignee_details?.firstname ? data?.assignee_details?.firstname : 'N/A'}&nbsp;
                                  {data?.assignee_details?.lastname ? data?.assignee_details?.lastname : 'N/A'}
                                </p>
                              </div>

                              {/* Milestone */}
                              {/* <div className='mb-2'>
                                <p className='text-sm grey-text mb-2'>Actual Timeline</p>
                                <p className='py-1 px-2 rounded-full mt-3 mb-3 w-[110px] text-center bg-light-green green-text text-sm'>On Time</p>
                              </div> */}
                            </div>
                          </div>
                        )
                      })
                    : !loading && (
                        <div className='mx-7 mt-8 block '>
                          <div className='flex justify-center mb-1'>
                            <img src={tray} alt='' />
                          </div>
                          <p className='text-center grey-text-3 font-light'>No data available</p>
                          {total_tasks > 0 && (
                            <div
                              className='px-2 py-2 w-fit cursor-pointer rounded bg-[#109B32] mt-6 mx-auto font-light'
                              onClick={() => {
                                handleFilter(
                                  {
                                    shipment_transport_type: '',
                                    shipment_type: '',
                                    shipment_status: '',
                                    start_date: '',
                                    end_date: '',
                                  },
                                  ''
                                )
                                setStartNum(1)
                                setEndNum(10)
                                setPage(0)
                                setFilterObject({ page: '0', count: '10', completed: isCompleted, type: 'ongoing' })
                              }}
                            >
                              <p className='text-white'>Go back to bookings</p>
                            </div>
                          )}
                        </div>
                      )}

                  {/* phone pagination */}
                  {filteredShipmentTasks?.length > 0 && (
                    <div className='mt-4 mx-7  py-6 flex justify-between'>
                      {/* left */}
                      {page === 0 ? (
                        <div className='rotate-180 w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='rotate-180 w-[24px] h-[24px]'
                          onClick={() => {
                            handlePageChange(page)
                            setPage(page - 1)
                            setStartNum(startNum - 10)
                            setEndNum(endNum - 10)
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}

                      {/* middle */}
                      <div>
                        <p>
                          {startNum}-{filteredShipmentTasks.length >= 10 ? endNum : endNum - (10 - (filteredShipmentTasks.length % 10))} of{' '}
                          {total_tasks}
                        </p>
                      </div>

                      {/* right */}
                      {page >= Math.ceil(total_tasks / 10 - 1) ? (
                        <div className='w-[24px] h-[24px]' onClick={() => {}}>
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#d6dfed'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          className='w-[24px] h-[24px] '
                          onClick={() => {
                            handlePageChange(page + 2)
                            setPage(page + 1)
                            setStartNum(startNum + 10)
                            setEndNum(filteredShipmentTasks.length >= 10 ? endNum + 10 : endNum - (10 - (filteredShipmentTasks.length % 10)))
                          }}
                        >
                          <svg
                            className='w-[100%] h-[100%]'
                            width='14'
                            height='14'
                            viewBox='0 0 14 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.95703 2.91732L9.04036 7.00065L4.95703 11.084'
                              stroke='#374151'
                              stroke-width='1.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                  {isOpen && <TaskModal isOpen={isOpen} setIsOpen={setIsOpen} taskData={clickedData} />}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

// export default Tasks

const mapStateToProps = (state: any) => {
  const { loading, tasks, total_tasks, table_loading, task_report_csv } = state.shipment
  const { country_code } = state.additionalDetails
  return { loading, tasks, total_tasks, table_loading, country_code, task_report_csv }
}

export default connect(mapStateToProps, { getTaskReport, getTaskReportCsv })(Tasks)
