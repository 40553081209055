import React from 'react'
interface Props {
  firstname: string
  lastname: string
  count?: number
}
const AdminComp = ({ firstname, lastname, count }: Props) => {
  return (
    <div className='rounded-[18px] flex gap-[8px] items-center bg-[#F3F4F6] text-xs py-[4px] pl-[4px] pr-[12px] w-fit'>
      <span className='rounded-[19px] bg-[#FFFFFF] w-[22px] h-[22px] flex items-center justify-center text-[10px] font-medium'>
        {firstname?.charAt(0)}
        {lastname?.charAt(0)}
      </span>
      <div className='text-xs font-medium '>
        {firstname} {lastname} {count && `(${count})`}
      </div>
    </div>
  )
}

export default AdminComp
