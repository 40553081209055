import React from 'react'
import drag from 'assets/icons/drag.svg'
import deleteIcon from 'assets/icons/delete.svg'
import littlecross from 'assets/icons/littlecross.svg'

import PrimaryButtons from 'components/buttons/PrimaryButtons'
import {
  StaticMilestoneFormI,
  WorkflowConfigurationContextI,
  useWorkflowConfigurationContext,
} from 'pages/reports/shipments/workflowConfiguration/CreateWorkflowConfiguration'

interface ConfigureMileStoneItemType {
  uid: string
  idx: number
  text: string
  removeMilestone: WorkflowConfigurationContextI['removeMilestone']
  mile: StaticMilestoneFormI
  selectedMilestoneId: WorkflowConfigurationContextI['selectedMilestoneId']
  setSelectedMilestoneId: WorkflowConfigurationContextI['setSelectedMilestoneId']
}

const ConfigureMileStoneItem = ({
  text,
  idx,
  uid,
  removeMilestone,
  mile,
  setSelectedMilestoneId,
  selectedMilestoneId,
}: ConfigureMileStoneItemType) => (
  <div
    onClick={() => setSelectedMilestoneId(mile?.uid)}
    className={`border-solid border-[1px] ${
      selectedMilestoneId === uid ? 'border-[#99FF7D] bg-[#139c330d]' : 'border-[#F3F4F6] '
    } px-2 py-4 rounded flex justify-between items-center cursor-pointer`}
  >
    <div className='flex items-center gap-x-3 '>
      <img src={drag} alt='' />
      <p className='black-text-3 text-sm'>{text}</p>
    </div>
    {idx !== 0 ? selectedMilestoneId === uid ? <img src={deleteIcon} onClick={() => removeMilestone(uid)} alt='delete' /> : <></> : <></>}
  </div>
)

const ConfigureMilestone = ({ totalMilestone }: { totalMilestone: number }) => {
  const { milestones, addMilestone, removeMilestone, selectedMilestoneId, setSelectedMilestoneId } = useWorkflowConfigurationContext()
  return (
    <div className='flex flex-col h-full'>
      <p className='green-text-4 px-4 py-6 font-medium bg-[#F9FAFB]'>Configuration Milestones({milestones?.length})</p>
      <div className='p-4 flex flex-col gap-y-3'>
        {milestones?.map((mile, idx) => (
          <ConfigureMileStoneItem
            key={mile?.uid}
            idx={idx}
            uid={mile?.uid}
            mile={mile}
            text={`${mile.milestone_name ? mile.milestone_name : `Milestone ${idx + 1}`}`}
            selectedMilestoneId={selectedMilestoneId}
            removeMilestone={removeMilestone}
            setSelectedMilestoneId={setSelectedMilestoneId}
          />
        ))}
      </div>
      <div className='px-4'>
        <PrimaryButtons
          title='Add New Milestone'
          style={{ backgroundColor: '#109b320d', color: '#109B32', marginTop: '24px' }}
          onClick={() => addMilestone()}
          disabled={false}
          icon={littlecross}
        />
      </div>
      <div className='uppercase flex items-center gap-x-2 py-6 px-4 bg-[#F9FAFB] mt-auto rounded-bl'>
        <p className='grey-text font-light'>total duration:</p>
        <p className='black-text-2'>{totalMilestone} days</p>
      </div>
    </div>
  )
}

export default ConfigureMilestone
