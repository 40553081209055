export const fetchTableData = (filterObject, setPageLoading, getTableData) => {
  // @ts-ignore
  const filter_string = new URLSearchParams(filterObject).toString()
  console.log('filter string', filter_string)
  setPageLoading(true)
  getTableData(filter_string)
}

// handlepagechange
export const handlePageChange = (page, setFilterObject, filterObject) => {
  setFilterObject({ ...filterObject, ...{ page: String(page - 1) } })
}

// handle per rows change
export const handlePerRowsChange = async (newPerPage, page, setFilterObject, filterObject) => {
  setFilterObject({
    ...filterObject,
    ...{ page: String(page), count: String(newPerPage) },
  })
}

// handle table search
export const handleSearch: (value: string, setFilterObject, filterObject) => void = (value, setFilterObject, filterObject) => {
  if (value) {
    setFilterObject({ ...filterObject, page: '0', search: value })
  } else {
    setFilterObject({ ...filterObject, page: '0', search: value })
  }
}

export const handleFilter = (value: any, setFilterObject, filterObject) => {
  console.log('value', value)
  if (value) {
    setFilterObject({
      ...filterObject,
      ...{
        page: '0',
        ...value,
      },
    })
  }
}
