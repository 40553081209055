import React from 'react'

//components
import Navbar from 'components/dock/Navbar'

//style
import './index.css'

const PrivacyPolicy = (): JSX.Element => {
  return (
    <>
      <Navbar isNav={false} />

      <div className='globe-green-bg'></div>
      <div className='lg:px-36 lg:mx-0 mx-5'>
        <div className='terms-card md:p-12 p-5 mb-32'>
          <h3 className='text-2xl black-text font-semibold uppercase'>Privacy Policy</h3>

          <div className='text-sm black-text  md:my-10 my-5'>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>1. PREAMBLE</p>

              <p className='mb-2'>
                <span className='font-semibold'>ONEPORT 365 INC.</span> respects your privacy and is committed to protecting your data.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>2. INTRODUCTION</p>

              <p className='mb-2'>
                This Data Protection and Privacy Policy (the “Privacy Policy”) describes your privacy rights regarding{' '}
                <span className='font-semibold'>ONEPORT 365 INC.</span>, its subsidiaries, related companies, agents and/or representatives (“we”,
                “us” or “our”) collection, use, storage, sharing and protection of your personal information. It applies to our website and all
                related sites, applications, services and tools (“Services”) regardless of how you access or use them.
              </p>
              <p className='mb-2'>
                This Privacy Policy applies to all forms of systems, operations and processes within our environment that involve the collection,
                storage, use, transmission and disposal of Personal Information (described below). It is provided to help you understand what
                information we collect from you, how the information collected is used, how we protect it, and your rights to it, amongst others.{' '}
              </p>
              <p className='mb-2'>
                This Privacy Policy is strictly restricted to only our Services and therefore does not apply to services that are not owned or
                controlled by us, including third-party websites. We are committed to handling all Personal Information provided to us in compliance
                with both applicable and model data protection and privacy laws.
              </p>
            </div>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>3. CONSENT</p>

              <p className='mb-2'>
                By using our Services in any manner, you confirm to us that you have read and accept this Privacy Policy and consent to the data
                practices described in this Privacy Policy. If you do not intend to accept this Privacy Policy or do not meet or comply with the
                provisions set forth herein, then you may not use our Services.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>4. THE INFORMATION WE COLLECT</p>

              <p className='mb-1 uppercase font-semibold'>A. Personal Information</p>
              <p className='mb-2'>
                As part of our operations, we collect and processes certain types of information (such as name, telephone numbers, residential
                address, email address etc.) of individuals that makes them easily identifiable. These individuals include current, past and
                prospective registered users, prospective users, newsletter applicants, any and all other persons that in one way or the other
                interact with our Platform (“Users”) and other individuals whom we communicate or deal with, jointly and/or severally.
              </p>
              <p className='mb-2'>
                To use our Services, you will be required and hereby undertake to voluntarily provide us with certain Personal Information. Personal
                Information refers to information relating to an identified person or information that can be used to identify you, (e.g., name, email
                address, telephone number). It may also include anonymous information that may be linked to you specifically, (e.g., IP address).
              </p>
              <p className='mb-2'>We use your Personal Information to:</p>
              <div className='mb-2'>
                {' '}
                <ol className='ml-10' style={{ listStyleType: 'lower-alpha' }}>
                  <li>provide you with the required Services</li>
                  <li>respond to your questions or requests</li>
                  <li>improve features, website content and analyse data to develop products and services</li>
                  <li>address inappropriate use of our Services</li>
                  <li>
                    prevent, detect and manage risk against misinformation, wrong use and illegal activities using internal and third-party screening
                    tools
                  </li>
                  <li>
                    send you marketing content, newsletters and service updates curated by us, however, we will provide you with an option to
                    unsubscribe if you do not want to hear from us
                  </li>
                  <li>
                    verify your identity and the information you provide in line with our statutory obligations using internal and third-party tools
                  </li>
                  <li>maintain up-to-date records</li>
                  <li>resolve disputes that may arise, including investigations by law enforcement or regulatory bodies</li>
                  <li>any other purpose that we disclose to you while providing our Services to you</li>
                </ol>
              </div>
              <p className='mb-2'>
                We may retrieve additional Personal Information about you from third parties and other identification/verification services. With your
                consent, we may also collect additional Personal Information in other ways including emails, surveys, and other forms of
                communication. Once you begin using our Services, we will keep records of and collect information regarding your activities as it
                relates to our Services. We will not share or disclose your Personal Information with a third party without your consent except as may
                be required for the purpose of providing you with our Services or under applicable legislation.
              </p>
              <p className='my-1 uppercase font-semibold'>B. Information that we collect from website visitors not Users</p>
              <p className='mb-2'>
                As a visitor to our website, we do not collect your Personal Information. However, so we can monitor and improve our website and
                Services, we may collect non-personally identifiable information. We will not share or disclose this information with third parties
                except as a necessary part of providing our Services. We may, where applicable, use the information to target advertisements to you.{' '}
              </p>
              <p className='my-1 uppercase font-semibold'>C. Purpose Limitation </p>
              <p className='mb-2'>
                We collect Personal Information only for identified purposes and for which consent has been obtained. Such Personal Information cannot
                be reused for another purpose that is incompatible with the original purpose, except consent is obtained for such purpose
              </p>
              <p className='my-1 uppercase font-semibold'>D. Data Minimization </p>
              <p className='mb-2'>
                We limit Personal Information collection and usage to data that is relevant, adequate, and absolutely necessary for carrying out the
                purpose for which the data is processed. We will evaluate whether and to what extent the processing of Personal Information is
                necessary and where the purpose allows, anonymize data will be used.
              </p>
              <p className='my-1 uppercase font-semibold'>E. Cookies </p>
              <p className='mb-2'>
                We use cookies to identify you as a User and make your user experience easier, customise our Services, content and advertising and
                where applicable help you ensure that your account security is not compromised. We also use cookies to mitigate risk and prevent
                fraud, promote trust and safety on our website. Cookies allow our servers to remember IP addresses, date and time of visits, monitor
                web traffic and prevent illegal activities.
              </p>
              <p className='mb-2'>
                Our cookies do not store personal or sensitive information. They simply hold a unique random reference to you so that once you visit
                our website, we can recognize who you are and provide certain content to you. If your browser or browser add-on permits, you have the
                choice to disable cookies on our website, however this may impact your experience with using our website.
              </p>
            </div>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>5. HOW WE PROTECT YOUR INFORMATION</p>

              <p className='mb-2'>
                We shall establish adequate controls in order to protect the integrity and confidentiality of your Personal Information, both in
                digital and physical format and to prevent your Personal Information from being accidentally or deliberately compromised.
              </p>
              <p className='mb-2'>
                We are committed to managing your Personal Information in line with global industry best practices. We protect your Personal
                Information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access,
                disclosure and alteration, we also use industry recommended security protocols to safeguard your Personal Information. Other security
                safeguards include but are not limited to data encryption, firewalls, and physical access controls to our building and files and only
                granting access to your Personal Information to only employees who require it to fulfill their job responsibilities. No Personal
                Information processing will be undertaken by an employee who has not been authorized to carry such out as part of their legitimate
                duties.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>6. HOW WE SHARE THE PERSONAL INFORMATION YOU PROVIDE</p>

              <p className='mb-2'>
                We do not sell, trade or rent Personal Information to anyone. However, to enable us render our Services to you on our website, we may
                share your information with trusted third parties, such third parties include sanctions screening and identity verification services
                as well as any third parties that you have directly authorized to receive your Personal Information.
              </p>
              <p className='mb-2'>
                We may disclose your Personal Information in compliance with applicable law or a legal obligation to which we are bound. Please note
                that third-party sites you engage with through our Services will have their privacy policies, and we are therefore not responsible for
                their actions, including their information protection practices. The use of your information by such third party will be subject to
                their applicable privacy policy, which you should carefully review.
              </p>
            </div>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>7. TRANSFER OF PERSONAL INFORMATION</p>
              <p className='my-1 uppercase font-semibold'>A. Third Party Processor</p>
              <p className='mb-2'>
                We may engage the services of third parties to process your Personal Information. The processing by such third parties shall be
                governed by a written contract with us to ensure adequate protection and security measures are put in place by the third party for the
                protection of Personal Information in accordance with the terms of this Privacy Policy.
              </p>
              <p className='my-1 uppercase font-semibold'>B. Transfer of Personal Information to Foreign Country</p>
              <p className='mb-2'>
                In providing you with the Services, we may rely on third-party servers located in foreign jurisdictions from time to time, which as a
                result, may require the transfer or maintenance of your Personal Information on computers or servers in foreign jurisdictions.
              </p>
              <p className='mb-2'>
                Where such Personal Information is to be transferred to a foreign country, we shall put adequate measures in place to ensure the
                security of such Personal Information.
              </p>
              <p className='mb-2'>
                The transfer and subsequent processing of such Personal Information would be in accordance with the provisions of the applicable Data
                Protection Laws and Regulation which we comply with. We will therefore only transfer and permit the processing of Personal Information
                in a foreign country where:
              </p>
              <div className='mb-2'>
                <ol className='ml-10' style={{ listStyleType: 'lower-alpha' }}>
                  <li>your consent has been obtained</li>
                  <li>
                    the transfer and processing are necessary for the performance of a contract between us or implementation of pre-contractual
                    measures taken at your request;
                  </li>
                  <li>the transfer and processing are necessary to conclude a contract between us and a third party in your interest;</li>
                  <li>the transfer and processing are necessary for reason of public interest</li>
                  <li>the transfer and processing are for the establishment, exercise or defense of legal claims</li>
                  <li>
                    the transfer and processing is necessary to protect your vital interests or that of other persons, where you are physically or
                    legally incapable of giving consent.
                  </li>
                </ol>
              </div>
              <p className='mb-2'>
                We will take all necessary steps to ensure that your Personal Information is transmitted and processed in a safe and secure manner.
                Details of the protection given when your Personal Information is transferred to foreign country shall be provided to you upon
                request.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>8. DATA RETENTION</p>

              <p className='mb-2'>
                We will retain your Personal Information for as long as is needed to provide our Services to you, comply with our legal and statutory
                obligations or verify your information with the required verification authorities. Therefore, even after discontinuance of our
                Services, we will retain certain Personal Information and transaction data to comply with our legal and regulatory obligations. All
                Personal Information shall be destroyed by us where we have satisfied the legal retention timeframe.
              </p>
              <p className='mb-2'>
                For all Personal Information and records obtained, used and stored by us, we shall perform periodical reviews of the data retained to
                confirm the accuracy, purpose, validity and requirement to retain. The length of storage of your Personal Information shall, amongst
                other things, be determined by:
              </p>
              <div className='mb-2'>
                <ol className='ml-10' style={{ listStyleType: 'lower-alpha' }}>
                  <li> the contract terms agreed between us or as long as it is needed for the purpose for which it was obtained; or</li>
                  <li>whether the transaction or relationship has statutory implication or a required retention period; or </li>
                  <li>
                    whether there is an express request for deletion of the Personal Information by you, provided that such request will only be
                    treated where you are not under any investigation which may require us to retain such Personal Information or there is no
                    subsisting contractual arrangement with you that would require the processing of the Personal Information; or
                  </li>
                  <li>
                    whether we have another lawful basis for retaining that information beyond the period for which it is necessary to serve the
                    original purpose.{' '}
                  </li>
                </ol>
              </div>
            </div>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>9. CHOICES AND RIGHTS</p>

              <p className='mb-2'>
                Once your Personal Information is held by us, you are entitled to reach out to us to exercise the following rights:
              </p>
              <div className='mb-2'>
                <ol className='ml-10' style={{ listStyleType: 'lower-alpha' }}>
                  <li>
                    the right to request for and access your Personal Information collected and stored. Where data is held electronically in a
                    structured form, such as in a Database, you have a right to receive that data in a common electronic format,
                  </li>
                  <li> the right to information on your Personal Information collected and stored,</li>
                  <li>the right to objection or request for restriction,</li>
                  <li>the right to object to automated decision making,</li>
                  <li>the right to request rectification and modification of your Personal Information which we keep,</li>
                  <li>the right to request for the deletion of your data</li>
                  <li>the right to request the movement of your data from us to a third party called the right to the portability of data; and</li>
                  <li>
                    the right to object to, and to request that we restrict the processing of your Personal Information. We shall inform you of the
                    effect of such restrictions and how it will affect our Services to you.
                  </li>
                </ol>
              </div>
              <p className='mb-2'>
                Your request will be reviewed by us and carried out except as restricted by law or our statutory obligations. You may review and
                update your Personal Information directly or by contacting us via email at support@oneport365.com.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>10. AGE RESTRICTION</p>

              <p className='mb-2'>
                Our Services are directed to people from the ages of 18 and above. If as a parent or guardian, you become aware that your child or
                ward has provided us with any information without your consent, please contact us via email at support@oneport365.
              </p>
            </div>
            <div className=''>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>11. UPDATES, MODIFICATIONS AND AMENDMENTS</p>

              <p className='mb-2'>
                We reserve the right to update, modify, change or revise this Privacy Policy from time to time. The changes will not be retroactive,
                and the most current version of this Privacy Policy which will always be on this page and will continue to govern our relationship
                with you. We advise that you check this page often, referring to the date of the last modification on the page.
              </p>
              <p className='mb-2'>
                We will also try to notify you of any material changes which could be done via email associated with your account or service
                notification. By continuing to use our Services after the changes become effective, you agree to be bound by the revised Privacy
                Policy.
              </p>
            </div>
            <div className='my-4'>
              <p className='black-text text-lg mb-2 font-semibold uppercase'>12. CONTACT US</p>

              <p className='mb-2'>
                You may contact us upon becoming aware of any breach of Personal Information or if your access to our Services has been compromised,
                to enable us to take the necessary steps towards ensuring the security of your Personal Information.
              </p>
              <p className='mb-2'>
                You may also contact us if you have any questions relating to this Privacy Policy or would like to find out more about exercising your
                data protection rights. All questions and inquiries may be sent to us via email at support@oneport365.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy
