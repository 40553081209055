import { all, fork } from 'redux-saga/effects'

//public
import authSaga from 'store/auth/saga'
import rateSaga from 'store/rate/saga'
import bookingSaga from 'store/booking/saga'
import settingsSaga from 'store/settings/saga'
import dashboardSaga from 'store/dashboard/saga'
import contactSaga from 'store/contact/saga'
import shipmentSaga from 'store/shipment/saga'
import bookingsSaga from './bookings/saga'
import additionalDetailsSaga from './additionalDetails/saga'
import workflowsSaga from 'store/workflow/saga'
import tasksSaga from 'store/tasks/saga'

export default function* rootSaga() {
  yield all([fork(authSaga)])
  yield all([fork(rateSaga)])
  yield all([fork(bookingSaga)])
  yield all([fork(settingsSaga)])
  yield all([fork(dashboardSaga)])
  yield all([fork(contactSaga)])
  yield all([fork(shipmentSaga)])
  yield all([fork(bookingsSaga)])
  yield all([fork(additionalDetailsSaga)])
  yield all([fork(workflowsSaga)])
  yield all([fork(tasksSaga)])
}
