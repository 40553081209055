import { ProcessArray, processMap } from 'types/workflowConfiguration/WorkflowConfiguration'

export const getJobCombination = (processDetail: ProcessArray, shipmentType?: string) => {
  const comboParts: string[] = []

  if (shipmentType) {
    comboParts.push(shipmentType)
  }

  for (const processName in processMap) {
    if (processDetail?.[0][processName]) {
      comboParts.push(processMap[processName])
    }
  }

  return comboParts.join('/')
}

export const getJobCombinationTwo = (processDetail: ProcessArray, shipmentType?: string) => {
  const { air_freight, customs_brokerage, haulage, ocean_freight, tracking } = processDetail[0]
  let combo = ''
  combo += shipmentType
  if (air_freight) {
    combo += `/${processMap['air_freight']}`
  }
  if (ocean_freight) {
    combo += `/${processMap['ocean_freight']}`
  }
  if (customs_brokerage) {
    combo += `/${processMap['customs_brokerage']}`
  }
  if (haulage) {
    combo += `/${processMap['haulage']}`
  }
  if (tracking) {
    combo += `/${processMap['tracking']}`
  }
  console.log('ship_tp', shipmentType)

  return combo
}
