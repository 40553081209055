import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import AuthRoute from './AuthRoute'
import { AppRoutes, ORL } from './Approutes'

//Coming soon
import ComingSoon from 'components/partials/ComingSoon'

//Authentication
import SignIns from 'pages/auth/SignIns'
import EmailVerifications from 'pages/auth/EmailVerifications'

//Tasks
import IndexTask from 'pages/tasks/IndexTask'

//Reports
import ReportShipments from 'pages/reports/shipments/Shipments'
import ReportShipmentById from 'pages/reports/shipments/ShipmentById'
import ReportTasks from 'pages/reports/tasks/Tasks'

//Messaging
import Messaging from 'pages/messaging/Messaging'

//Escalations
import Escalations from 'pages/escalations/Escalations'

//Workflow Configuration
import WorkflowConfiguration from 'pages/reports/shipments/workflowConfiguration/WorkflowConfiguration'
import ViewWorkflowDetails from 'pages/reports/shipments/workflowConfiguration/ViewWorkflowDetails'
import CreateWorkflowConfiguration from 'pages/reports/shipments/workflowConfiguration/CreateWorkflowConfiguration'

//Booking
import Booking from 'pages/booking/Booking'
import BookingDetailsRoot from 'pages/booking/BookingDetailsRoot'

//404 page
import NotFound from 'pages/notFound'

//Terms
import Terms from 'pages/terms/Terms'
import TermsKenya from 'pages/terms/TermsKenya'
import PrivacyPolicy from 'pages/terms/PrivacyPolicy'

//Dashboard
import Dashboards from 'pages/dashboard/Dashboards'

//Shipment
import Shipments from 'pages/shipment/Shipments'
import InvalidShipment from 'pages/shipment/InvalidShipment'
import ShipmentDetailsRoot from 'pages/shipment/ShipmentDetailsRoot'

//Settings
import Settings from 'pages/settings/Settings'
import Profile from 'pages/settings/Profile'
import ProfileEdit from 'pages/settings/ProfileEdit'

//containers
import Containers from 'pages/containers/Containers'

//freight details
import FreightDetails from 'pages/ofap/FreightDetails'
import FreightRates from 'pages/ofap/FreightRates'
import ShipmentInformation from 'pages/ofap/ShipmentInformation'

//scroll manager
import { ScrollManager } from 'components/scrollManager/ScrollManager'

const Router = () => {
  return (
    <ScrollManager>
      <Routes>
        {/* authentication */}
        <Route
          path={ORL}
          element={
            <AuthRoute>
              <SignIns />
            </AuthRoute>
          }
        />
        <Route
          path={AppRoutes.verifyEmail}
          element={
            <AuthRoute>
              <EmailVerifications />
            </AuthRoute>
          }
        />

        {/* dashboard  */}
        <Route
          path={AppRoutes.dashboard}
          element={
            <ProtectedRoute>
              <Dashboards />
            </ProtectedRoute>
          }
        />

        {/* shipment  */}
        <Route
          path={AppRoutes.shipments}
          element={
            <ProtectedRoute>
              <Shipments />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.shipmentsDetails}
          element={
            <ProtectedRoute>
              <ShipmentDetailsRoot />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.invalidShipment}
          element={
            <ProtectedRoute>
              <InvalidShipment />
            </ProtectedRoute>
          }
        />

        {/* New Routes */}
        {/* Tasks */}
        <Route
          path={AppRoutes.tasks}
          element={
            <ProtectedRoute>
              <IndexTask />
            </ProtectedRoute>
          }
        />
        {/* Reports */}
        <Route
          path={AppRoutes.reports.shipments}
          element={
            <ProtectedRoute>
              {/* @ts-ignore */}
              <ReportShipments />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.reports.shipmentById}
          element={
            <ProtectedRoute>
              {/* @ts-ignore */}
              <ReportShipmentById />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.reports.tasks}
          element={
            <ProtectedRoute>
              <ReportTasks />
            </ProtectedRoute>
          }
        />

        {/* Messaging */}
        <Route
          path={AppRoutes.messaging}
          element={
            <ProtectedRoute>
              <Messaging />
            </ProtectedRoute>
          }
        />

        {/* Escalations */}
        <Route
          path={AppRoutes.escalations}
          element={
            <ProtectedRoute>
              <Escalations />
            </ProtectedRoute>
          }
        />

        {/* Workflow Configurations */}
        <Route
          path={AppRoutes.workflowConfiguration.workflowView}
          element={
            <ProtectedRoute>
              <WorkflowConfiguration />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoutes.workflowConfiguration.workflowDetail}
          element={
            <ProtectedRoute>
              <ViewWorkflowDetails />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoutes.workflowConfiguration.workflowCreate}
          element={
            <ProtectedRoute>
              <CreateWorkflowConfiguration />
            </ProtectedRoute>
          }
        />

        <Route
          path={AppRoutes.workflowConfiguration.editWorkflow}
          element={
            <ProtectedRoute>
              <CreateWorkflowConfiguration />
            </ProtectedRoute>
          }
        />

        {/* ofap */}
        <Route
          path={AppRoutes.freightDetails}
          element={
            <ProtectedRoute>
              <FreightDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.freightDetailsEdit}
          element={
            <ProtectedRoute>
              <FreightDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.freightRates}
          element={
            <ProtectedRoute>
              <FreightRates />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.shipmentInformation}
          element={
            <ProtectedRoute>
              <ShipmentInformation />
            </ProtectedRoute>
          }
        />

        {/* settings  */}
        <Route
          path={AppRoutes.settings}
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.profileSettings}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path={AppRoutes.editProfileSettings}
          element={
            <ProtectedRoute>
              <ProfileEdit />
            </ProtectedRoute>
          }
        />

        {/* containers  */}
        <Route
          path={AppRoutes.container}
          element={
            <ProtectedRoute>
              <Containers />
            </ProtectedRoute>
          }
        />

        {/* Booking */}
        <Route
          path={AppRoutes.booking}
          element={
            <ProtectedRoute>
              <Booking />
            </ProtectedRoute>
          }
        />

        {/* Booking details */}
        <Route
          path={AppRoutes.bookingDetail}
          element={
            <ProtectedRoute>
              <BookingDetailsRoot />
            </ProtectedRoute>
          }
        />

        {/* Terms */}
        <Route path={AppRoutes.termsOfService} element={<TermsKenya />} />
        <Route path={AppRoutes.privacyPolicy} element={<PrivacyPolicy />} />

        {/* not found */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </ScrollManager>
  )
}

export default Router
