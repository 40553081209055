import {
  GET_SHIPMENTS,
  GET_SHIPMENTS_BY_ID,
  GET_PUBLIC_SHIPMENT_BY_ID,
  GET_CONTAINERS,
  GET_CONTAINER_BY_ID,
  GET_CONTAINER_BY_JOBNUMBER,
  GET_SHIPMENT_REPORT,
  GET_SHIPMENT_REPORT_BY_ID,
  GET_TASK_REPORT,
  GET_TASK_REPORT_BY_ID,
  UPDATE_SHIPMENT_STATUS,
  UPDATE_SHIPMENT_STATUS_SUCCESS,
  API_ERROR,
  GET_TASK_REPORT_CSV,
  GET_SHIPMENT_REPORT_CSV,
} from 'store/shipment/constants'

export const getShipments = (data: any) => {
  return {
    type: GET_SHIPMENTS.REQUEST,
    payload: { data },
  }
}

export const getShipmentsSuccess = (response: any) => {
  return {
    type: GET_SHIPMENTS.SUCCESS,
    payload: response,
  }
}

export const getContainers = (data: any) => {
  return {
    type: GET_CONTAINERS.REQUEST,
    payload: { data },
  }
}

export const getContainersSuccess = (response: any) => {
  return {
    type: GET_CONTAINERS.SUCCESS,
    payload: response,
  }
}

export const getContainerById = (id: string) => {
  return {
    type: GET_CONTAINER_BY_ID.REQUEST,
    payload: { id },
  }
}

export const getContainerByIdSuccess = (response: any) => {
  return {
    type: GET_CONTAINER_BY_ID.SUCCESS,
    payload: response,
  }
}

export const getContainerByJobNumber = (data: any) => {
  return {
    type: GET_CONTAINER_BY_JOBNUMBER.REQUEST,
    payload: { data },
  }
}

export const getContainerByJobNumberSuccess = (response: any) => {
  return {
    type: GET_CONTAINER_BY_JOBNUMBER.SUCCESS,
    payload: response,
  }
}
// export const uploadShipmentDoc = (data: any) => {
//   return {
//     type: UPLOAD_SHIPMENT_DOC.REQUEST,
//     payload: { data },
//   };
// };

// export const uploadShipmentDocSuccess = (response: any) => {
//   return {
//     type: UPLOAD_SHIPMENT_DOC.SUCCESS,
//     payload: response,
//   };
// };

export const getShipmentsById = (id: string) => {
  return {
    type: GET_SHIPMENTS_BY_ID.REQUEST,
    payload: { id },
  }
}

export const getShipmentsByIdSuccess = (response: any) => {
  return {
    type: GET_SHIPMENTS_BY_ID.SUCCESS,
    payload: response,
  }
}

export const getPublicShipmentById = (id: string) => {
  return {
    type: GET_PUBLIC_SHIPMENT_BY_ID.REQUEST,
    payload: { id },
  }
}

export const getPublicShipmentByIdSuccess = (response: any) => {
  return {
    type: GET_PUBLIC_SHIPMENT_BY_ID.SUCCESS,
    payload: response,
  }
}

export const getShipmentReport = (data: string) => {
  return {
    type: GET_SHIPMENT_REPORT.REQUEST,
    payload: { data },
  }
}
export const getShipmentReportCsv = (data: string) => {
  return {
    type: GET_SHIPMENT_REPORT_CSV.REQUEST,
    payload: { data },
  }
}

export const getShipmentReportSuccess = (response: any) => {
  return {
    type: GET_SHIPMENT_REPORT.SUCCESS,
    payload: response,
  }
}

export const getShipmentReportCsvSuccess = (response: any) => {
  return {
    type: GET_SHIPMENT_REPORT_CSV.SUCCESS,
    payload: response,
  }
}

export const getShipmentReportById = (id: string) => {
  return {
    type: GET_SHIPMENT_REPORT_BY_ID.REQUEST,
    payload: { id },
  }
}

export const getShipmentReportByIdSuccess = (response: any) => {
  return {
    type: GET_SHIPMENT_REPORT_BY_ID.SUCCESS,
    payload: response,
  }
}

export const getTaskReport = (data: string) => {
  return {
    type: GET_TASK_REPORT.REQUEST,
    payload: { data },
  }
}
export const getTaskReportCsv = (data: string) => {
  return {
    type: GET_TASK_REPORT_CSV.REQUEST,
    payload: { data },
  }
}

export const getTaskReportSuccess = (response: any) => {
  return {
    type: GET_TASK_REPORT.SUCCESS,
    payload: response,
  }
}
export const getTaskReportCsvSuccess = (response: any) => {
  return {
    type: GET_TASK_REPORT_CSV.SUCCESS,
    payload: response,
  }
}

// export const getTaskReportById = (id: string) => {
//   return {
//     type: GET_TASK_REPORT_BY_ID.REQUEST,
//     payload: { id },
//   }
// }

export const getTaskReportById = (id: string, shipment_id: string) => {
  return {
    type: GET_TASK_REPORT_BY_ID.REQUEST,
    payload: { id, shipment_id }, // Pass both task_id and shipment_id in payload
  }
}

export const getTaskReportByIdSuccess = (response: any) => {
  return {
    type: GET_TASK_REPORT_BY_ID.SUCCESS,
    payload: response,
  }
}

export const updateShipmentStatus = (data: any, callback: any) => {
  return {
    type: UPDATE_SHIPMENT_STATUS,
    payload: { data, callback },
  }
}
export const updateShipmentStatusSuccess = (response: any) => {
  return {
    type: UPDATE_SHIPMENT_STATUS_SUCCESS,
    payload: response,
  }
}

export const shipmentApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
