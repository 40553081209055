import { useEffect, useRef, useState } from 'react'
import { getWorkflowTaskById, completeWorkflowTask, escalateWorkflowTask, saveWorkflowContainer } from 'store/actions'
import { connect } from 'react-redux'

import { taskbgHelper } from 'helpers/taskbgHelper'

//icons
import pin from 'assets/icons/pin.svg'
import message from 'assets/icons/message.svg'
import threedots from 'assets/icons/threedotsgrey.svg'
import divider from 'assets/icons/divider.svg'
import darckupload from 'assets/icons/darkupload.svg'
import darckcheck from 'assets/icons/darkcheck.svg'
import escalate from 'assets/icons/escalate.svg'

const MobileActions = ({ handleCompeteTask, openDocUpload, openEscalateModal, workflow_task_data, status }) => (
  <div className='bg-white rounded-lg px-1 py-1 shadow-lg'>
    {/* <div
      onClick={handleCompeteTask}
      className='text-sm py-1.5 text-[#004300] flex items-center gap-x-3 px-4 cursor-pointer hover:bg-gray-100 rounded'
    >
      <img src={darckcheck} alt='' />
      <span>Mark as complete</span>
    </div> */}
    {workflow_task_data?.[0]?.task_files?.length === 0 || status === 'completed' ? null : (
      <div onClick={openDocUpload} className='text-sm py-1.5 text-[#004300] flex items-center gap-x-3 px-4 cursor-pointer hover:bg-gray-100 rounded'>
        <img src={darckupload} alt='' />
        <span>Upload File</span>
      </div>
    )}
    <div
      onClick={openEscalateModal}
      className='text-sm py-1.5 text-[#004300] flex items-center gap-x-3 px-4 cursor-pointer hover:bg-gray-100 rounded'
    >
      <img src={escalate} alt='' />
      <span>Escalate</span>
    </div>
  </div>
)

interface TaskItemProp {
  id: string
  openTaskModal: () => void
  openDocUpload: () => void
  openEscalateModal: () => void
  timeline: string
  job_number: string
  assignee: string
  title: string
  status: string
  bl_number: string | number
  company_name: string
  filesCount: string | number
  commentCount: string | number
}

const TaskItem = ({
  id,
  openTaskModal,
  openDocUpload,
  openEscalateModal,
  timeline,
  title,
  bl_number,
  job_number,
  company_name,
  filesCount,
  commentCount,
  status,
  assignee,
  completeWorkflowTask,
  workflow_task_data,
}: TaskItemProp & { completeWorkflowTask: any; workflow_task_data }) => {
  const [selectedContainers, setSelectedContainers] = useState([])

  const handleCompleteTask = () => {
    completeWorkflowTask(id, { container_ids: selectedContainers })
  }

  const [isChosen, setIsChosen] = useState<object>({})
  const actionref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (isChosen && actionref.current && !actionref.current.contains(e.target)) {
        setIsChosen({})
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isChosen])

  return (
    <div className='p-4 bg-white rounded-lg cursor-pointer'>
      <div className='text-xs flex items-center justify-between relative'>
        <p className={`px-2 py-1 rounded-full ${taskbgHelper(status?.toLowerCase(), false, false)}`}>{timeline || 'Completed'}</p>
        {/* {status === 'on-track' && (
          <p>
            <img
              src={threedots}
              className='p-1'
              alt=''
              onClick={() => {
                setIsChosen((prev) => ({ ...prev, [id]: !prev[id] }))
              }}
            />
            {isChosen[id] && (
              <div ref={actionref} className='absolute right-0 top-6'>
                <MobileActions
                  status={status}
                  workflow_task_data={workflow_task_data}
                  openEscalateModal={openEscalateModal}
                  openDocUpload={openDocUpload}
                  handleCompeteTask={handleCompleteTask}
                />
              </div>
            )}
          </p>
        )} */}
        <p className='h-7 w-7 bg-[#286A3A] flex items-center justify-center rounded-full text-white text-[11px]'>{assignee}</p>
      </div>
      <div onClick={openTaskModal} className='mt-6'>
        <p className='text-base text-black capitalize'>{title}</p>
        <div className='flex items-center gap-x-1 mt-2'>
          <span className='text-[#139C33] text-xs'>{job_number}</span>
          <img src={divider} alt='' />
          <span className='grey-text-1 capitalize text-xs font-light'>{company_name}</span>
        </div>
        <div className='flex items-center gap-x-2 mt-6'>
          <div className='flex items-center gap-x-1'>
            <img src={pin} alt='' />
            <p className='grey-text text-sm font-light'>{filesCount}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { workflow_task_data, singleWorkflowLoading } = state.tasks
  return { workflow_task_data, singleWorkflowLoading }
}

export default connect(mapStateToProps, { completeWorkflowTask, escalateWorkflowTask })(TaskItem)
