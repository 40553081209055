import React from 'react'

//style
import './index.css'

//icons
import bus from 'assets/icons/bus.svg'
import busActive from 'assets/icons/bus-green.svg'
import ocean from 'assets/icons/ocean.svg'
import oceanActive from 'assets/icons/ocean-green.svg'
import tick from 'assets/icons/tick.svg'
import tickActive from 'assets/icons/tick-green.svg'
import map from 'assets/icons/map.svg'
import mapActive from 'assets/icons/map-green.svg'
import { ShipmentTabType } from 'types/reports/Shipments'

// const CustomTabs = (props: any): JSX.Element => {
// const { tabs, activeTab, setActiveTab, setIsCompleted, switchStatus, icons } = props
const CustomTabs = ({ tabs, activeTab, setActiveTab, setIsCompleted, switchStatus, icons }: ShipmentTabType) => {
  const iconNames = {
    bus: {
      name: bus,
      width: 20,
      height: 20,
    },
    'bus-active': {
      name: busActive,
    },
    ocean: {
      name: ocean,
      width: 15,
      height: 20,
    },
    'ocean-active': {
      name: oceanActive,
    },
    tick: {
      name: tick,
      width: 20,
      height: 20,
    },
    'tick-active': {
      name: tickActive,
    },
    map: {
      name: map,
      width: 20.5,
      height: 24,
    },
    'map-active': {
      name: mapActive,
    },
  }

  return (
    <div className='tabs flex' style={{}}>
      {tabs &&
        tabs.map((tab: string, id: number) => {
          return (
            <>
              <div
                className='tab cursor-pointer'
                onClick={() => {
                  setActiveTab(tab)
                  if (tab == 'Completed') {
                    setIsCompleted(true)
                    switchStatus(true)
                  } else if (tab == 'Active') {
                    setIsCompleted(false)
                    switchStatus(false)
                  }
                }}
                key={id}
              >
                <div className='flex items-center mb-3' style={icons && icons.length ? { minWidth: 180 } : {}}>
                  {icons && icons.length ? (
                    <>
                      <img
                        src={activeTab === tab ? iconNames[`${icons[id]}-active`].name : iconNames[icons[id]].name}
                        alt=''
                        width={iconNames[icons[id]].width}
                        height={iconNames[icons[id]].height}
                        className='ml-5 mr-1'
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <p
                    className={`${activeTab === tab ? 'font-base text-[#139C33]' : 'text-[#6B7280]'} text-xs lg:text-sm ${
                      icons && icons.length ? 'mr-5' : 'mx-5'
                    } `}
                  >
                    {tab}
                  </p>
                </div>

                <hr className={`${activeTab === tab ? 'border-t-2 border-green-500 mt-[-3px]' : 'border-t border-gray-300 mt-[-1px]'}`} />
              </div>
            </>
          )
        })}
    </div>
  )
}

export default CustomTabs
