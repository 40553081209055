import { useState, useEffect, createContext, useContext, Dispatch } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import { connect } from 'react-redux'
import { createWorkflow, getSingleWorkflow, editWorkflow, clearWorkflow, getAdmins } from 'store/actions'

import { getJobCombination } from 'helpers/jobCombination'

import { AppRoutes } from 'routes/Approutes'

import uniqid from 'uniqid'

import drag from 'assets/icons/drag.svg'
import add from 'assets/icons/add-workflow.svg'
import caretdown from 'assets/icons/caret-down.svg'
import caretleft from 'assets/icons/arrow-left2.svg'

import Layout from 'components/layout/Layout'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import OutlineButton from 'components/buttons/OutlineButton'
import MilestoneDetails from 'components/workflowConfiguration/MilestoneDetails'
import ConfigureMilestone from 'components/workflowConfiguration/ConfigureMilestone'
import TipsAndSuggestion from 'components/workflowConfiguration/TipsAndSuggestion'
import PageLoading from 'components/partials/pageLoading'

import { SingleWorkflow } from 'types/workflowConfiguration/WorkflowConfiguration'
import { Admin } from 'types/dashboard/Dashboard'
import { getFullPort } from 'helpers'

const _Json = require('sea-ports')

export interface WorkflowConfigurationContextI {
  single_workflow: SingleWorkflow
  selectedMilestoneId: string
  setSelectedMilestoneId: Dispatch<string>
  milestones: StaticMilestoneFormI[]
  setMilestones: Dispatch<StaticMilestoneFormI[]>
  addMilestone: () => void
  removeMilestone: (id: string) => void
  updateMilestone: (id: string, name: string, value: string) => void
  resetMilestone: (id: string) => void
  addMilestoneTask: (id: string) => void
  removeMilestoneTask: (id: string, taskId: string) => void
  updateMilestoneTask: (id: string, taskId: string, name: string, value: string) => void
  resetMilestoneTask: (id: string, taskId: string) => void
}

export interface StaticMilestoneFormI {
  _id?: string
  uid: string
  milestone_number: number | null
  milestone_name: string
  milestone_tasks: MilestoneTaskI[]
}

interface MilestoneTaskI {
  _id?: string
  uid: string
  milestone_task_name: string
  duration: string
  assignee_id: string
  is_file_upload: boolean
  customer_task: boolean
  file_names: string[]
}

const staticMilestoneTasksForm: MilestoneTaskI = {
  uid: '',
  milestone_task_name: '',
  duration: '',
  assignee_id: 'default',
  is_file_upload: false,
  customer_task: false,
  file_names: [],
}

const staticMilestoneForm: StaticMilestoneFormI = {
  uid: '',
  milestone_number: null,
  milestone_name: '',
  milestone_tasks: [{ ...staticMilestoneTasksForm, uid: uniqid() }],
}

const WorkflowConfigurationContext = createContext<WorkflowConfigurationContextI>({
  single_workflow: null as any,
  selectedMilestoneId: '',
  setSelectedMilestoneId: () => {},
  milestones: [],
  setMilestones: () => {},
  addMilestone: () => {},
  removeMilestone: () => {},
  updateMilestone: () => {},
  resetMilestone: () => {},
  addMilestoneTask: () => {},
  removeMilestoneTask: () => {},
  updateMilestoneTask: () => {},
  resetMilestoneTask: () => {},
})

const uid = uniqid()

interface CreateWorkflow {
  loading: boolean
  single_loading: boolean
  edit_loading: boolean
  create_loading: boolean
  single_workflow: SingleWorkflow
  clearWorkflow: () => {
    type: string
    payload: null
  }
  editWorkflow: (
    data: any,
    callback?: any
  ) => {
    type: string
    payload: {
      data: any
      callback?: any
    }
  }
  getSingleWorkflow: (id: string) => {
    type: string
    payload: {
      id: string
    }
  }
  createWorkflow: (
    data: any,
    callback: any
  ) => {
    type: string
    payload: {
      data: any
      callback: any
    }
  }
  loading_admins: boolean
  admins: Admin[]
  getAdmins: () => {
    type: string
  }
}

const CreateWorkflowConfiguration = ({
  createWorkflow,
  loading,
  edit_loading,
  single_loading,
  single_workflow,
  getSingleWorkflow,
  editWorkflow,
  create_loading,
  clearWorkflow,
  loading_admins,
  admins,
  getAdmins,
}: CreateWorkflow) => {
  const location = useLocation()
  const navigate = useNavigate()

  const params = useParams()
  const { id } = params

  const [milestones, setMilestones] = useState<StaticMilestoneFormI[]>([{ ...staticMilestoneForm, uid: uid, milestone_number: null }])

  const [totalMilestone, setTotalMilestone] = useState(0)

  const [isChosen, setIsChosen] = useState<object>({ [uid]: true })

  const [isDraftLoading, setIsDraftLoading] = useState(false)

  useEffect(() => {
    id && getSingleWorkflow(id)
    getAdmins()
  }, [id])

  useEffect(() => {
    if (single_workflow) {
      const new_milestones = [...(single_workflow?.milestones || [])]?.map((milestone) => {
        const updated_milestone: any = { milestone_name: milestone?.milestone_name, uid: milestone?._id, _id: milestone?._id }
        updated_milestone.milestone_tasks = [...milestone?.milestone_tasks]?.map((i) => ({
          uid: i?._id,
          _id: i?._id,
          assignee_id: i?.assignee_details?._id,
          milestone_task_name: i?.milestone_task_name,
          duration: i?.duration,
          is_file_upload: i?.is_file_upload,
          file_names: [...(i?.milestone_task_files || [])]?.map((v) => v?.document_name),
        }))
        return updated_milestone
      })
      if (new_milestones?.length > 0) {
        console.log(JSON.stringify(new_milestones))
        setMilestones(new_milestones)
        setTimeout(() => setSelectedMilestoneId(new_milestones?.[0]?.uid), 200)
      }
    } else {
      setMilestones([{ ...staticMilestoneForm, uid: uid }])
    }
  }, [single_workflow])

  // Calculating the total duration by the highest task duration in a milestone
  useEffect(() => {
    setTotalMilestone(
      milestones
        ?.map((milestone) => milestone?.milestone_tasks?.reduce((max, task) => Math.max(max, parseInt(task?.duration || '0')), 0))
        ?.reduce((prev, curr) => prev + curr, 0)
    )
  }, [milestones])

  console.log('milstone>>>', milestones)

  const [selectedMilestoneId, setSelectedMilestoneId] = useState(uid)

  console.log('selectedId', selectedMilestoneId)

  const addMilestone = () => {
    // const prevMilestoneNumber = (milestones?.[milestones.length - 1]?.milestone_number || 0) + 1
    const newMilestone = { ...staticMilestoneForm, uid: uniqid() }
    setMilestones([...milestones, newMilestone])
  }

  const removeMilestone = (id: string) => {
    // if (milestones?.find((i) => i?.uid === id)?._id) return
    if (milestones?.length < 2) {
      setSelectedMilestoneId(milestones?.[0]?.uid)
      return
    }
    setMilestones((prevDoc) => {
      const curr_idx = prevDoc.findIndex((i) => i?.uid === id)
      setTimeout(() => setSelectedMilestoneId(prevDoc[curr_idx - 1]?.uid))
      return prevDoc.filter((data) => id !== data['uid'])
    })
  }

  const updateMilestone = (id: string, name: string, value: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return { ...data, [name]: value }
      })
    })
  }

  const resetMilestone = (id: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return { ...staticMilestoneForm, uid: id }
      })
    })
  }

  const addMilestoneTask = (id: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return { ...data, milestone_tasks: [...data.milestone_tasks, { ...staticMilestoneTasksForm, uid: uniqid() }] }
      })
    })
  }

  // const removeMilestoneTask = (id: string, taskId: string) => {
  //   setMilestones((prev) => {
  //     return prev.map((data) => {
  //       // if (id !== data['uid']) return data
  //       if (data?.milestone_tasks?.find((i) => i?.uid === taskId)?._id) return data
  //       return {
  //         ...data,
  //         milestone_tasks: data.milestone_tasks.filter((task) => taskId !== task.uid),
  //       }
  //     })
  //   })
  // }

  const removeMilestoneTask = (id: string, taskId: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return {
          ...data,
          milestone_tasks: data.milestone_tasks.filter((task) => taskId !== task.uid),
        }
      })
    })
  }

  const updateMilestoneTask = (id: string, taskId: string, name: string, value: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return {
          ...data,
          milestone_tasks: data.milestone_tasks.map((task) => {
            if (taskId !== task['uid']) return task
            return { ...task, [name]: value }
          }),
        }
      })
    })
  }

  const resetMilestoneTask = (id: string, taskId: string) => {
    setMilestones((prev) => {
      return prev.map((data) => {
        if (id !== data['uid']) return data
        return {
          ...data,
          milestone_tasks: data.milestone_tasks.map((task) => {
            if (taskId !== task['uid']) return task
            return { ...staticMilestoneTasksForm, uid: taskId }
          }),
        }
      })
    })
  }

  const workflowSubmitted = () => {
    // setMilestones([])
    // clearWorkflow()
    navigate(AppRoutes.workflowConfiguration.workflowView)
  }

  const state = location.state

  const submit = (isDraft = false) => {
    isDraft && setIsDraftLoading(true)
    if (!id) {
      const updatedMilestones = milestones.map((milestone, milestoneIndex) => ({
        ...milestone,
        milestone_number: milestoneIndex + 1,
        milestone_tasks: milestone.milestone_tasks.map((task, taskIndex) => ({
          ...task,
          milestone_task_number: taskIndex + 1,
        })),
      }))
      createWorkflow({ ...state.data, is_draft: isDraft, milestones: updatedMilestones }, workflowSubmitted)
    } else {
      const { _id, ...restOfWorkflow } = single_workflow
      const updatedMilestones = milestones.map((milestone, milestoneIndex) => ({
        ...milestone,
        milestone_number: milestoneIndex + 1,
        milestone_tasks: milestone.milestone_tasks.map((task, taskIndex) => ({
          ...task,
          milestone_task_number: taskIndex + 1,
        })),
      }))
      const data = {
        ...restOfWorkflow,
        port_location: single_workflow?.processes[0]?.port_location,
        escalation_contact: single_workflow?.escalation_contact_id,
        ocean_freight: single_workflow?.processes[0]?.ocean_freight,
        customs_brokerage: single_workflow?.processes[0]?.customs_brokerage,
        haulage: single_workflow?.processes[0]?.haulage,
        warehousing: single_workflow?.processes[0]?.warehousing,
        air_freight: single_workflow?.processes[0]?.air_freight,
        tracking: single_workflow?.processes[0]?.tracking,
        process_description: 'Random',
        milestones: updatedMilestones,
        is_draft: isDraft,
      }
      editWorkflow(
        {
          id: id,
          data: data,
          type: 'complete',
        },
        workflowSubmitted
      )
    }
  }

  console.log('milestone>>>', milestones)

  console.log('state>>', state)

  return (
    <WorkflowConfigurationContext.Provider
      value={{
        single_workflow: single_workflow as any,
        selectedMilestoneId,
        setSelectedMilestoneId,
        milestones,
        setMilestones,
        addMilestone,
        removeMilestone,
        updateMilestone,
        resetMilestone,
        addMilestoneTask,
        removeMilestoneTask,
        updateMilestoneTask,
        resetMilestoneTask,
      }}
    >
      {single_loading ? (
        <PageLoading title='workflow details' />
      ) : (
        <>
          <div className='px-4 lg:px-10 py-6 flex items-center justify-between bottom-divider-2'>
            <div className='flex items-center gap-x-3.5'>
              <img src={caretleft} alt='' className='cursor-pointer' onClick={() => navigate(-1)} />
              <div>
                <p className='green-text-4 text-xl font-medium'>{state?.services || getJobCombination(single_workflow?.processes) || 'N/A'}</p>
                <p className='black-text-4 font-light text-sm'>{`${getFullPort(
                  _Json.JSON,
                  state?.data?.port_location || single_workflow?.processes?.[0]?.port_location || 'N/A'
                )} (${state?.data?.department || single_workflow?.department || 'N/A'})`}</p>
              </div>
            </div>
            {/* turn the button to a component for the mobile version */}
            <div className='flex items-center gap-x-4 desktop-only'>
              <OutlineButton
                title='Save as Draft'
                style={{ color: '#296FD8', fontWeight: 500, border: '1px solid #D0F5FF' }}
                onClick={() => submit(true)}
                loading={isDraftLoading && create_loading}
                disabled={false}
                icon=''
              />
              <PrimaryButtons
                title={id ? 'Update Workflow' : 'Publish Workflow'}
                style={{ backgroundColor: '#007003' }}
                disabled={false}
                onClick={() => submit(false)}
                loading={!isDraftLoading && (create_loading || edit_loading)}
              />
            </div>
          </div>
          <div className='px-4 pt-6 lg:px-10 grid grid-cols-10 h-[calc(100vh-121px)] desktop-only'>
            <div className='border-solid border-[1px] border-[#dfe0e2] col-span-3 rounded-l'>
              <ConfigureMilestone totalMilestone={totalMilestone} />
            </div>
            <div className='col-span-4 border-solid border-t-[1px] border-b-[1px] border-[#dfe0e2]'>
              <MilestoneDetails />
            </div>
            <div className='bg-pureple-400 border-[#dfe0e2] border-solid border-[1px] col-span-3 p-4 rounded-r'>
              <TipsAndSuggestion />
            </div>
          </div>

          <div className='px-4 pt-6 lg:px-10 h-[calc(100vh-121px)] mobile-only'>
            <div className='flex justify-between items-center bg-[#F9FAFB] px-4 py-6'>
              <p className='green-text-4 font-medium'>Configuration Milestones({milestones?.length})</p>
              <img src={add} alt='add milestone' className='cursor-pointer' onClick={() => addMilestone()} />
            </div>
            <div className='mt-4 flex flex-col gap-4'>
              {milestones?.map((mile, idx) => {
                return (
                  <div>
                    <div
                      onClick={() => {
                        setSelectedMilestoneId(mile?.uid)
                        setIsChosen((prev) => ({ ...prev, [mile?.uid]: !prev[mile?.uid] }))
                      }}
                      className={`border-solid border-[1px] ${
                        selectedMilestoneId === mile?.uid ? 'border-[#99FF7D] bg-[#139c330d]' : 'border-[#F3F4F6]'
                      } px-2 py-4 rounded flex justify-between items-center cursor-pointer`}
                    >
                      <div className='flex items-center gap-x-3 '>
                        <img src={drag} alt='' />
                        <p className='black-text-3 text-sm'>{`${mile.milestone_name ? mile.milestone_name : `Milestone ${idx + 1}`}`}</p>
                      </div>
                      <img src={caretdown} alt='' />
                      {/* The logic below deletes a workflow (ask design about deleting a milestone for mobile view) */}
                      {/* {idx !== 0 ? selectedMilestoneId === uid ? <img src={deleteIcon} onClick={() => removeMilestone(uid)} alt='delete' /> : <></> : <></>} */}
                    </div>
                    {isChosen[mile?.uid] && (
                      <div>
                        <MilestoneDetails />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
            <div className='flex flex-col gap-y-4 mt-10 pb-4'>
              <OutlineButton
                title='Save as Draft'
                style={{ color: '#296FD8', fontWeight: 500, border: '1px solid #D0F5FF' }}
                onClick={() => submit(true)}
                loading={isDraftLoading && create_loading}
                disabled={false}
                icon=''
              />
              <PrimaryButtons
                title={id ? 'Update Workflow' : 'Publish Workflow'}
                style={{ backgroundColor: '#007003' }}
                disabled={false}
                onClick={() => submit(false)}
                loading={!isDraftLoading && (create_loading || edit_loading)}
              />
            </div>
          </div>
        </>
      )}
    </WorkflowConfigurationContext.Provider>
  )
}

export function useWorkflowConfigurationContext() {
  return useContext(WorkflowConfigurationContext)
}

const mapStateToProps = (state: any) => {
  const { error, loading, single_loading, single_workflow, edit_loading, create_loading } = state.workflows

  const { loading_admins, admins } = state.dashboard

  return { error, loading, single_loading, single_workflow, edit_loading, create_loading, loading_admins, admins }
}

export default connect(mapStateToProps, { createWorkflow, getSingleWorkflow, editWorkflow, clearWorkflow, getAdmins })(CreateWorkflowConfiguration)
