import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'

import stick from 'assets/icons/stick.svg'
import ServiceDocs from 'assets/icons/service-docs.svg'
import plus from 'assets/icons/plus-no-bg.svg'
import minus from 'assets/icons/minus-no-bg.svg'
import container from 'assets/icons/container.svg'
import StatusComp from './StatusComp'

interface Props {
  shipment_report_data: any
}
const ShipmentTimeline = ({ shipment_report_data }: Props) => {
  const [accordion, setAccordion] = useState({})
  const [sortedMilestones, setSortedMilestones] = useState([])
  const [showDocs, setShowDocs] = useState([])
  const [open, setOpen] = useState<boolean>(false)

  const toggle = (index) => {
    const temp = [...showDocs]
    //@ts-ignore
    temp[index] = !temp[index]
    setShowDocs([...temp])
  }
  const toggleAccordion = (id) => {
    setAccordion((prevState) => ({
      ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
      [id]: !prevState[id],
    }))
  }

  // sort milestones
  useEffect(() => {
    setSortedMilestones(shipment_report_data?.milestones?.sort((a, b) => a.milestone_number - b.milestone_number))
  }, [shipment_report_data])
  return (
    <div className='md:col-span-1 lg:col-span-2 bg-[#F9FAFB] p-[24px] '>
      <div className='flex gap-[4px] items-center mb-[16px]'>
        <p className='text-[#374151] uppercase font-medium text-[12px]'>Shipment Timeline</p>
        <div className='w-[1px] h-[11px] bg-[#E5E7EB]'></div>
        <span className=' text-[#6B7280] text-[12px]'>
          <Moment format='DD'>{shipment_report_data?.current_timeline}</Moment> Day(s) Spent
        </span>
      </div>
      <div className='flex flex-col gap-[16px] '>
        {' '}
        {sortedMilestones?.map((item: any) => (
          <div className='' key={item._id}>
            <div
              className={
                open
                  ? 'p-[16px] flex gap-[16px] justify-between bg-white rounded-t-[8px] items-center'
                  : 'p-[16px] flex gap-[16px] justify-between bg-white rounded-[8px] items-center'
              }
            >
              <div className='flex gap-[16px] '>
                <img
                  src={plus}
                  alt=''
                  className='cursor-pointer'
                  onClick={() => {
                    toggleAccordion(item.milestone_id)
                    setOpen(!open)
                  }}
                />

                <p className='text-[#6B7280] font-medium text-xs uppercase'>
                  Milestone : <span className='text-[#374151] uppercase font-medium'>{item?.milestone_name}</span>
                </p>
              </div>

              <p className=' text-[#139C33] text-xs font-medium'>{Math.round(item?.milestone_percentage_completion)}%</p>
            </div>
            {accordion[item.milestone_id] && (
              <div className='px-[16px] bg-[#FFFFFF] pb-[16px] rounded-b-lg flex flex-col gap-[16px]  '>
                {item?.tasks?.map((taskItem: any, idx) => (
                  <div key={taskItem._id} className=''>
                    <div className='  '>
                      <div
                        className={
                          taskItem?.task_status === 'delayed'
                            ? ' bg-[#F9FAFB] rounded-lg flex items-center border border-[#FFBAB3]'
                            : ' bg-[#F9FAFB] rounded-lg flex items-center'
                        }
                      >
                        <div className='py-[12px] px-[27px] '>
                          <p className='text-[36px] font-medium text-[#1F2937] text-center'>
                            <Moment format='DD'>{taskItem?.createdAt}</Moment>
                          </p>
                          <p className='font-medium text-[#4B5563] text-[12px] text-center'>
                            <Moment format='MMMM-YYYY'>{taskItem?.createdAt}</Moment>
                          </p>
                        </div>
                        <div className='px-[10px] '>
                          <img src={stick} width={'0.5rem'} alt='stick' />
                        </div>
                        <div className='px-[16px]  flex flex-col gap-[14px] '>
                          <p className=' text-[#1F2937] capitalize text-sm'>{taskItem?.task_name}</p>
                          <div className='flex  gap-[8px] flex-wrap '>
                            {taskItem?.assignee_details.length > 0 && (
                              <div className='h-[20px] min-w-[20px] w-fit flex  items-center justify-center rounded-full bg-[#D0F5FF] '>
                                <p className='text-[#1F2937] text-[9px]'>
                                  {taskItem?.assignee_details?.[0]?.firstname.charAt(0)}
                                  {taskItem?.assignee_details?.[0]?.lastname.charAt(0)}
                                </p>
                              </div>
                            )}
                            {taskItem.customer_task && (
                              <div
                                className={
                                  'min-w-fit w-fit flex items-center rounded-[23px] justify-center px-[8px] py-[4px] bg-[#F3F4F6] text-[#374151] capitalize  text-[10px] '
                                }
                              >
                                <p className={''}>Customer Task</p>
                              </div>
                            )}
                            <StatusComp taskItem={taskItem} />
                            {taskItem?.is_file_upload === true ? (
                              <>
                                <div
                                  className='cursor-pointer flex items-center gap-[4px] rounded-[23px] bg-[#F3F4F6] px-[8px] py-[4px] min-w-fit'
                                  onClick={() => toggle(idx)}
                                >
                                  <img src={ServiceDocs} width={'14px'} height={'14px'} />
                                  <p className='text-[#6B7280] text-[10px] '> Documents</p>
                                  {showDocs[idx] && (
                                    <div className='absolute z-20 border border-[red]'>
                                      <div className='lg:pt-32'>
                                        <div className='p-1 w-[186px] bg-white shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded-md'>
                                          {taskItem?.task_documents?.map((docItem: any) => {
                                            console.log('taskItem', docItem)
                                            return (
                                              <a
                                                href={docItem?.document_location}
                                                target='_blank'
                                                rel='noreferrer'
                                                className='hover:bg-[#139c330f] items-center gap-x-2 rounded cursor-pointer'
                                              >
                                                <p key={docItem._id} className='text-[#4B5563] text-[12px] p-2'>
                                                  {docItem?.document_name}
                                                </p>
                                              </a>
                                            )
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : null}

                            <div className='flex items-center gap-[4px] rounded-[23px] bg-[#F3F4F6] text-[#6B7280] text-[10px] px-[8px] py-[4px] min-w-fit'>
                              <img src={container} alt='' />

                              <p>
                                {taskItem?.container_details?.[0]?.completed_containers}/{taskItem?.container_details?.[0]?.total_containers}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ShipmentTimeline
