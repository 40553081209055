import { error } from 'console'
import {
  GET_WORKFLOWS,
  CREATE_WORKFLOW,
  GET_SINGLE_WORKFLOW,
  EDIT_WORKFLOW,
  API_ERROR,
  ACTIVATE_WORKFLOW,
  DEACTIVATE_WORKFLOW,
  DELETE_WORKFLOW,
  CLEAR_WORKFLOW,
  GET_WORKFLOWS_CSV,
} from 'store/workflow/constants'

const initialState = {
  error: '',
  loading: true,
  table_loading: false,
  workflows: [],
  workflows_csv: [],
  total_workflows: '',
  single_workflow: null,
  single_loading: false,
  activate_loading: false,
  deactivate_loading: false,
  edit_loading: false,
}

const workflows = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_WORKFLOWS.REQUEST:
      return {
        ...state,
        table_loading: true,
      }

    case GET_WORKFLOWS.SUCCESS:
      return {
        ...state,
        loading: false,
        table_loading: false,
        workflows: action.payload?.workflow,
        total_workflow: action.payload?.total_workflow,
      }

    case GET_WORKFLOWS_CSV.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case GET_WORKFLOWS_CSV.SUCCESS:
      return {
        ...state,
        loading: false,

        workflows_csv: action.payload?.workflow,
      }

    case CREATE_WORKFLOW.REQUEST:
      return {
        ...state,
        create_loading: true,
        error: '',
      }

    case CREATE_WORKFLOW.SUCCESS:
      return {
        ...state,
        create_loading: false,
      }

    case GET_SINGLE_WORKFLOW.REQUEST:
      return {
        ...state,
        single_loading: true,
        error: '',
      }

    case GET_SINGLE_WORKFLOW.SUCCESS:
      return {
        ...state,
        single_loading: false,
        single_workflow: action.payload,
      }

    case ACTIVATE_WORKFLOW.REQUEST:
      return {
        ...state,
        activate_loading: true,
        error: '',
      }

    case ACTIVATE_WORKFLOW.SUCCESS:
      return {
        ...state,
        activate_loading: false,
      }

    case DEACTIVATE_WORKFLOW.REQUEST:
      return {
        ...state,
        deactivate_loading: true,
        error: '',
      }

    case DEACTIVATE_WORKFLOW.SUCCESS:
      return {
        ...state,
        deactivate_loading: false,
      }

    case DELETE_WORKFLOW.REQUEST:
      console.log('delete workflow reducer')
      return {
        ...state,
        loading: true,
        error: '',
      }

    case DELETE_WORKFLOW.SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case EDIT_WORKFLOW.REQUEST:
      return {
        ...state,
        edit_loading: true,
        error: '',
      }

    case EDIT_WORKFLOW.SUCCESS:
      return {
        ...state,
        edit_loading: false,
      }

    case CLEAR_WORKFLOW:
      return { ...state, single_workflow: null }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        edit_loading: false,
        single_loading: false,
        deactivate_loading: false,
        create_loading: false,
      }

    default:
      return state
  }
}

export default workflows
