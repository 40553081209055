export const ORL = '/'

export const AppRoutes = {
  dashboard: 'dashboard',

  signup: 'signup',
  verifyEmail: 'verify-email',
  forgotPassword: 'forgot-password',
  signupSuccess: 'signup-success',
  passwordConfirmation: 'password-confirmation',
  teamVerification: 'team-verification',

  shipments: 'shipments',
  shipmentsDetails: 'shipments/:id',
  invalidShipment: 'invalidshipment',

  termsOfService: 'terms-of-service',
  privacyPolicy: 'privacy-policy',

  freightDetails: 'freight-details',
  freightDetailsEdit: 'freight-details/:id',
  freightRates: 'freight-rates/:id',
  shipmentInformation: 'shipment-information/:id',

  settings: 'settings',
  profileSettings: 'settings/profile',
  editProfileSettings: 'settings/profile-edit',

  container: 'container',
  booking: 'booking',
  bookingDetail: 'booking/:id',

  notFound: 'not-found',

  tasks: 'tasks',

  escalations: 'escalations',

  workflowConfiguration: {
    workflowView: '/workflow-configuration',
    workflowCreate: '/workflow-configuration/create',
    workflowDetail: '/workflow-configuration/details/:id',
    editWorkflow: '/workflow-configuration/create/:id',
  },

  reports: {
    shipments: '/reports/shipments',
    shipmentById: '/reports/shipments/:id',
    tasks: 'reports/tasks',
  },

  overview: {
    tillAccounts: {
      assignTillAccounts: `${ORL}/till-account/assign-till-account`,
    },
  },

  messaging: 'messaging',
}
