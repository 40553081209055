import { FC, useState } from 'react'
import { Controller } from 'react-hook-form'
import PrimaryButton from 'components/buttons/PrimaryButton'
// import OtherocsModal from 'pages/shipment/shipmentDetails/documentTab/documentPartials/otherDocsModal/docsModal'
import exportImg from 'assets/icons/export-upload.svg'

interface Props {
  id: string
  control: object
  name: string
  label: string
  placeholder: string
  defaultValue: string | undefined
  errors: object
  isRequired: boolean
  disabled: boolean
  handleFileChange: (any) => void
}

const ShipmentUploadInput: FC<Props> = ({
  id,
  control,
  name,
  label,
  placeholder,
  defaultValue,
  errors,
  isRequired,
  handleFileChange,
  disabled,
}): JSX.Element => {
  const [filename, setFileName] = useState<any>('')
  const [isOpen, setIsOpen] = useState(false)
  const files = ['PDF', 'PNG', 'JPG', 'XLS', 'XLSX', 'JPEG']
  return (
    <>
      <Controller
        //  @ts-ignore
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={{
          required: isRequired ? true : false,
          validate: {
            lessThan10MB: (files) => files[0]?.size < 10000000 || 'Maximum file size of 10MB',
            acceptedFormats: (files) => ['application/xls'].includes(files[0]?.type) || 'Only XLXs format allowed.',
          },
        }}
        // @ts-ignore
        render={({ field: { onChange, value } }) => {
          return (
            <div>
              <div className=''>
                <div className={` items-center ${disabled && 'opacity-40'}`}>
                  <label htmlFor={id} className='cursor-pointer mb-2'>
                    <span className='self-center'>
                      {/* <div className="">
                                                <button
                                                    type="button"
                                                    className="w-full text-[#139C33] bg-white font-base rounded-sm text-sm px-5 py-3 me-2 mb-2 greener-br rounded-lg">
                                                    Select document(s)
                                                </button>
                                            </div> */}
                      <div className={` items-center mt-2 ${disabled && 'opacity-40'}`}>
                        <label htmlFor={id} className='cursor-pointer'>
                          <div
                            className={`bg-[#F9FAFB] text-sm green-text-2 rounded border-[1px] border-[#37B248] w-full py-3 text-center ${
                              disabled && 'opacity-40 bg-[#F9FAFB]'
                            }`}
                          >
                            {/* <img src={exportImg} alt="" /> */}Select document
                          </div>
                        </label>
                      </div>
                    </span>
                  </label>
                </div>
                <input
                  id={id}
                  type='file'
                  onChange={(val) => {
                    onChange(val.target.files)
                    handleFileChange(val)
                  }}
                  disabled={disabled}
                />
              </div>
              {/* @ts-ignore */}
              {errors[name] && (
                <div className='mt-2 error-text'>
                  {/* @ts-ignore */}
                  {errors[name]['message'] ? (
                    //  @ts-ignore
                    <p>{errors[name]['message']}</p>
                  ) : (
                    <p>{label ? label : name} is required.</p>
                  )}
                </div>
              )}
            </div>
          )
        }}
      />
      {/* <OtherocsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            /> */}
    </>
  )
}
// control={control}
//       defaultValue={defaultValue}
//       name={name}
//       rules={{
//         required: isRequired ? true : false,
//       }}

export default ShipmentUploadInput
