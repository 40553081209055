export const getOpsAdmins = (admins, optionsFormat = false) => {
  // const filteredAdmins = admins.filter((item) => item.department.includes('operations') && item.role !== 'super_admin')
  const filteredAdmins = admins
  if (optionsFormat) {
    return filteredAdmins.map((ops) => ({
      label: `${ops.firstname} ${ops.lastname}`,
      value: `${ops._id}`,
    }))
  }

  return filteredAdmins.map((ops) => ({
    firstname: ops.firstname,
    lastname: ops.lastname,
    id: ops._id,
  }))
}
