import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'

//libraries
import { connect } from 'react-redux'

import { DashboardProps, Admin } from 'types/dashboard/Dashboard'

//components
import Layout from 'components/layout/Layout'
import OpsMember from 'components/dashboard/OpsMember'
import TasksCard, { TasksAction } from 'components/dashboard/TasksCard'
import { TasksCategory, TasksDuration } from 'components/dashboard/TasksCard'
import { OpsGrade, OpsScore } from 'components/dashboard/OpsRanking'

//icons
import delayed from 'assets/icons/delayed.svg'
import assigned from 'assets/icons/assigned.svg'

//redux
import { getDashboardParams, getAdmins, setCountryCodeSuccess } from 'store/actions'
import { AppRoutes } from 'routes/Approutes'
import CBTNotification from 'components/rate/exportOcean/CBTNotification'
import { countryMap } from 'helpers/countryMapping'

const time_map = {
  'all time': '',
  today: 'today',
  'this week': 'this_week',
  'this month': 'this_month',
  'this year': 'this_year',
}

const Dashboards = ({ getDashboardParams, loading, dashboard_params, loading_admins, admins, getAdmins, country_code }: DashboardProps) => {
  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  const [selectedOpsMember, setSelectedOpsMember] = useState<{ firstname: string; lastname: string; id: string }>({
    firstname: 'everyone',
    lastname: '',
    id: '',
  })
  const [period, setPeriod] = useState<string>('all time')

  const getOpsAdmins = useCallback(() => {
    if (admin_data?.role === 'super_admin' && selectedOpsMember?.id === '') {
      getDashboardParams('', time_map[period], false, countryMap[country_code])
    } else if (admin_data?.role === 'super_admin' && selectedOpsMember?.id !== '') {
      getDashboardParams(selectedOpsMember?.id, time_map[period], true, countryMap[country_code])
    } else {
      getDashboardParams(admin_data?._id, time_map[period], true, countryMap[country_code])
    }
  }, [admin_data?._id, selectedOpsMember?.id, admin_data?.role, period, country_code])

  useEffect(() => {
    getOpsAdmins()
  }, [getOpsAdmins])

  useEffect(() => {
    if (admin_data?.role === 'super_admin') {
      getAdmins()
    }
  }, [admin_data?.role])

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        <h1 className='black-text-4 text-2xl font-medium'>Dashboard</h1>
        <OpsMember
          admins={admins}
          selectedOpsMember={selectedOpsMember}
          setSelectedOpsMember={setSelectedOpsMember}
          period={period}
          setPeriod={setPeriod}
          totalTask={dashboard_params?.task?.total_task}
        />
        {dashboard_params?.task?.total_task === 0 && (
          <CBTNotification
            text='No tasks  or shipments have been assigned to this user for this time period'
            editInfo={true}
            style={{ marginBottom: '24px' }}
            width={'80%'}
          />
        )}
        <div className='grid gap-x-6 gap-y-6 md:gap-y-7 md:grid-cols-2 lg:grid-cols-3'>
          <TasksCard
            maintext={
              <TasksCategory
                icon={assigned}
                text={admin_data?.role === 'super_admin' && selectedOpsMember.firstname === 'everyone' ? 'Total Assigned Tasks' : 'Assigned Tasks'}
                isView={false}
                linkTo='/tasks'
                loading={loading}
              />
            }
            subtext={<TasksDuration loading={loading} duration={dashboard_params?.task?.total_task} />}
            action={
              <TasksAction
                text='View Total Assigned Tasks'
                bgColor='#059c011a'
                color='#007003'
                loading={loading}
                linkTo='/tasks'
                shipmentType='completed'
              />
            }
          />
          <TasksCard
            maintext={
              <TasksCategory
                icon={delayed}
                text={admin_data?.role === 'super_admin' && selectedOpsMember.firstname === 'everyone' ? 'Total Delayed Tasks' : 'Delayed Tasks'}
                linkTo='/tasks'
                tooltip='Delays comprising of delays from both OnePort 365 and Customer'
              />
            }
            subtext={<TasksDuration loading={loading} duration={dashboard_params?.task?.delayed_task} />}
            action={
              <TasksAction text='View all Delayed Tasks' bgColor='#FFE4E1' color='#AD0013' loading={loading} linkTo='/tasks' shipmentType='active' />
            }
          />
          {/* </div> */}

          {/* <TasksCard
            maintext={
              <OpsGrade
                selectedOpsMember={selectedOpsMember}
                admin_role={admin_data?.role}
                score={Math.round(dashboard_params?.task?.ranking)}
                loading={loading}
                totalTask={dashboard_params?.task?.total_task}
              />
            }
            subtext={
              <OpsScore score={Math.round(dashboard_params?.task?.ranking)} loading={loading} totalTask={dashboard_params?.task?.total_task} />
            }
            isRanking={true}
            class_names='order-[-1] md:order-none'
          /> */}

          <TasksCard
            maintext={<TasksCategory text='Total Active Shipments' />}
            subtext={<TasksDuration loading={loading} duration={dashboard_params?.shipments?.active_shipments} />}
            action={
              <TasksAction
                text='View all active shipments'
                bgColor='#d0f5ff4d'
                color='#4B83F0'
                loading={loading}
                linkTo={AppRoutes.reports.shipments}
                shipmentType='active'
              />
            }
          />
          <TasksCard
            maintext={<TasksCategory text='Total Completed Shipments' />}
            subtext={<TasksDuration loading={loading} duration={dashboard_params?.shipments?.completed_shipments} />}
            action={
              <TasksAction
                text='View all completed shipments'
                bgColor='#059c011a'
                color='#007003'
                loading={loading}
                linkTo={AppRoutes.reports.shipments}
                shipmentType='completed'
              />
            }
          />
          <TasksCard
            maintext={<TasksCategory text='Total Assigned Shipments' isRight={true} />}
            subtext={<TasksDuration loading={loading} duration={dashboard_params?.shipments?.total_shipments} />}
            action={
              <div className='mt-9'>
                <p className='mb-1 black-text-3 text-sm'>Shipment completion level</p>
                <div className='relative'>
                  <div className='bg-[#E6F5E6] h-5 rounded-lg' />
                  <div
                    style={{ width: `${Math.round(dashboard_params?.shipments?.percentage_completion)}%` }}
                    className='absolute top-0 rounded-lg h-5 bg-[#3AB44A] text-white text-xs py-[2px] pl-2.5'
                  >{`${Math.round(dashboard_params?.shipments?.percentage_completion)}%`}</div>
                </div>
              </div>
            }
            loading={loading}
          />
        </div>
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading, dashboard_params, loading_admins, admins } = state.dashboard
  const { country_code } = state.additionalDetails

  return { error, loading, dashboard_params, loading_admins, admins, country_code }
}

export default connect(mapStateToProps, { getDashboardParams, getAdmins })(Dashboards)
