import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'

import PrimaryButtons from 'components/buttons/PrimaryButtons'
import CustomOtpInput from 'components/textInputs/CustomOtpInput'

import { clearErrors, resendVerification, validateRegistration } from 'store/actions'
import { getRedirect } from 'helpers'


const EmailVerification = (props: any): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const { clearErrors, error, loading, otp_data_new, validateRegistration, resendVerification } = props
  const [counter, setCounter] = useState(10)

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    if (counter > 0) timer = setInterval(() => setCounter((prevCounter) => prevCounter - 1), 1000)

    return () => {
      if (timer) clearInterval(timer)
    }
  }, [counter])

  useEffect(() => {
    window.scrollTo(0, 0)
    clearErrors()
  }, [])

  const location = useLocation()
  const redirect = getRedirect(location, 'dashboard')

  const urlParams = new URLSearchParams(location.search)
  const token = urlParams.get('token') ?? ' '

  const [otp_data] = useState({
    verify_token: decodeURIComponent(token.replace(/ /g, '+')),
    email: urlParams.get('email'),
    otp_code: 0,
  })

  const onSubmit = (data: any) => {
    otp_data.otp_code = parseInt(data.otp_code)
    otp_data.verify_token = otp_data_new.verify_token ? otp_data_new.verify_token : otp_data.verify_token
    validateRegistration(otp_data, redirect)
  }

  const resendOtp = () => {
    setCounter(10)
    resendVerification({ email: otp_data.email, verify_token: otp_data.verify_token }, `/verify-email?redirect=${redirect}`)
  }

  return (
    <>
      <div className='max-h-screen h-screen grid grid-cols-1 lg:grid-cols-2'>
        <div className='pt-7 min-h-screen overflow-auto login-screen'>
          <div className='ml-6 sm:ml-20 sm:mr-[100px]'>
            <Link to='/'>
              <img src='https://ik.imagekit.io/oneport365/images/oneport-logo.svg' alt='' />
            </Link>
          </div>
          <div className='flex flex-col justify-center h-[calc(100vh_-_62px)] items-center'>
            <div className='sm:max-w-[450px] w-full p-6 sm:p-0 sm:w-[450px] mx-auto'>
              <p className='text-xl black-text-3 mb-1 flex justify-center'> Two Factor Verification</p>
              <p className='text-sm text-[#6B7280] font-light flex justify-center'>Enter The OTP sent to your email below</p>
              <div className='mt-10'>
                {error ? <p className='bg-error p-3 text-center text-red-600 font-500 text-sm my-4 font-light rounded'>{error}</p> : <></>}
              </div>
              <div className='mt-10'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='flex justify-center'>
                    <CustomOtpInput
                      control={control}
                      label={`Enter the 6 Digit Token sent to your mail`}
                      name='otp_code'
                      isRequired={true}
                      errors={errors}
                    />
                  </div>
                  <div className='flex justify-center my-10'>
                    <p className=' text-center text-sm black-text'>Didn't get the code?{'  '}</p>
                    <p
                      className={`text-sm green-text cursor-pointer ${counter && 'opacity-50'} ml-1`}
                      onClick={() => {
                        counter === 0 && resendOtp()
                      }}
                    >
                      {' '}
                      resend otp {counter === 0 ? ' ' : `in ${counter} seconds`}
                    </p>
                  </div>
                  <div className='mt-5'>
                    <PrimaryButtons title='Verify OTP' loading={loading} style={{}} icon='' disabled={loading} onClick={() => {}} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='hidden my-5 mx-5 rounded-[10px] signin-bg lg:flex flex-col justify-end'>
          <div className='px-12 xl:px-16 mb-[123px]'>
            <p className='text-[#F9FAFB] font-normal max-w-[582px] text-2xl leading-[39px] xl:leading-[48px] lg:text-[25px] xl:text-[30px] pb-6 border-b-solid border-b-[#6b7280] border-b-[1px] sato'>
              "Oneport365 operations: Streamlined operations, professional support, and seamless communication ensure efficiency at every step of the
              shipment process."
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading, otp_data, otp_data_new } = state.auth
  return { error, loading, otp_data, otp_data_new }
}

export default connect(mapStateToProps, { clearErrors, validateRegistration, resendVerification })(EmailVerification)
