import OpsMember from 'components/dashboard/OpsMember'
import OpsMemberTask from 'components/dashboard/OpsMemberTask'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDashboardParams, getAdmins, getAllWorkflowTasks, getAllTasksCsv } from 'store/actions'
import Layout from 'components/layout/Layout'
import { FilterObjectType } from 'types/tasks/Tasks'
import { fetchTableData, handleFilter } from 'helpers/tables/fetchTableData'
import TaskTable from 'components/tasks/TaskTable'
import CustomTabsTwo from 'components/customTabs/CustomTabsTwo'
import SearchComp from 'components/search/SearchComp'
import LoadingSpinner from 'components/partials/LoadingSpinner'
import PageLoading from 'components/partials/pageLoading'
import { TaskSkeleton } from 'components/dashboard/TasksCard'
import { useSelector } from 'react-redux'
import { Admin } from 'types/dashboard/Dashboard'
import { gradeDetails } from 'components/dashboard/OpsRanking'
import { countryMap } from 'helpers/countryMapping'
import ExportCsv from 'components/buttons/ExportCsv'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'

const SuperAdminTableView = (props: any) => {
  const {
    admins,
    getAdmins,
    getDashboardParams,
    dashboard_params,
    workflow_tasks,
    total_workflow_task,
    getAllWorkflowTasks,
    table_loading,
    loading,
    loading_dashboard_analytics,
    country_code,
    getAllTasksCsv,
    tasks_csv,
  } = props

  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  const [selectedOpsMember, setSelectedOpsMember] = useState<{ firstname: string; lastname: string; id: string }>({
    firstname: 'everyone',
    lastname: '',
    id: '',
  })

  const [status, setStatus] = useState<string>('')
  const [filterObject, setFilterObject] = useState<FilterObjectType>({
    page: '0',
    count: '10',
    status: 'on-track',
    country: countryMap[country_code],
  })
  const [pageLoading, setPageLoading] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const [tab, setTab] = useState('On-track')
  const tab_result = ['On-track', 'Incoming', 'Delayed', 'Completed', 'Blocked']
  const [csvData, setCsvData] = useState<any>([
    {
      status: '',
      name: '',

      shipment_details: {
        with_haulage: false,
        with_tracker: false,
        customs_brokerage: false,
        ocean_freight: false,
        air_freight: false,
        warehousing: false,

        shipment_type: '',
        shipment_transport_type: '',
      },
      milestone_details: {
        milestone_name: '',
      },
      containers: [
        {
          container_size: '',
        },
      ],
      containerCount: 0,
      company_details: [
        {
          company_name: '',
        },
      ],
      assignee_details: {
        firstname: '',
        lastname: '',
      },
      current_timeline: '',
      completion_timeline: '',
      actual_timeline: '',
    },
  ])
  useEffect(() => {
    getAllTasksCsv('')
  }, [])

  useEffect(() => {
    setCsvData(tasks_csv)
  }, [tasks_csv])

  const csvBookingData = csvData?.reverse().map((item) => ({
    'Task Name': item?.name,
    'Task Assignee': `${item?.assignee_details?.firstname} ${item?.assignee_details?.firstname}`,
    'Service Type': getMultipleServiceCombinations(item?.shipment_details),
    'Shipment Type': item?.shipment_details?.shipment_type,
    'Company Name': item?.company_details?.map((data) => data?.company_name),
    'Container Size': item?.containers?.map((data) => `${data?.container_size}`),
    'Current Timeline': item?.current_timeline,
    'Completion Timeline': item?.completion_timeline,
    'Actual Timeline': item?.expected_timeline,
  }))

  // get admins and dashboard params
  useEffect(() => {
    getAdmins()
  }, [])

  useEffect(() => {
    if (admin_data?.role === 'super_admin' && selectedOpsMember?.id === '') {
      getDashboardParams('', '', false, countryMap[country_code])
    } else if (admin_data?.role === 'super_admin' && selectedOpsMember?.id !== '') {
      getDashboardParams(selectedOpsMember?.id, '', true, countryMap[country_code])
    } else {
      getDashboardParams(admin_data?._id, '', true, countryMap[country_code])
    }
  }, [admin_data._id, selectedOpsMember?.id, admin_data?.role, country_code])

  // changing of tabs
  useEffect(() => {
    setFilterObject({
      ...filterObject,
      status:
        tab === 'On-track'
          ? 'on-track'
          : tab === 'Incoming'
          ? 'incoming'
          : tab === 'Delayed'
          ? 'delayed'
          : tab === 'Blocked'
          ? 'blocked'
          : 'completed',
    })
  }, [tab])

  // fetching table data
  useEffect(() => {
    fetchTableData(filterObject, setPageLoading, getAllWorkflowTasks)
  }, [filterObject, loading])

  // useEffect to do filters once the start date and end date change
  useEffect(() => {
    handleFilter(
      {
        start_date: startDate,
        end_date: endDate,
      },
      setFilterObject,
      filterObject
    )
  }, [dateRange])

  // useeffect to filter assignee id
  useEffect(() => {
    handleFilter(
      {
        assignee_id: selectedOpsMember.id,
      },
      setFilterObject,
      filterObject
    )
  }, [selectedOpsMember])

  // useeffect for country filter
  useEffect(() => {
    handleFilter(
      {
        country: countryMap[country_code],
      },
      setFilterObject,
      filterObject
    )
  }, [country_code])

  const { grade, textColor } = gradeDetails.find(({ minScore }) => dashboard_params?.task?.ranking >= minScore) || {}

  // const opsMemberScore = Math.round(dashboard_params?.task?.ranking)
  const TOTAL_POINTS = '100%'

  return (
    <Layout>
      <main className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        <div className=''>
          {loading ? (
            <PageLoading title='tasks' />
          ) : (
            <>
              <div className='flex sm:items-center  gap-[20px] flex-col sm:flex-row'>
                <h1 className='black-text-4 text-2xl font-medium  mt-[-15px]'>All Tasks</h1>
                <div className='flex items-center gap-3'>
                  <div>
                    <OpsMemberTask
                      admins={admins}
                      selectedOpsMember={selectedOpsMember}
                      setSelectedOpsMember={setSelectedOpsMember}
                      status={status}
                      setStatus={setStatus}
                      removeDateSection={true}
                    />
                  </div>
                  {/* {loading_dashboard_analytics ? (
                    <div className='mb-4'>
                      <TaskSkeleton />
                    </div>
                  ) : (
                    <div className='bg-[#F9FAFB] mb-4 flex gap-[2px] p-3 rounded-[32px] text-xs '>
                      <span className={`${textColor} font-semibold`}>{grade}+</span>
                      <span className='text-[#4B5563]'>{`(${Math.round(dashboard_params?.task?.ranking)}/${TOTAL_POINTS})`}</span>
                    </div>
                  )} */}
                </div>
              </div>

              <div className='relative'>
                {table_loading && (
                  <div className='absolute h-full w-full bg-[#7c8d788c] z-[999999] flex justify-center'>
                    <div className='pt-5'>
                      <LoadingSpinner top={false} height='10' loadingText='Loading table data' />
                    </div>
                  </div>
                )}
                <div>
                  {/* @ts-ignore */}
                  <CustomTabsTwo tabs={tab_result} activeTab={tab} setActiveTab={setTab} />
                  {/* search and date filter */}
                  <div className='flex flex-col md:flex-row md:items-center gap-[32px]  mb-[32px] justify-between'>
                    <SearchComp setFilterObject={setFilterObject} filterObject={filterObject} />
                    <ExportCsv csvBookingData={csvBookingData ? csvBookingData : csvData} name='all_tasks' />
                    {/* <DateFilter startDate={startDate} endDate={endDate} setDateRange={setDateRange} /> */}
                  </div>
                  <TaskTable
                    filteredTableData={workflow_tasks}
                    tasks_csv={tasks_csv}
                    total_data={total_workflow_task}
                    setFilterObject={setFilterObject}
                    filterObject={filterObject}
                    tab={tab}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { error, dashboard_params, loading_dashboard_analytics, loading_admins, admins } = state.dashboard
  const { country_code } = state.additionalDetails
  const { workflow_tasks, total_workflow_task, table_loading, loading, tasks_csv } = state.tasks
  return {
    error,
    loading,
    dashboard_params,
    loading_dashboard_analytics,
    loading_admins,
    admins,
    workflow_tasks,
    total_workflow_task,
    table_loading,
    country_code,
    tasks_csv,
  }
}

export default connect(mapStateToProps, { getDashboardParams, getAdmins, getAllWorkflowTasks, getAllTasksCsv })(SuperAdminTableView)
