import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Layout from 'components/layout/Layout'

import { Milestone, portsObject } from 'types/workflowConfiguration/WorkflowConfiguration'

import { getJobCombination } from 'helpers/jobCombination'

import { connect } from 'react-redux'

import { SingleWorkflow, MilestoneTask } from 'types/workflowConfiguration/WorkflowConfiguration'

import { portMapping, departmentMapping } from 'types/workflowConfiguration/WorkflowConfiguration'

import PageLoading from 'components/partials/pageLoading'
import OutlineButton from 'components/buttons/OutlineButton'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import DeactivateModal from 'components/workflowConfiguration/DeactivateModal'
import WorkflowConfigurationModal from 'components/workflowConfiguration/WorkflowConfigurationModal'

import caret from 'assets/icons/blackcaret-down.svg'
import pencil from 'assets/icons/pencil.svg'
import caretleft from 'assets/icons/arrow-left2.svg'
import deleteIcon from 'assets/icons/delete.svg'

import { clearWorkflow, activateWorkflow, getSingleWorkflow, deleteWorkflow } from 'store/actions'
import moment from 'moment'
import { getFullPort } from 'helpers'
import { AppRoutes } from 'routes/Approutes'
import { Admin } from 'types/dashboard/Dashboard'
import { useSelector } from 'react-redux'

const _Json = require('sea-ports')

interface Props {
  single_loading: boolean
  single_workflow: SingleWorkflow
  getSingleWorkflow: (id: string) => {
    type: string
    payload: {
      id: string
    }
  }
  clearWorkflow: () => {
    type: string
    payload: null
  }
  activateWorkflow: (
    id: string,
    callback?: () => void
  ) => {
    type: string
    payload: {
      id: string
      callback: (() => void) | undefined
    }
  }
  deleteWorkflow: (
    id: string,
    callback
  ) => {
    type: string
    payload: {
      id: string
      callback: () => void
    }
  }
}

const MilestoneItem = ({ milestone, idx }: { milestone: Milestone; idx: number }) => {
  const [isChosen, setIsChosen] = useState<object>({})
  return (
    <div onClick={() => setIsChosen((prev) => ({ ...prev, [milestone._id]: !prev[milestone._id] }))}>
      <div className='flex '>
        <p className='py-6 px-5 bg-[#90e67a1a]'>{idx + 1}</p>
        <div className='flex-1 flex items-center justify-between rounded-r-lg bg-white py-6 pl-3 pr-6 cursor-pointer'>
          <p className='black-text-3'>
            {milestone?.milestone_name} <span className='grey-text'>({milestone?.milestone_tasks?.length} Tasks)</span>
          </p>
          <img src={caret} alt='caret' />
        </div>
      </div>
      {isChosen[milestone?._id] && (
        <div className='bg-white px-4 md:px-12 py-6 border-t-[1px] border-solid border-[#f3f4f6] rounded-b-lg flex flex-col gap-y-5'>
          {milestone?.milestone_tasks?.map((data, idx) => (
            <TaskGroup data={data} idx={idx} />
          ))}
        </div>
      )}
    </div>
  )
}

const TaskGroup = ({ data, idx }: { data: MilestoneTask; idx: number }) => (
  <div className='grid grid-cols-1 lg:grid-cols-3 gap-y-6 gap-x-5 breakdown-border rounded-lg p-4'>
    <TaskItem heading='Task Name' text={data?.milestone_task_name} />
    <TaskItem
      heading='Assignee'
      text={`${data?.assignee_details[0]?.firstname ? data?.assignee_details[0]?.firstname : 'N/A'} ${
        data?.assignee_details[0]?.lastname ? data?.assignee_details[0]?.lastname : 'N/A'
      }`}
    />
    <TaskItem heading='Task Duration' text={`${data?.duration} Days`} isBordered={false} />
    <TaskItem
      heading='Documents Required'
      text={data?.milestone_task_files?.length > 0 ? data?.milestone_task_files?.map((i) => i?.document_name).join(', ') : 'N/A'}
      isCapitalize={false}
    />
  </div>
)

const TaskItem = ({
  heading,
  text,
  isBordered = true,
  isCapitalize = true,
}: {
  heading: string
  text: string
  isBordered?: boolean
  isCapitalize?: boolean
}) => (
  <div className={`${isBordered ? 'border-none md:border-solid border-r-[#F3F4F6] border-r-[1px]' : 'border-none'}`}>
    <p className='grey-text-1 font-light text-xs mb-2'>{heading}</p>
    <p className={`black-text-3 font-light text-sm break-words ${isCapitalize ? 'capitalize' : ''} `}>{text}</p>
  </div>
)

const WorkflowDetailItem = ({ text, subtext }: { text: string; subtext: string }) => (
  <div className='flex items-center gap-x-5'>
    <p className='grey-text-1 font-light text-sm w-[131px] min-w-[131px]'>{text}</p>
    <p className='black-text-3 text-sm capitalize'>{subtext}</p>
  </div>
)

const ViewWorkflowDetails = ({ single_loading, single_workflow, getSingleWorkflow, activateWorkflow, deleteWorkflow, clearWorkflow }: Props) => {
  const params = useParams()
  const { id } = params

  const navigate = useNavigate()

  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  const onActivated = () => navigate(AppRoutes.workflowConfiguration.workflowView)

  const onDeleted = () => navigate(AppRoutes.workflowConfiguration.workflowView)

  const handleActivate = (id: string) => {
    activateWorkflow(id, onActivated)
  }

  const handleDeleteWorkflow = (id: string) => {
    deleteWorkflow(id, onDeleted)
  }

  const [showDeactivate, setShowDeactivate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)

  const closeEditModal = () => {
    setShowEdit(false)
  }

  const closeDeactivateModal = () => {
    setShowDeactivate(false)
  }

  useEffect(() => {
    id && getSingleWorkflow(id)
  }, [])

  return (
    <Layout>
      {single_loading ? (
        <PageLoading title='workflow details' />
      ) : (
        <>
          <div className='px-4 lg:px-10 py-6 flex flex-col md:flex-row md:items-center gap-y-7 md:gap-y-0 justify-between bottom-divider-2 '>
            <div className='flex items-center gap-x-3.5'>
              <img src={caretleft} className='cursor-pointer' onClick={() => navigate(-1)} alt='' />
              <div>
                <p className='grey-text font-light text-sm capitalize'>{single_workflow?.department ? single_workflow?.department : 'N/A'}</p>
                <p className='black-text-4 text-xl font-medium'>{getJobCombination(single_workflow?.processes)}</p>
              </div>
            </div>
            {admin_data?.role === 'super_admin' && (
              <div className='flex items-center self-end md:self-auto gap-x-4 '>
                {single_workflow?.is_draft && (
                  <button onClick={() => handleDeleteWorkflow(single_workflow?._id)} className='border p-3 rounded-[4px] border-[#FFBAB3]'>
                    <img src={deleteIcon} alt='delete' />
                  </button>
                )}
                {single_workflow?.is_active ? (
                  <OutlineButton
                    title='Deactivate'
                    style={{ color: '#AD0013', fontWeight: 400, backgroundColor: '#F9FAFB', border: '' }}
                    onClick={() => setShowDeactivate(true)}
                    loading={false}
                    disabled={false}
                    icon=''
                  />
                ) : (
                  <OutlineButton
                    title='Activate'
                    style={{ color: '#007200', fontWeight: 400, backgroundColor: '#F9FAFB', border: '' }}
                    onClick={() => handleActivate(single_workflow?._id)}
                    loading={false}
                    disabled={false}
                    icon=''
                  />
                )}
                <PrimaryButtons
                  title='Edit Workflow'
                  style={{ backgroundColor: '#374151' }}
                  disabled={false}
                  onClick={() => navigate(`/workflow-configuration/create/${single_workflow?._id}`)}
                />
              </div>
            )}
          </div>
          <div className='grid grid-cols-1 md:grid-cols-5 bg-[#f9fafb] px-4 lg:px-10 py-8 md:h-full gap-6 '>
            <div className='md:col-span-3'>
              <p className='black-text-3 font-medium px-6 pt-6 pb-4 bg-white rounded-lg'>
                Milestones <span className='grey-text'>({single_workflow?.milestones?.length})</span>
              </p>
              <div className='mt-6 flex flex-col gap-6 '>
                {single_workflow?.milestones?.map((milestone, idx) => (
                  <MilestoneItem key={milestone?._id} idx={idx} milestone={milestone} />
                ))}
              </div>
            </div>
            <div className='md:col-span-2'>
              <div className='bg-white rounded-lg'>
                <div className='flex items-center justify-between px-6 pt-6 pb-4 bottom-divider'>
                  <p className='black-text-3 font-medium'>Workflow Details</p>
                  {admin_data.role === 'super_admin' && (
                    <div className='flex items-center gap-x-2' onClick={() => setShowEdit(true)}>
                      <img src={pencil} alt='pencil' />
                      <p className='text-sm text-[#139C33] font-light cursor-pointer'>Edit details</p>
                    </div>
                  )}
                </div>
                <div className='p-6 flex flex-col gap-6'>
                  <WorkflowDetailItem text={'Workflow name:'} subtext={single_workflow?.workflow_name} />
                  <WorkflowDetailItem text={'Port Location:'} subtext={getFullPort(_Json.JSON, single_workflow?.processes?.[0]?.port_location)} />
                  <WorkflowDetailItem text={'Date created:'} subtext={moment(single_workflow?.createdAt).format('ddd, Do MMMM YYYY')} />
                  <WorkflowDetailItem text={'Job Combination:'} subtext={getJobCombination(single_workflow?.processes)} />
                  <WorkflowDetailItem text={'Shipment Type:'} subtext={single_workflow?.shipment_type} />
                  <WorkflowDetailItem
                    text={'Escalation Contact:'}
                    subtext={`${single_workflow?.escalation_contact?.firstname} ${single_workflow?.escalation_contact?.lastname}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <WorkflowConfigurationModal
        isOpen={showEdit}
        closeModal={closeEditModal}
        singleWorkflow={single_workflow}
        isNotEditableConfigured={true}
        id={id}
      />
      <DeactivateModal modalIsOpen={showDeactivate} closeModal={closeDeactivateModal} id={single_workflow?._id} />
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { single_loading, error, single_workflow } = state.workflows
  return { single_loading, error, single_workflow }
}

export default connect(mapStateToProps, { getSingleWorkflow, activateWorkflow, deleteWorkflow, clearWorkflow })(ViewWorkflowDetails)
