import { GET_DASHBOARD_PARAMS, GET_ADMINS, GET_PENDING_DASHBOARD_QUOTES, API_ERROR } from 'store/dashboard/constants'

export const getDashboardParams = (ops_id: string, period: string, isAdmin: boolean, country: string) => {
  return {
    type: GET_DASHBOARD_PARAMS.REQUEST,
    payload: { ops_id, period, isAdmin, country },
  }
}

export const getDashboardParamsSuccess = (response: any) => {
  return {
    type: GET_DASHBOARD_PARAMS.SUCCESS,
    payload: response,
  }
}

export const getAdmins = () => {
  return {
    type: GET_ADMINS.REQUEST,
  }
}

export const getAdminsSuccess = (response: any) => {
  return {
    type: GET_ADMINS.SUCCESS,
    payload: response,
  }
}

export const getPendingDashboardQuotes = (data) => {
  return {
    type: GET_PENDING_DASHBOARD_QUOTES.REQUEST,
    payload: { data },
  }
}

export const getPendingDashboardQuotesSuccess = (response: any) => {
  return {
    type: GET_PENDING_DASHBOARD_QUOTES.SUCCESS,
    payload: response,
  }
}

export const dashboardApiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
