import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

//services
import shipmentServices from 'api/services/shipment'

//helpers
import { errorHandler } from 'helpers/errorHandler'

//redux
import {
  GET_SHIPMENTS,
  GET_SHIPMENTS_BY_ID,
  GET_PUBLIC_SHIPMENT_BY_ID,
  GET_CONTAINER_BY_ID,
  GET_CONTAINERS,
  GET_CONTAINER_BY_JOBNUMBER,
  GET_SHIPMENT_REPORT,
  GET_SHIPMENT_REPORT_BY_ID,
  GET_TASK_REPORT,
  GET_TASK_REPORT_BY_ID,
  UPDATE_SHIPMENT_STATUS,
  GET_SHIPMENT_REPORT_CSV,
  GET_TASK_REPORT_CSV,
} from './constants'

import { ResponseGenerator } from 'store/type'
import {
  getShipmentsSuccess,
  getShipmentByIdSuccess,
  getPublicShipmentByIdSuccess,
  getContainersSuccess,
  getContainerByIdSuccess,
  getContainerByJobNumberSuccess,
  // uploadShipmentDocSuccess,
  getShipmentReportSuccess,
  getShipmentReportByIdSuccess,
  getTaskReportSuccess,
  getTaskReportByIdSuccess,
  updateShipmentStatusSuccess,
  shipmentApiError,
  getShipmentReportCsvSuccess,
  getTaskReportCsvSuccess,
} from 'store/actions'

function* getShipments({ payload }: any) {
  const { data } = payload
  try {
    const response: ResponseGenerator = yield call(shipmentServices.getShipments, data)

    if (response.data) {
      yield put(getShipmentsSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* getContainers({ payload }: any) {
  const { data } = payload
  try {
    const response: ResponseGenerator = yield call(shipmentServices.getContainers, data)

    if (response.data) {
      yield put(getContainersSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* getContainerById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getContainerById, id)

    if (response.data) {
      yield put(getContainerByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}
// function* sendUploadShipmentDoc({ payload }: any) {
//   const { data } = payload;
//   try {
//     const response: ResponseGenerator = yield call(
//       shipmentServices.uploadShipmentDoc,
//       data
//     );

//     if (response.data) {
//       yield put(uploadShipmentDocSuccess(response.data));
//     }
//   } catch (error) {
//     const message = errorHandler(error);
//     yield put(shipmentApiError(message));
//   }
// }

function* getShipmentsById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getShipmentById, id)

    if (response.data) {
      yield put(getShipmentByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* getPublicShipmentById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getPublicShipmentById, id)

    if (response.data) {
      yield put(getPublicShipmentByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* getContainerByJobNumber({ payload }: any) {
  try {
    const { data } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getContainerByJobNumber, data)

    if (response.data) {
      yield put(getContainerByJobNumberSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* doGetShipmentReport({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(shipmentServices.getShipmentReport, data)
    yield put(getShipmentReportSuccess(response.data.data))
  } catch (error) {
    yield put(shipmentApiError(error))
  }
}

function* doGetShipmentReportCsv({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(shipmentServices.getShipmentReportCsv, data)
    yield put(getShipmentReportCsvSuccess(response.data.data))
  } catch (error) {
    yield put(shipmentApiError(error))
  }
}

function* getShipmentReportById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getShipmentReportById, id)

    if (response.data) {
      yield put(getShipmentReportByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* doGetTaskReport({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(shipmentServices.getTaskReport, data)
    yield put(getTaskReportSuccess(response.data.data))
  } catch (error) {
    yield put(shipmentApiError(error))
  }
}

function* doGetTaskReportCsv({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(shipmentServices.getTaskReportCsv, data)
    yield put(getTaskReportCsvSuccess(response.data.data))
  } catch (error) {
    yield put(shipmentApiError(error))
  }
}

function* getTaskReportById({ payload }: any) {
  try {
    const { id, shipment_id } = payload

    const response: ResponseGenerator = yield call(shipmentServices.getTaskReportById, id, shipment_id)

    if (response.data) {
      yield put(getTaskReportByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

function* doUpdateShipmentRemark({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(shipmentServices.updateShipmentRemark, data)

    if (response.data.status === 'success') {
      yield put(updateShipmentStatusSuccess(response.data.data))
      toast.success('Remark Updated Successfully')
      callback()
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(shipmentApiError(message))
  }
}

export default function* shipmentSaga() {
  yield takeEvery(GET_SHIPMENTS.REQUEST, getShipments)
  yield takeEvery(GET_CONTAINERS.REQUEST, getContainers)
  yield takeEvery(GET_CONTAINER_BY_JOBNUMBER.REQUEST, getContainerByJobNumber)
  yield takeEvery(GET_SHIPMENTS_BY_ID.REQUEST, getShipmentsById)
  yield takeEvery(GET_CONTAINER_BY_ID.REQUEST, getContainerById)
  yield takeEvery(GET_PUBLIC_SHIPMENT_BY_ID.REQUEST, getPublicShipmentById)
  yield takeLatest(GET_SHIPMENT_REPORT.REQUEST, doGetShipmentReport)
  yield takeLatest(GET_SHIPMENT_REPORT_CSV.REQUEST, doGetShipmentReportCsv)
  yield takeEvery(GET_SHIPMENT_REPORT_BY_ID.REQUEST, getShipmentReportById)
  yield takeLatest(GET_TASK_REPORT.REQUEST, doGetTaskReport)
  yield takeLatest(GET_TASK_REPORT_CSV.REQUEST, doGetTaskReportCsv)
  yield takeEvery(GET_TASK_REPORT_BY_ID.REQUEST, getTaskReportById)
  yield takeEvery(UPDATE_SHIPMENT_STATUS, doUpdateShipmentRemark)
}
