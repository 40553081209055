import { combineReducers } from 'redux'

//User
import auth from 'store/auth/reducer'
import rate from 'store/rate/reducer'
import booking from 'store/booking/reducer'
import settings from 'store/settings/reducer'
import dashboard from 'store/dashboard/reducer'
import contact from 'store/contact/reducer'
import shipment from 'store/shipment/reducer'
import bookings from './bookings/reducer'
import additionalDetails from 'store/additionalDetails/reducer'
import workflows from 'store/workflow/reducer'
import tasks from 'store/tasks/reducer'

const rootReducer = combineReducers({
  //public
  auth,
  rate,
  booking,
  settings,
  dashboard,
  contact,
  shipment,
  bookings,
  additionalDetails,
  workflows,
  tasks
})

export default rootReducer
