import React, { useEffect, useRef, useState } from 'react'

//icons
import search from 'assets/icons/search.svg'
import caretdown from 'assets/icons/caret-down.svg'
import { set } from 'react-hook-form'

interface TasksfilterProps {
  status: {
    status: string
    color: string
  }
  setStatus: React.Dispatch<
    React.SetStateAction<{
      status: string
      color: string
    }>
  >
  setShipmentType: React.Dispatch<React.SetStateAction<string>>
  shipmentType?: string
}

const TasksFilters = ({ status, setStatus, shipmentType, setShipmentType }: TasksfilterProps) => {
  const statusref = useRef<HTMLDivElement>(null)
  const [showStatus, setShowStatus] = useState<boolean>(false)
  const [showShipmentType, setShowShipmentType] = useState<boolean>(false)
  const [showTime, setShowTime] = useState<boolean>(false)

  console.log('status>>>', status)

  // 'open tasks', 'incoming tasks', 'delayed tasks', 'completed tasks'

  const options: { status: string; color: string }[] = [
    { status: 'open tasks', color: '#4B83F0' },
    { status: 'incoming tasks', color: '#DB8900' },
    { status: 'delayed tasks', color: '#AD0013' },
    { status: 'completed tasks', color: '#139C33' },
  ]

  const time: string[] = ['all time', 'last 24 hours', 'last week', 'last month', 'last year']

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showStatus && statusref.current && !statusref.current.contains(e.target)) {
        setShowStatus(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showStatus])

  return (
    <div className='flex gap-x-5'>
      <div className='flex items-center gap-x-1 form-input px-4 py-[7px] custom-input w-full black-text md:w-[264px]'>
        <img src={search} alt=''></img>
        <input placeholder='Search by Job Number' className='w-full' />
      </div>
      {/* <div className='relative'>
        <div
          className='flex items-center gap-x-2 w-fit px-3 py-2 cursor-pointer rounded solid-br desktop-only'
          onClick={() => {
            setShowShipmentType(false)
            setShowTime(!showTime)
          }}
        >
          <p className='grey-text-1 font-light text-sm capitalize'>Time</p>
          <img src={caretdown} alt='caret' />
        </div>
        {showTime && (
          <div className={`absolute z-10 w-[145px] p-1 text-sm bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded`}>
            {time.map((option) => (
              <p
                key={option}
                className={`p-2 cursor-pointer capitalize hover:bg-slate-100 font-light rounded`}
                onClick={() => {
                  setShowTime(false)
                }}
              >
                {option}
              </p>
            ))}
          </div>
        )}
      </div> */}
      <div className='relative'>
        <div
          onClick={() => {
            setShowTime(false)
            setShowShipmentType(!showShipmentType)
          }}
          className='flex items-center gap-x-2 w-fit px-3 py-2 cursor-pointer rounded solid-br desktop-only'
        >
          <p className='grey-text-1 font-light text-sm capitalize'>Shipment Type</p>
          <img src={caretdown} alt='caret' />
        </div>
        {showShipmentType && (
          <div className={`absolute z-10 w-[145px] p-1 text-sm bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded`}>
            <p
              onClick={() => {
                setShipmentType('import')
                setShowShipmentType(false)
              }}
              className={`p-2 cursor-pointer capitalize hover:bg-slate-100 font-light rounded`}
            >
              Import
            </p>
            <p
              onClick={() => {
                setShipmentType('export')
                setShowShipmentType(false)
              }}
              className={`p-2 cursor-pointer capitalize hover:bg-slate-100 font-light rounded`}
            >
              Export
            </p>
          </div>
        )}
      </div>
      <div className='relative mobile-only w-full' ref={statusref}>
        <div
          className={`flex items-center gap-x-2 w-fit px-3 py-2 cursor-pointer rounded-lg`}
          style={{ border: `1px solid ${status?.color}` }}
          onClick={() => setShowStatus(!showStatus)}
        >
          <p style={{ color: `${status?.color}` }} className='font-light text-sm capitalize'>
            {status?.status}
          </p>
          <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'>
            <path stroke={status?.color} stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M11.085 4.96 7 9.045 2.918 4.961' />
          </svg>
        </div>
        {showStatus && (
          <div className={`absolute z-10 w-[145px] p-1 text-sm bg-[#F9FAFB] shadow-[1px_4px_12px_-1px_rgba(44,78,39,0.15)] rounded`}>
            {options.map((option) => (
              <p
                key={option?.color}
                className={`p-2 cursor-pointer capitalize hover:bg-slate-100 font-light rounded`}
                style={{ color: `${option?.color}` }}
                onClick={() => {
                  setStatus(option)
                  setShowStatus(false)
                }}
              >
                {option?.status}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TasksFilters
