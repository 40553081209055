import { useNavigate } from 'react-router-dom'
import { TasksCardType } from 'types/dashboard/Dashboard'
import caret_right from 'assets/icons/caret-right.svg'
import info from 'assets/icons/info.svg'
import triangle from 'assets/icons/triangle.svg'
import { useState } from 'react'

export const TaskSkeleton = () => <p className='h-8 w-40 rounded-full bg-[#E6E6E6] animate-pulse'></p>

export const TasksCategory = ({
  icon,
  text,
  isRight = false,
  isView = false,
  tooltip,
  linkTo,
  loading,
}: {
  icon?: string
  text: string
  isRight?: boolean
  tooltip?: string
  isView?: boolean
  linkTo?: string
  loading?: boolean
}) => {
  const navigate = useNavigate()
  const [showToolTip, setShowToolTip] = useState(false)

  return (
    <div className={`flex ${isRight ? 'items-start' : 'items-center'} gap-x-4 mb-8`}>
      {icon && <img className={`${isRight ? 'order-1 ml-auto' : ''}`} src={icon} alt='assigned' />}
      <p className='black-text-3'>{text}</p>
      {isView && (
        <div className='flex items-center gap-x-1 px-3 py-2 rounded-full bg-[#F9FAFB] ml-auto cursor-pointer'>
          <span onClick={() => !loading && navigate(`${linkTo}`)} className='text-sm'>
            View
          </span>
          <img src={caret_right} alt='' />
        </div>
      )}

      {tooltip && (
        <div className='relative ml-auto'>
          <img className='ml-auto' src={info} alt='info' onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)} />
          {showToolTip && (
            <div className='absolute top-8 right-[-15px]'>
              <div className='flex justify-end'>
                <img src={triangle} className='mr-4' alt='' />
              </div>
              <div className='bg-[#1F2937] text-white text-xs leading-5 font-light pl-3 pr-2 py-3 rounded-lg w-[279px] '>{tooltip}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export const TasksDuration = ({ duration, loading }: { duration: number; loading?: boolean }) => {
  return loading ? <TaskSkeleton /> : <p className='black-text-3 text-4xl'>{duration}</p>
}

export const TasksAction = ({
  text,
  color,
  bgColor,
  loading,
  linkTo,
  shipmentType,
}: {
  text: string
  color: string
  bgColor: string
  loading?: boolean
  linkTo?: string
  shipmentType?: string
}) => {
  const navigate = useNavigate()
  return (
    <div
      style={{ backgroundColor: bgColor, color: color }}
      onClick={() => !loading && navigate(`${linkTo}?${shipmentType}`)}
      className='text-xs xl:text-sm pl-4 py-[5px] pr-[5px] mt-8 rounded-full flex justify-between items-center cursor-pointer'
    >
      {text}{' '}
      <span>
        <svg xmlns='http://www.w3.org/2000/svg' width='75' height='32' fill='none'>
          <rect width='75' height='32' fill='#fff' rx='16' />
          <path
            stroke='currentColor'
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
            d='m19.999 12 4.333 4-4.333 4M35.999 12l4.333 4-4.333 4M51.999 12l4.333 4-4.333 4'
          />
        </svg>
      </span>
    </div>
  )
}
const TasksCard = ({ maintext, subtext, action, class_names, isRanking = false, loading }: TasksCardType) => {
  return (
    <div className={`border-grey-2 rounded-lg flex flex-col justify-between ${class_names} ${isRanking ? 'px-6 pt-5 pb-4' : 'p-6'}`}>
      {maintext}
      {subtext}
      {loading ? <TaskSkeleton /> : action}
    </div>
  )
}

export default TasksCard
