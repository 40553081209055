import React, { FC } from 'react'
import { string, bool, func } from 'prop-types'
import { Link } from 'react-router-dom'
import Checkbox from 'react-custom-checkbox'

// icons
import check from 'assets/icons/check.svg'

interface Props {
  name: string
  id: string
  label: string
  isRequired: boolean
  isDisabled: boolean
  onChange: any
  defaultChecked: boolean
  checked: boolean // Add this prop
}

const CustomCheckBox: FC<Props> = ({ name, id, label, isRequired, isDisabled, onChange, defaultChecked, checked }): JSX.Element => {
  return (
    <div>
      <div className='flex items-center'>
        <div className=''>
          <Checkbox
            name={name}
            icon={<img src={check} alt='' />}
            checked={checked} // Use checked prop
            onChange={onChange}
            disabled={isDisabled}
            borderColor='#E5E7EB'
            style={
              label === 'footer-terms' ? { background: '#2C3333', border: '1px solid #6B7280' } : { backgroundColor: `${checked ? '#139C33' : ''}` }
            }
            labelStyle={{ marginLeft: 5, userSelect: 'none' }}
            borderRadius={5}
            borderWidth={checked ? 0 : 1}
          />
          <span></span>
        </div>
      </div>
    </div>
  )
}

export default CustomCheckBox
