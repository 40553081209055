import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import { getAdmins, reAssignTask } from 'store/actions'
import { useSelector } from 'react-redux'

import { getOpsAdmins } from 'helpers/getOpsAdmins'
import CustomSearchSelect from 'components/selectInputs/CustomSearchSelect'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    transform: 'translate(-50%, -50%)',
    width: '434px',
    // height: '485px',
    borderRadius: '10px',
    border: '0.01px solid #888',
    backgroundColor: '#F3F4F6',
  },
  overlay: {
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
    zIndex: '99999999999',
  },
}

const ReassignTaskModal = (props: any) => {
  const { loading, reassignTaskModal, setReassignTaskModal, workflow_task_data, re_assign_data, reAssignTask, admins: remoteAdmins } = props

  let local_admin = useSelector((state: any) => state.dashboard.admins)

  const [admins, setAdmins] = useState([])
  const [assigned, setAssigned] = useState()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => {
    const newData = {
      id: workflow_task_data?.[0]?._id,
      assignee_id: data?.assignee_details?.value,
    }
    // console.log('newdata', newData)
    // console.log('assignee', data?.assignee_details?.value)
    reAssignTask(newData)
  }

  console.log('response', re_assign_data)

  useEffect(() => {
    if (local_admin?.length > 0) {
      setAdmins(getOpsAdmins(local_admin, true))
    } else {
      setAdmins(getOpsAdmins(remoteAdmins, true))
    }
  }, [local_admin, remoteAdmins])

  console.log('workflow dets', workflow_task_data)

  return (
    <Modal
      isOpen={reassignTaskModal}
      style={customStyles}
      contentLabel='Task Management Modal'
      onRequestClose={() => {
        // setEscalateModal(false)
      }}
    >
      <div className='flex items-center p-[24px]'>
        <div>
          <p className='text-[16px text-[#1F2937]]'>Reassign Task</p>
          <p className='text-[12px] text-[#6B7280]'>Kindly select someone for this task</p>
        </div>
        <div className='ml-auto'>
          <i
            className='ion-ios-close py-1 text-3xl black-text cursor-pointer'
            onClick={() => {
              setReassignTaskModal(false)
            }}
          ></i>
        </div>
      </div>
      <div className='bottom-divider'></div>
      <div className='p-[24px]'>
        <p className='text-[14px] font-light text-[#6B7280]'>Current Assignee</p>
        <div className='solid-br bg-[#E5E7EB]'>
          <p className='py-[12px] px-[16px] text-[14px] text-[#6B7280]'>
            {workflow_task_data?.[0]?.assignee_details?.firstname} {workflow_task_data?.[0]?.assignee_details?.lastname}
          </p>
        </div>

        <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
          {/* <form className='pt-4'> */}
          <CustomSearchSelect
            control={control}
            name={`assignee_details`}
            id={`assignee_details`}
            label={'New Assignee'}
            placeholder={'Enter assignee info'}
            isRequired={true}
            errors={errors}
            isDisabled={false}
            defaultValue={''}
            options={admins}
          />
          <div className='py-6 text-center'>
            <button
              type='submit'
              className='bg-[#139C33] transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-white text-sm font-normal rounded w-full mb-3'
            >
              Yes, I want to reassign this task
            </button>
            <button
              type='submit'
              className='bg-[#F9FAFB] transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-[#8A0000] text-sm font-normal rounded w-full mb-3'
              onClick={() => {}}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  const { loading_admins, admins } = state.dashboard
  const { error, re_assign_data, loading } = state.tasks
  return { error, re_assign_data, loading, loading_admins, admins }
}

export default connect(mapStateToProps, { getAdmins, reAssignTask })(ReassignTaskModal)
