import React, { useEffect, useRef } from 'react'
import { useState } from 'react'

//components
import CustomDefaultSelect from 'components/selectInputs/CustomDefaultSelect'

//libraries
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { useForm } from 'react-hook-form'
import moment from 'moment'

// icons
import calendar from 'assets/icons/calendar1.svg'
import stroke from 'assets/icons/stroke-down.svg'
import PrimaryButtons from 'components/buttons/PrimaryButtons'

import { connect, useSelector } from 'react-redux'
import { getOpsAdmins } from 'helpers/getOpsAdmins'
import { getAdmins } from 'store/actions'
import { SelectInstance } from 'react-select'

const ReportFilter = (props: any): JSX.Element => {
  const {
    handleFilter,
    filteredBookings,
    applyFilter,
    filterDisplay,
    exportMain,
    setExportMain,
    importMain,
    setImportMain,
    air,
    setAir,
    haulage,
    setHaulage,
    ocean,
    setOcean,
    warehouse,
    setWarehouse,
    cbt,
    setCBT,
    setFilterDisplay,
    loading,
    dateRange,
    setDateRange,
    startDate,
    endDate,
    clear,
    setClear,
    tab,
    admins: remoteAdmins,
    getAdmins,
  } = props

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm()

  const [shipmentType, setShipmentType] = useState('')
  const [shipmentTransportType, setShipmentTransportType] = useState('')
  let local_admin = useSelector((state: any) => state.dashboard.admins)
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    if (local_admin?.length > 0) {
      setAdmins(getOpsAdmins(local_admin, true))
    } else {
      setAdmins(getOpsAdmins(remoteAdmins, true))
    }
  }, [local_admin, remoteAdmins])

  useEffect(() => {
    getAdmins()
  }, [])

  const onSubmit = (data: any) => {
    setFilterDisplay(false)
    handleFilter({
      assignee_id: data.assignee.value ? data.assignee.value : '',
      status: data.type_of_status.value === undefined ? '' : data.type_of_status.value,
    })
  }

  return (
    // {/* filtered page **********************/}
    <form onSubmit={handleSubmit(onSubmit)} className={filterDisplay ? 'bg-[white] w-full z-50 shadow-xl' : 'hidden'}>
      {/* scroll */}
      <div className='py-6 rounded px-5 overflow-y-auto '>
        {/* head */}
        <div className='flex items-center justify-between'>
          <p className='text-base black-text-3'>Filter</p>

          <div className='cursor-pointer'>
            <p
              className='text-sm font-medium text-[#8A0000]'
              onClick={() => {
                console.log('what is showing filter ')
                handleFilter({
                  assignee_id: '',
                  status: '',
                })

                setFilterDisplay(false)
                reset()
              }}
            >
              Clear filter
            </p>
          </div>
        </div>

        {/* Transport type */}

        {/* line */}
        <div className='w-full h-[1px] my-4 bg-[#F3F4F6]'></div>

        {/* status */}
        {tab == 'Ongoing' && (
          <div className='w-full '>
            <p className='black-text-3 text-sm'>Status</p>
            {/* select */}
            <div className='mt-[-16px]'>
              <CustomDefaultSelect
                control={control}
                name={'type_of_status'}
                id={'type_of_status'}
                label={''}
                placeholder={'Select Status'}
                isRequired={false}
                errors={errors}
                isDisabled={false}
                defaultValue={''}
                options={[
                  { label: 'On Track', value: 'on-track' },
                  { label: 'Delayed', value: 'delayed' },

                  { label: 'Incoming', value: 'incoming' },
                ]}
                icon=''
              />
            </div>
          </div>
        )}

        <div className='w-full '>
          <p className='black-text-3 text-sm'>Task Assignee</p>
          {/* select */}
          <div className='mt-[-16px]'>
            <CustomDefaultSelect
              control={control}
              name={'assignee'}
              id={'assignee'}
              label={''}
              placeholder={'Select Assignee'}
              isRequired={false}
              errors={errors}
              isDisabled={false}
              defaultValue={''}
              options={admins}
              icon=''
            />
          </div>
        </div>

        {/* line */}
        <div className='w-full h-[1px] my-4 bg-[#F3F4F6]'></div>

        {/* cancel and apply filter button */}
        <div className='flex item-center gap-x-[24px] mt-[48px]'>
          <div className='bg-[#F9FAFB] py-[10px] px-[14px] rounded cursor-pointer' onClick={() => setFilterDisplay(false)}>
            <p className='text-sm text-[#139C33]'>Cancel</p>
          </div>
          <PrimaryButtons title='Apply filter' style={{}} disabled={false} loading={loading} icon={''} />
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading_admins, admins } = state.dashboard

  const { edit_loading } = state.workflows

  return { error, loading_admins, admins, edit_loading }
}

export default connect(mapStateToProps, { getAdmins })(ReportFilter)
