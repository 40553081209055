import React, { useState } from 'react'
import { Control, FieldErrors } from 'react-hook-form'

import { useForm } from 'react-hook-form'

import uniqid from 'uniqid'

import { useSelector } from 'react-redux'

import littlecross from 'assets/icons/littlecross.svg'
import deleteicon from 'assets/icons/delete.svg'

import CustomInput from 'components/textInputs/CustomInput'
import PrimaryButtons from 'components/buttons/PrimaryButtons'

import { useWorkflowConfigurationContext } from 'pages/reports/shipments/workflowConfiguration/CreateWorkflowConfiguration'
import { getOpsAdmins } from 'helpers/getOpsAdmins'
import CustomSearchSelect from 'components/selectInputs/CustomSearchSelect'
import TaskToggle from 'components/toggles/TaskToggle'

//libraries
var converter = require('number-to-words')

interface MilestoneFormTasksType {
  idx: number
  uid: string
}

const MilestoneTasksFiles = ({ idx, uid }: MilestoneFormTasksType) => {
  const { selectedMilestoneId, updateMilestoneTask, milestones } = useWorkflowConfigurationContext()

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values: { file_name: milestones.find((i) => i.uid === selectedMilestoneId)?.milestone_tasks.find((i) => i.uid === uid)?.file_names[idx] },
  })

  const handleOnChange = (value) => {
    updateMilestoneTask(
      selectedMilestoneId,
      uid,
      'file_names',
      [...(milestones.find((i) => i.uid === selectedMilestoneId)?.milestone_tasks.find((i) => i.uid === uid)?.file_names || [])]?.map((name, i) =>
        i !== idx ? name : value
      ) as any
    )
  }

  const handleDeleteTwo = () => {
    // if (
    //   single_workflow &&
    //   (((single_workflow?.milestones || [])?.find((i) => i?._id === selectedMilestoneId)?.milestone_tasks || [])?.find((i) => i?._id === uid)
    //     ?.milestone_task_files || [])?.[idx]
    // )
    //   return
    const length_before = (milestones.find((i) => i.uid === selectedMilestoneId)?.milestone_tasks.find((i) => i.uid === uid)?.file_names || [])
      ?.length
    console.log('lengthBefrore>>>', length_before)
    //length before is 1, function below removes 1 extra file
    updateMilestoneTask(
      selectedMilestoneId,
      uid,
      'file_names',
      [...(milestones.find((i) => i.uid === selectedMilestoneId)?.milestone_tasks.find((i) => i.uid === uid)?.file_names || [])]?.filter(
        (name, i) => i !== idx
      ) as any
    )
    if (length_before < 2) {
      updateMilestoneTask(selectedMilestoneId, uid, 'is_file_upload', false as any)
    }
  }

  return (
    <form>
      <CustomInput
        control={control}
        name={`file_name`}
        id={`file_name`}
        label={`${idx === 0 ? 'Document Name' : `Document-${idx + 1} Name`}`}
        placeholder={'doc/image name'}
        isRequired={false}
        type={'text'}
        errors={errors}
        isDisabled={false}
        defaultValue={''}
        min={''}
        max={''}
        icon={deleteicon}
        customOnChange={handleOnChange}
        customFunction={handleDeleteTwo}
      />
    </form>
  )
}

const MilestoneFormTasks = ({ idx, uid }: MilestoneFormTasksType) => {
  const { selectedMilestoneId, removeMilestoneTask, updateMilestoneTask, milestones } = useWorkflowConfigurationContext()

  let local_admin = useSelector((state: any) => state.dashboard.admins)
  const admins = getOpsAdmins(local_admin, true)
  // const admins = local_admin

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    values: {
      ...milestones.find((i) => i?.uid === selectedMilestoneId)?.milestone_tasks.find((i) => i?.uid === uid),
      assignee_id:
        admins?.find(
          (i) => i?.value === milestones?.find((i) => i?.uid === selectedMilestoneId)?.milestone_tasks?.find((i) => i?.uid === uid)?.assignee_id
        ) || '',
    },
  })

  console.log('milestone >>>', milestones)

  const addDocument = () => {
    updateMilestoneTask(selectedMilestoneId, uid, 'file_names', [
      ...(milestones?.find((i) => i?.uid === selectedMilestoneId)?.milestone_tasks?.find((i) => i.uid === uid)?.file_names || []),
      '',
    ] as any)
    updateMilestoneTask(selectedMilestoneId, uid, 'is_file_upload', true as any)
  }

  console.log('admins_workflow>>', admins)

  return (
    <>
      {idx === 0 ? (
        <div className='my-6'></div>
      ) : (
        <div className='text-sm flex items-center justify-between my-6 '>
          <p className='black-text-2 capitalize'>{converter.toWordsOrdinal(idx + 1)} Task</p>
          <p onClick={() => removeMilestoneTask(selectedMilestoneId, uid)} className='text-[#991B1B] cursor-pointer'>
            Remove Task
          </p>
        </div>
      )}
      <div className=' flex justify-end gap-[4px]  items-center pt-3'>
        <TaskToggle selectedMilestoneId={selectedMilestoneId} uid={uid} updateMilestoneTask={updateMilestoneTask} />
        <p className='text-[#139C33] text-xs'>Customers Task</p>
      </div>

      <form className=' grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3 mt-3'>
        <CustomInput
          control={control}
          name={`milestone_task_name`}
          id={`milestone_task_name`}
          label={'Task Name'}
          placeholder={'Container Setup'}
          isRequired={false}
          type={'text'}
          errors={errors}
          isDisabled={false}
          defaultValue={''}
          min={''}
          max={''}
          icon=''
          customOnChange={(value) => updateMilestoneTask(selectedMilestoneId, uid, 'milestone_task_name', value)}
        />

        <CustomSearchSelect
          control={control}
          name={`assignee_id`}
          id={`assignee_id`}
          label={'Assignee'}
          placeholder={'Select Assignee'}
          isRequired={false}
          errors={errors}
          isDisabled={false}
          options={admins}
          defaultValue={''}
          customOnChange={(value) => updateMilestoneTask(selectedMilestoneId, uid, 'assignee_id', value)}
        />

        <CustomInput
          control={control}
          name={`duration`}
          id={`duration`}
          label={'Task Duration (days)'}
          placeholder={'1, 2, 3...'}
          isRequired={false}
          type={'number'}
          errors={errors}
          isDisabled={false}
          defaultValue={''}
          min={''}
          max={''}
          icon=''
          customOnChange={(value) => updateMilestoneTask(selectedMilestoneId, uid, 'duration', value)}
        />
        <>
          {(milestones?.find((i) => i?.uid === selectedMilestoneId)?.milestone_tasks?.find((i) => i?.uid === uid)?.file_names || [])?.length > 0 &&
            (milestones?.find((i) => i?.uid === selectedMilestoneId)?.milestone_tasks?.find((i) => i?.uid === uid)?.file_names || [])?.map(
              (item, idx) => {
                return <MilestoneTasksFiles key={idx + uid} idx={idx} uid={uid} />
              }
            )}
        </>
      </form>
      <div className='border-solid border-[#E6E7EC] border-b-[1px] w-full flex items-center gap-x-4 pt-3 pb-6'>
        <PrimaryButtons
          title='Add Document'
          style={{ backgroundColor: 'transparent', color: '#109B32', padding: '0' }}
          disabled={false}
          icon={littlecross}
          onClick={addDocument}
          isPadded={false}
        />
      </div>
    </>
  )
}

export default MilestoneFormTasks
