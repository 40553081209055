import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ResponseGenerator } from 'store/type'
import { toast } from 'react-toastify'
import { errorHandler } from 'helpers/errorHandler'
import uniqid from 'uniqid'
//constants
import {
  GET_WORKFLOWS,
  CREATE_WORKFLOW,
  GET_SINGLE_WORKFLOW,
  EDIT_WORKFLOW,
  DEACTIVATE_WORKFLOW,
  DELETE_WORKFLOW,
  ACTIVATE_WORKFLOW,
  GET_WORKFLOWS_CSV,
} from 'store/workflow/constants'

//actions
import {
  getWorkflowsSuccess,
  createWorkflowSuccess,
  getSingleWorkflowSuccess,
  editWorkflowSuccess,
  activateWorkflowSuccess,
  deactivateWorkflowSuccess,
  workflowsApiError,
  deleteWorkflowSuccess,
  getWorkflowsCsvSuccess,
} from './actions'

//services
import workflowServices from 'api/services/workflows'

function* getWorkflows({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(workflowServices.getWorkflows, data)
    if (response.data) {
      yield put(getWorkflowsSuccess(response.data.data))
    }
  } catch (error) {
    yield put(workflowsApiError(error))
  }
}

function* getWorkflowsCsv({ payload }: any) {
  try {
    const { data } = payload
    const response: ResponseGenerator = yield call(workflowServices.getWorkflowsCsv, data)
    if (response.data) {
      yield put(getWorkflowsCsvSuccess(response.data.data))
    }
  } catch (error) {
    yield put(workflowsApiError(error))
  }
}

function* createWorkflow({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(workflowServices.createWorkflow, data)

    if (response.data.status === 'success') {
      yield put(createWorkflowSuccess(response.data))
      toast.success('Workflow Created Successfully')

      if (callback === undefined) {
      } else {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

function* deleteWorkflow({ payload }: any) {
  try {
    const { id, callback } = payload
    console.log('delete workflow>>>>', id)
    const response: ResponseGenerator = yield call(workflowServices.deleteWorkflow, id)

    if (response.data.status === 'success') {
      yield put(deleteWorkflowSuccess(response.data))
      toast.success('Workflow Deleted Successfully')
      if (callback !== undefined) {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

function* getSingleWorkflow({ payload }: any) {
  try {
    const { id } = payload
    console.log('get single workflow saga', id)
    const response: ResponseGenerator = yield call(workflowServices.getSingleWorkflow, id)

    if (response.data.status === 'success') {
      yield put(
        getSingleWorkflowSuccess({
          ...(response?.data?.data || {}),
          milestones: (response?.data?.data?.milestones || [])?.map((i) => ({
            ...i,
            milestone_tasks: (i?.milestone_tasks || [])?.map((j) => ({
              ...j,
              _id: uniqid(),
            })),
          })),
        })
      )
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

function* activateWorkflow({ payload }: any) {
  try {
    const { id, callback } = payload
    console.log('activate workflow saga', id)
    const response: ResponseGenerator = yield call(workflowServices.activateWorkflow, id)

    if (response.data.status === 'success') {
      yield put(activateWorkflowSuccess(response.data.data))
      toast('Workflow activated successfully')
      if (callback !== undefined) {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

function* deactivateWorkflow({ payload }: any) {
  try {
    const { id, callback } = payload
    const response: ResponseGenerator = yield call(workflowServices.deactivateWorkflow, id)

    if (response.data.status === 'success') {
      yield put(deactivateWorkflowSuccess(response.data.data))
      toast('Workflow deactivated successfully')
      if (callback !== undefined) {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

function* editWorkflow({ payload }: any) {
  try {
    const { data, callback } = payload

    const response: ResponseGenerator = yield call(workflowServices.editWorkflow, data)

    if (response.data.status === 'success') {
      yield put(editWorkflowSuccess(response.data))
      toast.success('Workflow Updated Successfully')
      if (callback === undefined) {
      } else {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(workflowsApiError(message))
  }
}

export default function* workflowsSaga() {
  yield takeEvery(GET_WORKFLOWS.REQUEST, getWorkflows)
  yield takeLatest(GET_WORKFLOWS_CSV.REQUEST, getWorkflowsCsv)
  yield takeEvery(CREATE_WORKFLOW.REQUEST, createWorkflow)
  yield takeEvery(GET_SINGLE_WORKFLOW.REQUEST, getSingleWorkflow)
  yield takeEvery(EDIT_WORKFLOW.REQUEST, editWorkflow)
  yield takeEvery(ACTIVATE_WORKFLOW.REQUEST, activateWorkflow)
  yield takeEvery(DEACTIVATE_WORKFLOW.REQUEST, deactivateWorkflow)
  yield takeEvery(DELETE_WORKFLOW.REQUEST, deleteWorkflow)
}
