import { generateActions } from 'helpers/generateActions'

export const GET_WORKFLOWS = generateActions('GET_WORKFLOWS')
export const GET_WORKFLOWS_CSV = generateActions('GET_WORKFLOWS_CSV')

export const CREATE_WORKFLOW = generateActions('CREATE_WORKFLOW')

export const GET_SINGLE_WORKFLOW = generateActions('GET_SINGLE_WORKFLOW')

export const EDIT_WORKFLOW = generateActions('EDIT_WORKFLOW')

export const ACTIVATE_WORKFLOW = generateActions('ACTIVATE_WORKFLOW')

export const DEACTIVATE_WORKFLOW = generateActions('DEACTIVATE_WORKFLOW')

export const DELETE_WORKFLOW = generateActions('DELETE_WORKFLOW')

export const CLEAR_WORKFLOW = 'CLEAR_WORKFLOW'

export const API_ERROR = 'API_ERROR'
