import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import Layout from 'components/layout/Layout'
import PageTitle from 'components/partials/PageTitle'
import WorkflowFilter from 'components/workflowConfiguration/WorkflowFilter'
import WorkflowConfigurationTable from 'components/workflowConfiguration/WorkflowConfigurationTable'
import LoadingSpinner from 'components/partials/LoadingSpinner'

import { WorkflowType, FilterObjectType, WorkflowconfigurationType } from 'types/workflowConfiguration/WorkflowConfiguration'

import { getWorkflows, getWorkflowsCsv } from 'store/actions'
import PageLoading from 'components/partials/pageLoading'
import { useSelector } from 'react-redux'

const WorkflowConfiguration = ({
  workflows,
  total_workflow,
  getWorkflows,
  table_loading,
  loading,
  workflows_csv,
  getWorkflowsCsv,
}: WorkflowconfigurationType) => {
  const [filterDisplay, setFilterDisplay] = useState<boolean>(false)
  const [filteredWorkflow, setFilteredWorkflow] = useState<WorkflowType[]>([])
  const [filterObject, setFilterObject] = useState<FilterObjectType>({ page: '0', count: '10' })

  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange

  const filter_ref = useRef<HTMLDivElement>(null)

  const [clear, setClear] = useState(false)
  const storedCountry = localStorage.getItem('country')
  console.log('userC >>>', storedCountry)

  const handlePageChange: (page: number) => void = (page) => {
    setFilterObject({ ...filterObject, ...{ page: String(page - 1) } })
  }

  console.log(filteredWorkflow)
  const handlePerRowsChange: (newPerPage: number, page: number) => void = (newPerPage, page) => {
    setFilterObject({
      ...filterObject,
      ...{ page: String(page), count: String(newPerPage) },
    })
  }

  const handleSearch: (value: string, type?: string) => void = (value, type) => {
    if (value) {
      setFilterObject({ ...filterObject, page: '0', search: value })
    } else {
      setFilterObject({ ...filterObject, page: '0', search: value })
    }
  }

  const handleFilter = (value: any, type: string) => {
    if (value) {
      setFilterObject({
        ...filterObject,
        ...{
          page: '0',
          shipment_transport_type: value.shipment_transport_type,
          shipment_type: value.shipment_type,
          shipment_status: value.shipment_status,
          start_date: value.start_date,
          end_date: value.end_date,
        },
      })
    }
  }

  const fetchWorkflow = () => {
    // const filter_string = new URLSearchParams(filterObject).toString()
    const filter_string = new URLSearchParams(Object.entries(filterObject).map(([key, value]) => [key, String(value)])).toString()
    getWorkflows(filter_string)
  }

  useEffect(() => {
    fetchWorkflow()
  }, [filterObject])

  useEffect(() => {
    getWorkflowsCsv('')
  }, [])

  useEffect(() => {
    if (workflows?.length > 0) {
      setFilteredWorkflow(workflows)
    } else {
      setFilteredWorkflow([])
    }
  }, [workflows])

  //creating the click outside to close drop down effect
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (filterDisplay && filter_ref.current && !filter_ref.current.contains(e.target)) {
        setFilterDisplay(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [filterDisplay])

  return (
    <Layout>
      <div className='px-4 pt-8 lg:pt-10 lg:px-10 dashboard-content-scroll'>
        {loading ? (
          <PageLoading title='workflows' />
        ) : (
          <>
            <PageTitle text={'Workflow Configurations'} subtext='Here’s a list of the workflow configs present on the system' />
            <div className='relative'>
              {table_loading && (
                <div className='absolute h-full w-full bg-[#7c8d788c] z-[999999] flex justify-center'>
                  <div className='pt-5'>
                    <LoadingSpinner top={false} height='10' loadingText='Loading table data' />
                  </div>
                </div>
              )}
              <div
                ref={filter_ref}
                className='fixed top-0 left-0 sm:absolute w-full sm:w-[336px] sm:left-auto sm:right-0 md:left-[280px] sm:top-12 z-50'
              >
                <WorkflowFilter
                  handleFilter={handleFilter}
                  filterDisplay={filterDisplay}
                  filteredBookings={filteredWorkflow}
                  setFilterDisplay={setFilterDisplay}
                  loading={loading}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  startDate={startDate}
                  endDate={endDate}
                  setClear={setClear}
                  clear={clear}
                  type={'shipment'}
                />
              </div>
              <WorkflowConfigurationTable
                filteredWorkflow={filteredWorkflow}
                workflows_csv={workflows_csv}
                total_workflow={total_workflow}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                handleSearch={handleSearch}
                filterObject={filterObject}
                setFilterDisplay={setFilterDisplay}
              />
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { workflows, total_workflow, error, loading, table_loading, workflows_csv } = state.workflows
  return { workflows, total_workflow, error, loading, table_loading, workflows_csv }
}

export default connect(mapStateToProps, { getWorkflows, getWorkflowsCsv })(WorkflowConfiguration)
