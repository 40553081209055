import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Modal from 'react-modal'
import { getShipmentReportById, updateShipmentStatus } from 'store/actions'
import PageLoading from 'components/partials/pageLoading'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import Moment from 'react-moment'
import { ShipmentModalType } from 'types/reports/Shipments'
import stick from 'assets/icons/stick.svg'
import ServiceDocs from 'assets/icons/service-docs.svg'
import { report } from 'process'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'
import EscalationRemarkComp from './EscalationRemarkComp'
import ShipmentTimeline from './ShipmentTimeline'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    marginRight: '-50%',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '0px',
    paddingLeft: '0px',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    maxWidth: '800px',
    height: '600px',
    borderRadius: '10px',
    // border: '0.01px solid #888',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: '99999999999',
  },
}

const ReportModal = ({
  isOpen,
  setIsOpen,
  reportData,
  shipment_report_data,
  getShipmentReportById,
  updateShipmentStatus,
  loading,
}: ShipmentModalType) => {
  const nav = useNavigate()

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const reportData_id = reportData?.shipment_id

  useEffect(() => {
    getShipmentReportById(reportData_id)
  }, [getShipmentReportById, reportData_id])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    const handleChange = () => {
      if (mediaQuery.matches && isOpen) {
        setIsOpen(false)
      }
    }

    mediaQuery.addEventListener('change', handleChange)
    return () => mediaQuery.removeEventListener('change', handleChange)
  }, [isOpen, setIsOpen])

  console.log(shipment_report_data, 'shipment_report_data')
  console.log(reportData, 'shipment_report_data')

  const goToShipmentReport = (params_id) => {
    nav(`/reports/shipments`)
  }

  const onSubmit = (data: any) => {
    const newData = {
      id: reportData_id,
      remarks: data?.remarks,
      shipment_status: reportData?.shipment_details?.shipment_status,
      assigned_to: reportData?.shipment_assignee_details?.assigned_to,
    }
    //@ts-ignore
    updateShipmentStatus(newData, goToShipmentReport)
    // Optionally, you can set a confirmation message or state here to indicate success
  }

  const ref = useRef<HTMLDivElement>(null)

  console.log('customer dets', reportData)

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={customStyles}
      contentLabel='Single Report Modal'
      onRequestClose={() => {
        setIsOpen(false)
      }}
    >
      {loading ? (
        <div>
          <div className='mt-3'>
            <PageLoading title={'Reports'} />
          </div>
        </div>
      ) : (
        <div>
          <div className='flex py-[16px] px-[24px] justify-between items-center border border-b-[#F3F4F6] border border-x-[transparent] border border-t-[transparent] '>
            <div className='flex flex-col gap-[5px]'>
              <div className='flex items-center  gap-[8px]'>
                <p className='font-[14px] text-[#004300] text-left capitalize font-medium'>
                  {reportData?.shipment_details?.shipment_type}, {getMultipleServiceCombinations(reportData?.shipment_details)}
                </p>
                {reportData?.is_delayed && (
                  <div className='bg-[#FFBAB333] w-fit rounded-[23px] py-[4px] px-[8px] mx-auto'>
                    <p className='text-[10px] text-[#AD0013] font-medium'>Delayed Shipment</p>
                  </div>
                )}
              </div>

              <div className='flex gap-[4px] text-xs text-[#4B5563] font-normal items-center'>
                <p className=' '>
                  {reportData?.shipment_details?.origin_port_code} ({reportData?.shipment_details?.bl_number})
                </p>
                <div className='w-[1px] h-[9px] bg-[#E5E7EB]'></div>
                <p> {reportData?.company_details?.[0]?.company_name}</p>
              </div>
            </div>

            <div className=''>
              <i className='ion-ios-close  text-3xl black-text cursor-pointer' onClick={() => setIsOpen(false)}></i>
            </div>
          </div>

          <div className='grid md:grid-cols-1  lg:grid-cols-3  h-full'>
            <ShipmentTimeline shipment_report_data={shipment_report_data} />
            <EscalationRemarkComp shipment_report_data={shipment_report_data} onSubmit={onSubmit} loading={loading} />
          </div>
        </div>
      )}
    </Modal>
  )
}

const mapStateToProps = (state: any) => {
  const { shipment_report_data, loading } = state.shipment
  return { shipment_report_data, loading }
}
//@ts-ignore
export default connect(mapStateToProps, { getShipmentReportById, updateShipmentStatus })(ReportModal)
