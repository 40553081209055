import { GET_DASHBOARD_PARAMS, GET_ADMINS, GET_PENDING_DASHBOARD_QUOTES, API_ERROR } from 'store/dashboard/constants'

const initialState = {
  error: '',
  loading: false,
  loading_dashboard_analytics: false,
  loading_admins: false,
  admins: [],
  dashboard_params: {},
  pending_dashboard_quote: {},
}

const dashboard = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_DASHBOARD_PARAMS.REQUEST:
      state = {
        ...state,
        loading_dashboard_analytics: true,
        loading: true,
        error: '',
      }
      break

    case GET_DASHBOARD_PARAMS.SUCCESS: {
      state = {
        ...state,
        loading_dashboard_analytics: false,
        loading: false,
        error: '',
        dashboard_params: action.payload.data,
      }
      break
    }

    case GET_PENDING_DASHBOARD_QUOTES.REQUEST:
      state = {
        ...state,
        loading: true,
        error: '',
      }
      break

    case GET_PENDING_DASHBOARD_QUOTES.SUCCESS:
      state = {
        ...state,
        loading: false,
        error: '',
        pending_dashboard_quote: action.payload.data,
      }
      break

    case GET_ADMINS.REQUEST: {
      state = {
        ...state,
        loading_admins: true,
        error: '',
      }
      break
    }

    case GET_ADMINS.SUCCESS: {
      state = { ...state, loading_admins: false, admins: action.payload.data, error: '' }
      break
    }

    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default dashboard
