import React from 'react'
import { CSVLink } from 'react-csv'
import exp from 'assets/icons/export-csv.svg'
interface Props {
  csvBookingData: any
  name: string
}
const ExportCsv = ({ csvBookingData, name }: Props) => {
  return (
    <CSVLink data={csvBookingData ? csvBookingData : ''} asyncOnClick={true} loading={true} filename={`${name}.csv`}>
      <div className='desktop-only border border-[#e5e7eb] cursor-pointer px-[16px] py-[12px] items-center rounded-[4px] flex gap-[8px]'>
        <img src={exp} alt=''></img>
        <p className='text-[#9CA3AF] text-xs '>Export</p>
      </div>
    </CSVLink>
  )
}

export default ExportCsv
