//icons
import info from 'assets/icons/info.svg'
import A from 'assets/icons/A.svg'
import B from 'assets/icons/B.svg'
import C from 'assets/icons/C.svg'
import D from 'assets/icons/D.svg'
import norank from 'assets/icons/norank.svg'
import triangle from 'assets/icons/triangle.svg'

import { TaskSkeleton } from './TasksCard'
import { useState } from 'react'

interface OpsGradeProps {
  score: number
  admin_role: string
  selectedOpsMember: { firstname: string; lastname: string; id: string }
  loading: boolean
  totalTask: number
}

export const gradeDetails = [
  { minScore: 75, imgSrc: A, grade: 'A', text: 'Your efforts are valued', bgColor: 'bg-[#ffe75d26]', textColor: 'text-[#C27500]' },
  { minScore: 60, imgSrc: B, grade: 'B', text: 'Keep going! You’re almost at A rank', bgColor: 'bg-[#E5E7EB]', textColor: 'text-[#1F2937]' },
  { minScore: 50, imgSrc: C, grade: 'C', text: 'Average performance, let’s go harder', bgColor: 'bg-[#dba16026]', textColor: 'text-[#8F492A]' },
  { minScore: 0, imgSrc: D, grade: 'D', text: 'Something wrong? Reach out for support', bgColor: 'bg-[#ff9c9726]', textColor: 'text-[#E11435]' },
]

export const OpsGrade: React.FC<OpsGradeProps> = ({ score, admin_role, selectedOpsMember, loading, totalTask }) => {
  console.log('score>>', score)

  const [showToolTip, setShowToolTip] = useState(false)

  const { imgSrc, grade, text } = gradeDetails.find(({ minScore }) => score >= minScore) || {}

  return loading ? (
    <TaskSkeleton />
  ) : (
    <div className='flex gap-x-4 items-center mb-4 relative'>
      <img src={totalTask === 0 ? norank : imgSrc} alt='ranking' />
      <div>
        <p className='black-text-3 font-medium'>{totalTask === 0 ? 'No' : grade} Ranking</p>
        <p className='black-text-3 font-light text-xs'>{totalTask === 0 ? 'There is no task rank over this time period' : text}</p>
      </div>
      <img className='ml-auto' src={info} alt='info' onMouseEnter={() => setShowToolTip(true)} onMouseLeave={() => setShowToolTip(false)} />
      {showToolTip && (
        <div className='absolute top-8 right-[-15px]'>
          <div className='flex justify-end'>
            <img src={triangle} className='mr-4' alt='' />
          </div>
          <div className='bg-[#1F2937] text-white text-xs leading-5 font-light pl-3 pr-2 py-3 rounded-lg w-[279px] '>
            {selectedOpsMember.firstname === 'everyone' && admin_role === 'super_admin'
              ? 'This rank is based on the overall team tasks completed on schedule in ratio to total task assigned to the team'
              : 'Your rank is based on task completed on schedule in ratio to total task assigned to you'}
          </div>
        </div>
      )}
    </div>
  )
}

interface OpsScoreProps {
  score: number
  loading: boolean
  totalTask: number
}

const TOTAL_POINTS = '100%'

export const OpsScore: React.FC<OpsScoreProps> = ({ score, loading, totalTask }) => {
  const { bgColor, textColor } = gradeDetails.find(({ minScore }) => score >= minScore) || gradeDetails[gradeDetails?.length - 1]

  return loading ? (
    <TaskSkeleton />
  ) : (
    <div className={`py-2 px-3 ${totalTask === 0 ? 'bg-[#E5E7EB]' : bgColor} rounded-lg flex grey-text w-fit`}>
      <p className={`text-4xl font-medium ${totalTask === 0 ? 'text-[#1F2937]' : textColor}`}>{totalTask === 0 ? '0.00' : score}</p>
      <span className='self-end ml-1'>/</span>
      <p className='self-end text-xs'>{TOTAL_POINTS}</p>
    </div>
  )
}
