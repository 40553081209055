import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ResponseGenerator } from 'store/type'
import { errorHandler } from 'helpers/errorHandler'
import { toast } from 'react-toastify'

//constants
import {
  GET_ALL_TASKS,
  GET_ALL_WORKFLOW_TASKS,
  GET_DELAYED_TASKS,
  GET_WORKFLOW_TASK_BY_ID,
  COMPLETE_WORKFLOW_TASK,
  ESCALATE_WORKFLOW_TASK,
  ATTACH_DOC_TO_TASK,
  SAVE_WORKFLOW_CONTAINER,
  GET_TASKS,
  REASSIGN_TASK,
  GET_ALL_TASKS_CSV,
} from './constants'

//actions
import {
  getAllTasksSuccess,
  getAllWorkflowTasksSuccess,
  getDelayedWorkflowTasksSuccess,
  getWorkflowTaskByIdSuccess,
  completeWorkflowTaskSuccess,
  completeWorkflowTaskError,
  escalateWorkflowTaskSuccess,
  attachDocsToTaskSuccess,
  saveWorkflowContainerSuccess,
  tasksApiError,
  getTasksSuccess,
  reAssignTaskSuccess,
  getAllTasksCsvSuccess,
} from './actions'

//services
import tasksServices from 'api/services/tasks'
import { patch } from 'helpers/axios'

function* getAllTasks() {
  try {
    const response: ResponseGenerator = yield call(tasksServices.getAllTasks)

    if (response.data.status === 'success') {
      yield put(getAllTasksSuccess(response.data?.data?.[0]?.tasks))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* getAllTasksCsv() {
  try {
    const response: ResponseGenerator = yield call(tasksServices.getAllTasksCsv)

    if (response.data.status === 'success') {
      yield put(getAllTasksCsvSuccess(response.data?.data?.get_all_tasks[0]?.tasks))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* getTasks() {
  try {
    const response: ResponseGenerator = yield call(tasksServices.getTasks)

    if (response.data.status === 'success') {
      yield put(getTasksSuccess(response.data?.data?.[0]?.tasks))
      console.log('get_tasks_saga>>', response.data)
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* getAllWorkflowTasks({ payload }: any) {
  const { query } = payload
  try {
    const response: ResponseGenerator = yield call(tasksServices.getAllWorkflowTasks, query)

    if (response.data.status === 'success') {
      // console.log('response', response)
      yield put(getAllWorkflowTasksSuccess(response.data?.data?.get_all_tasks[0]))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* getDelayedTasks({ payload }: any) {
  const { query } = payload
  try {
    const response: ResponseGenerator = yield call(tasksServices.getDelayedTasks, query)

    if (response.data.status === 'success') {
      // console.log('response', response)
      yield put(getDelayedWorkflowTasksSuccess(response.data?.data?.[0]?.tasks))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* getWorkflowTaskById({ payload }: any) {
  try {
    const { id } = payload

    const response: ResponseGenerator = yield call(tasksServices.getWorkflowTaskById, id)

    if (response.data) {
      yield put(getWorkflowTaskByIdSuccess(response.data))
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* doCompleteWorkflowTasks({ payload }: any) {
  try {
    const { id, callback, data } = payload
    const response: ResponseGenerator = yield call(tasksServices.completeWorkflowTask, id, data)

    if (response.data.status === 'success') {
      yield put(completeWorkflowTaskSuccess(response.data.data))
      toast.success('Task Successfully Completed')
      window.location.replace(`${window.location.origin}/tasks`)
      if (callback !== undefined) {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
    yield put(completeWorkflowTaskError(error))
  }
}

function* doEscalateWorkflowTasks({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(tasksServices.escalateWorkflowTask, data)

    if (response.data.status === 'success') {
      yield put(escalateWorkflowTaskSuccess(response.data.data))
      toast.success('Task Successfully Escslated')
      window.location.replace(`${window.location.origin}/tasks`)
      // callback()
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* doReAssignTask({ payload }: any) {
  try {
    const { id, data, callback } = payload
    const response: ResponseGenerator = yield call(tasksServices.reAssignTask, data)
    console.log('response', response)

    if (response.data.status === 'success') {
      yield put(reAssignTaskSuccess(response.data))
      toast.success('Task Successfully Reassigned')
      if (callback !== undefined) {
        callback()
      }
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

function* doAttachDocsToTask({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(tasksServices.attachDocsToTask, data)

    if (response.data.status === 'success') {
      yield put(attachDocsToTaskSuccess(response.data))
      toast.success('Document Successfully Attached to Task')
      // window.location.replace(`${window.location.origin}/tasks`)
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(error))
  }
}

function* doSaveWorkflowContainer({ payload }: any) {
  try {
    const { data, callback } = payload
    const response: ResponseGenerator = yield call(tasksServices.saveWorkflowContainer, data)

    if (response.data.status === 'success') {
      yield put(saveWorkflowContainerSuccess(response.data.data))
      toast.success('Container Task Successfully Saved')
      // toast.success('Remark Updated Successfully')
      // callback()
    }
  } catch (error) {
    const message = errorHandler(error)
    yield put(tasksApiError(message))
  }
}

export default function* tasksSaga() {
  yield takeLatest(GET_ALL_TASKS.REQUEST, getAllTasks)
  yield takeLatest(GET_ALL_TASKS_CSV.REQUEST, getAllTasksCsv)
  yield takeEvery(GET_TASKS.REQUEST, getTasks)
  yield takeLatest(GET_ALL_WORKFLOW_TASKS.REQUEST, getAllWorkflowTasks)
  yield takeEvery(GET_DELAYED_TASKS.REQUEST, getDelayedTasks)
  yield takeEvery(GET_WORKFLOW_TASK_BY_ID.REQUEST, getWorkflowTaskById)
  yield takeEvery(COMPLETE_WORKFLOW_TASK.REQUEST, doCompleteWorkflowTasks)
  yield takeEvery(ESCALATE_WORKFLOW_TASK.REQUEST, doEscalateWorkflowTasks)
  yield takeEvery(ATTACH_DOC_TO_TASK.REQUEST, doAttachDocsToTask)
  yield takeEvery(SAVE_WORKFLOW_CONTAINER.REQUEST, doSaveWorkflowContainer)
  yield takeEvery(REASSIGN_TASK.REQUEST, doReAssignTask)
}
