import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

//icons
import search from 'assets/icons/search.svg'
import tri from 'assets/icons/trianglee.svg'
import filter from 'assets/icons/Filter-grey.svg'
import ReportModal from './ReportModal'
import type { TableProps } from 'antd'
import { ReportShipmentTypeTableType, TableType } from 'types/reports/Shipments'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'
import ExportCsv from 'components/buttons/ExportCsv'

const ReportsAntTable = ({
  filteredShipmentReports,
  shipment_report_csv,
  total_shipment_report,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
  setFilterDisplay,
  filterObject,
  tab,
}: ReportShipmentTypeTableType) => {
  // const { , , , , , ,  } = props
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [page, setPage] = useState(parseInt(filterObject.page))
  const [showCompletionTasks, setShowCompletionTasks] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState('')

  console.log('filteredShipmentReports', filteredShipmentReports)

  const [data, setData] = useState(filteredShipmentReports)
  const [csvData, setCsvData] = useState<any>([
    {
      percentage_completion: 0,
      createdAt: '',
      updatedAt: '',
      shipment_details: {
        shipment_status: '',
        with_haulage: false,
        with_tracker: false,
        customs_brokerage: false,
        ocean_freight: false,
        air_freight: false,
        warehousing: false,
        bl_number: '',
        job_number: '',
        shipment_type: '',
        shipment_transport_type: '',
        createdAt: '',
        port_location: '',
      },
      containers: [
        {
          container_size: '',
        },
      ],
      containerCount: 0,
      company_details: [
        {
          company_name: '',
        },
      ],
      shipment_assignee_details: {
        remarks: '',
      },
      assigned_to_details: [
        {
          firstname: '',
          lastname: '',
          email: '',
        },
      ],
      actual_timeline: 0,
      completedTasks: 0,
      totalTasks: 0,
      current_timeline: '',
      completion_timeline: '',
      is_delayed: false,
      active_log: {
        shipment_status: '',
        remarks: '',
      },
      milestone_details: [
        {
          milestone_name: '',
        },
      ],
    },
  ])

  useEffect(() => {
    setData(filteredShipmentReports)
  }, [filteredShipmentReports])

  useEffect(() => {
    setPage(parseInt(filterObject.page))
  }, [filterObject])

  useEffect(() => {
    setCsvData(shipment_report_csv)
  }, [shipment_report_csv])

  const csvBookingData = csvData?.reverse().map((item) => ({
    'Service Type': getMultipleServiceCombinations(item?.shipment_details),
    'Shipment Type': item?.shipment_details?.shipment_type,
    'Shipment Assignee': `${item?.assigned_to_details?.map((data) => data?.firstname)} ${item?.assigned_to_details?.map((data) => data?.lastname)}`,
    'Company Name': item?.company_details?.map((data) => data?.company_name),
    'Job Number': item?.shipment_details?.job_number,
    'Current Milestone': item?.milestone_details?.map((data) => data?.milestone_name),
    'Status Report': item?.shipment_assignee_details?.remarks,
    'Port Location': item?.shipment_details?.port_location,
    'Container Size': item?.containers?.map((data) => `${data?.container_size} `),
    'Shipment Status': item?.shipment_details?.shipment_status,
    'Current Timeline': item?.current_timeline,
    'Completion Timeline': item?.completion_timeline,
    'Estimated Timeline': item?.actual_timeline,
    'Completion Percentage': `${Math.round((Number(item?.completedTasks) / Number(item?.totalTasks)) * 100)}%`,
  }))

  console.log('shipment_report_csv', shipment_report_csv)
  console.log('csvdata', csvData)

  const [selectedData, setSelectedData] = useState(null)

  const handleRowClick = (record) => {
    setIsOpen(true)
    setSelectedData(record)
  }

  const columns: TableProps<TableType>['columns'] = [
    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{getMultipleServiceCombinations(shipment_details)}</p>
          <p className='text-xs capitalize text-[#296FD8]'>#{shipment_details.bl_number ? shipment_details.bl_number : 'N/A'}</p>
        </div>
      ),
    },

    {
      title: 'Shipment Type',
      dataIndex: 'shipment_type',
      key: 'shipment_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{shipment_details.shipment_type ? shipment_details.shipment_type : 'N/A'} </p>
        </div>
      ),
    },
    {
      title: 'Shipment Assignee',
      dataIndex: 'assigned_to_details',
      key: 'assigned_to_details',
      // width: 150,
      render: (_, { assigned_to_details }) => (
        <>
          <p className='text-xs'>
            {`${assigned_to_details?.[0]?.firstname === undefined ? 'N/A' : assigned_to_details?.[0]?.firstname} ${
              assigned_to_details?.[0]?.lastname === undefined ? '' : assigned_to_details?.[0]?.lastname
            }`}
          </p>
          <p className='text-xs text-[#296FD8]'>{assigned_to_details?.[0]?.email === undefined ? 'N/A' : assigned_to_details?.[0]?.email}</p>
        </>
      ),
    },

    {
      title: 'Company Name',
      dataIndex: 'company_details',
      key: 'company_details',
      render: (_, { company_details }) => (
        <>
          <p className='text-xs capitalize'>{company_details?.[0]?.company_name ? company_details?.[0]?.company_name : 'N/A'} </p>
        </>
      ),
    },
    {
      title: 'Job Number',
      dataIndex: 'shipment_details',
      key: 'shipment_details',
      render: (_, { shipment_details }) => <p className='text-xs'>{shipment_details?.job_number ? shipment_details?.job_number : 'N/A'}</p>,
    },
    {
      title: 'Current Milestone',
      dataIndex: 'milestone_details',
      key: 'milestone_details',
      render: (_, { milestone_details }) => (
        // @ts-ignore
        <p className='text-xs capitalize'>{milestone_details?.[0]?.milestone_name ? milestone_details?.[0]?.milestone_name : 'N/A'}</p>
      ),
    },

    {
      title: 'Status Report',
      dataIndex: 'shipment_assignee_details',
      key: 'shipment_assignee_details',
      // width: 150,
      render: (_, { shipment_assignee_details }) => (
        <p className='text-xs capitalize'>{shipment_assignee_details?.remarks ? shipment_assignee_details?.remarks : 'N/A'}</p>
      ),
    },

    {
      title: 'Port Location',
      dataIndex: 'port_of_location',
      key: 'port_of_location',
      // width: 150,
      render: (_, { shipment_details }) => (
        <p className='text-xs capitalize'>{shipment_details.port_location ? shipment_details.port_location : 'N/A'}</p>
      ),
    },
    {
      title: 'Container Size',
      dataIndex: 'containers',
      key: 'containers',
      render: (_, { containers, containerCount }) => (
        <div className='flex gap-[2px]'>
          <p className='text-xs'>{containerCount ? containerCount : 'N/A'} </p>
          <p className='text-xs'> x</p>
          <p className='text-xs'>{containers[0]?.container_size ? containers[0]?.container_size : 'N/A'}</p>
        </div>
      ),
    },
  ]

  if (tab === 'Active') {
    columns.push({
      title: 'Current Timeline',
      dataIndex: 'current_timeline',
      key: 'current_timeline',
      render: (_, { current_timeline, actual_timeline }) => (
        // @ts-ignore
        <>
          <p className={Number(current_timeline.substring(0, 2)) > actual_timeline ? 'text-xs capitalize text-[#AD0013]' : 'text-xs capitalize'}>
            {current_timeline ? `${current_timeline}(s)` : 'N/A'}
          </p>
        </>
      ),
    })

    columns.splice(3, 0, {
      title: 'Activity Status',
      dataIndex: 'is_delayed',
      key: 'is_delayed',
      // width: 150,
      render: (_, { is_delayed }) => (
        <>
          {/* <p className='text-xs'>{is_delayed ? 'true' : 'false'}</p> */}
          {is_delayed ? (
            <div className='bg-[#FFBAB333] w-fit rounded-[23px] py-[4px] px-[8px] mx-auto'>
              <p className='text-[10px] text-[#AD0013] font-medium'>Delayed</p>
            </div>
          ) : (
            <div className='bg-[#99FF7D2E] w-fit rounded-[23px] py-[4px] px-[8px] mx-auto'>
              <p className='text-[10px] text-[#005D00] font-medium'>On Track</p>
            </div>
          )}
        </>
      ),
    })
  }

  if (tab === 'Completed') {
    columns.push({
      title: 'Completion Timeline',
      dataIndex: 'completion_timeline',
      key: 'completion_timeline',
      render: (_, { completion_timeline }) => (
        // @ts-ignore
        <>
          <p className='text-xs capitalize'>{completion_timeline ? `${completion_timeline}(s)` : 'N/A'}</p>
        </>
      ),
    })
  }

  if (tab === 'Active' || tab === 'Completed') {
    columns.push({
      title: 'Estimated Timeline',
      dataIndex: 'estimated_timeline',
      key: 'estimated_timeline',
      render: (_, { actual_timeline }) => (
        // @ts-ignore
        <>
          <p className='text-xs capitalize'>{actual_timeline ? `${actual_timeline} Day(s)` : 'N/A'}</p>
        </>
      ),
    })
  }

  columns.push({
    title: 'Completion',
    dataIndex: 'percentage_completion',
    key: 'percentage_completion',
    fixed: 'right',
    width: 200,
    render: (_, { _id, percentage_completion, completedTasks, totalTasks }) => (
      <div
        className='flex cursor-pointer'
        onMouseLeave={() => {
          setShowCompletionTasks(false)
          setRowId('')
        }}
        onMouseOver={() => {
          setShowCompletionTasks(true)
          setRowId(_id)
        }}
      >
        <div
          className={`h-1.5 bg-[#00861E] flex items-center rounded-lg`}
          style={{ width: `${Math.round((Number(completedTasks) / Number(totalTasks)) * 100)}%`, height: '20px', marginRight: '-10px', zIndex: 9 }}
        >
          <span
            className={`${Math.round((Number(completedTasks) / Number(totalTasks)) * 100) < 20 ? 'text-black' : 'white-text'} text-xs px-4`}
            style={{ fontSize: '12px', position: 'absolute' }}
          >
            {' '}
            {Math.round((Number(completedTasks) / Number(totalTasks)) * 100)}%
          </span>
        </div>
        <div
          className={`h-1.5 bg-[#F3F4F6] rounded-lg`}
          style={{ width: `${100 - Math.round((Number(completedTasks) / Number(totalTasks)) * 100)}%`, height: '20px' }}
        ></div>

        {/* on hover details */}
        {showCompletionTasks && rowId == _id && (
          <div className=' absolute right-[10px] bottom-[0] z-20  bg-[#1F2937] rounded-[8px] p-[8px] text-white text-xs'>
            <img src={tri} className='absolute left-[15px] top-[-7px]'></img>
            <p>
              {completedTasks} of {totalTasks} tasks done{' '}
            </p>
          </div>
        )}
      </div>
    ),
  })

  return (
    <div>
      <div className=' '>
        <div className='mb-[34px] flex justify-between items-center '>
          <div className='flex items-center gap-x-[16px]'>
            {/* search */}
            <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input w-full black-text w-[264px]'>
              <div>
                <img src={search} alt=''></img>
              </div>
              <input placeholder='Search Shipment Report' onChange={(e) => handleSearch(e.target.value)} />
            </div>

            {/* filter */}
            <div
              className='cursor-pointer px-4 flex items-center gap-x-1 border border-[#e5e7eb] rounded  h-[37.6px] mt-[-20px] mb-[-20px]'
              onClick={() => {
                setFilterDisplay(true)
              }}
            >
              {/* image */}
              <div>
                <img className='min-w-[16px]' src={filter} alt=''></img>
              </div>
              {/* filter */}
              <p className='text-xs text-[#9CA3AF]'>Filter</p>
            </div>
          </div>

          <div className='  desktop-only'>
            <ExportCsv csvBookingData={csvBookingData ? csvBookingData : csvData} name='shipment_report' />
          </div>
        </div>
      </div>
      <div>
        <Table
          //   @ts-ignore
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            total: total_shipment_report,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: page + 1,
            showSizeChanger: true,
            pageSizeOptions: ['10', '15', '20', '30'],
            onChange: (page, pageSize) => {
              handlePageChange(page - 1)
              handlePerRowsChange(pageSize, page - 1)
              // console.log("page", page - 1);
              // console.log("pageSize", pageSize);
            },
          }}
          scroll={{ x: 2000 }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
        {/* @ts-ignore */}
        {isOpen && <ReportModal isOpen={isOpen} setIsOpen={setIsOpen} reportData={selectedData} />}
      </div>
    </div>
  )
}

export default ReportsAntTable
