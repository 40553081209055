import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from 'routes/Approutes'

import type { TableProps } from 'antd'
import { TableType, WorkflowConfigurationTableType, WorkflowType } from 'types/workflowConfiguration/WorkflowConfiguration'

//redux
import { useSelector } from 'react-redux'

//icons
import dot from 'assets/icons/dots.svg'
import norate from 'assets/icons/norate.svg'
import dots from 'assets/icons/three-dots.svg'
import divider from 'assets/icons/grey-divider.svg'

//components
import WorkflowConfigurationModal from 'components/workflowConfiguration/WorkflowConfigurationModal'

//libraries
import { Table, Dropdown, MenuProps } from 'antd'
import moment from 'moment'

//helpers
import { getJobCombination } from 'helpers/jobCombination'

//icons
import search from 'assets/icons/search.svg'
import filter from 'assets/icons/Filter-grey.svg'
import cross from 'assets/icons/cross.svg'

//components
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { Admin } from 'types/dashboard/Dashboard'
import ExportCsv from 'components/buttons/ExportCsv'

const WorkflowConfigurationTable = ({
  filteredWorkflow,
  workflows_csv,
  total_workflow,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
  setFilterDisplay,
  filterObject,
}: WorkflowConfigurationTableType) => {
  let admin_data: Admin = useSelector((state: any) => state.auth.admin_data)
  let local_admin: Admin = localStorage.getItem('admin_data') ? JSON.parse(localStorage.getItem('admin_data')!) : null
  admin_data = admin_data ? admin_data : local_admin

  const navigate = useNavigate()

  const actionref = useRef<HTMLDivElement>(null)
  const [isChosen, setIsChosen] = useState<object>({})

  const [selectWorkflow, setSelectedWorkflow] = useState<WorkflowType | null>(null)
  console.log('selected>>>', selectWorkflow)

  const [page, setPage] = useState(parseInt(filterObject.page))
  const [startNum, setStartNum] = useState(1)
  const [endNum, setEndNum] = useState(10)
  const [csvData, setCsvData] = useState<any>([
    {
      is_active: false,
      is_draft: false,
      workflow_name: '',
      department: '',
      shipment_type: '',
      createdAt: '',
      workflow_created_by: [
        {
          firstname: '',
          lastname: '',
        },
      ],
      process_details: [
        {
          ocean_freight: false,
          customs_brokerage: false,
          haulage: false,
          tracking: false,
          warehousing: false,
          air_freight: false,
          port_location: '',
        },
      ],
    },
  ])

  useEffect(() => {
    setCsvData(workflows_csv)
  }, [workflows_csv])

  const csvBookingData = csvData?.reverse().map((item) => ({
    'Workflow Name': item?.workflow_name,
    Department: item?.department,
    'Job Combination': getJobCombination(item?.process_details, item?.shipment_type),

    Status: item?.is_active ? 'active' : 'inactive',
    'Port Location': item?.process_details?.map((data) => data?.port_location),
    'Date Created': moment(item?.createdAt).format('ddd, Do MMMM YYYY'),
  }))

  useEffect(() => {
    setPage(parseInt(filterObject.page))
  }, [filterObject])

  const items: MenuProps['items'] =
    admin_data.role === 'super_admin'
      ? [
          { key: '1', label: <span className='text-sm black-text-3'>Edit Details</span> },
          { key: '2', label: <span className='text-sm black-text-3'>View Details</span> },
        ]
      : [{ key: '2', label: <span className='text-sm black-text-3'>View Details</span> }]

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '1') {
      navigate(`/workflow-configuration/create/${selectWorkflow?._id}`)
    } else if (key === '2') {
      navigate(`/workflow-configuration/details/${selectWorkflow?._id}`)
    }
  }

  const [showConfigurationModal, setShowConfigurationModal] = useState(false)
  const closeModal = () => {
    setShowConfigurationModal(false)
  }

  const columns: TableProps<TableType>['columns'] = [
    {
      title: 'WORKFLOW NAME',
      dataIndex: 'workflow_name',
      key: 'workflow_name',
      render: (_, { workflow_name }) => <>{workflow_name ? <span className='black-text'>{workflow_name}</span> : 'N/A'}</>,
    },
    {
      title: 'DEPARTMENT',
      dataIndex: 'department',
      key: 'department',
      render: (_, { department }) => <>{department ? <span className='capitalize black-text'>{department}</span> : 'N/A'}</>,
    },
    {
      title: 'JOB COMBINATION',
      dataIndex: 'job_combination',
      key: 'job_combination',
      render: (_, { process_details, shipment_type }) => (
        <>{process_details ? <span className='black-text capitalize'>{getJobCombination(process_details, shipment_type)}</span> : 'N/A'}</>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'is_draft',
      render: (_, { is_draft, is_active }) => (
        <>
          {!is_active && !is_draft ? (
            <p className='black-text capitalize bg-[#E5E7EB] text-sm rounded-lg py-1.5 w-[68px] text-center black-text-3'>Inactive</p>
          ) : is_draft ? (
            <p className='black-text capitalize bg-[#E5E7EB] text-sm rounded-lg py-1.5 w-[68px] text-center black-text-3'>Draft</p>
          ) : (
            <p className='capitalize bg-[#d0f5ff66] text-sm rounded-lg py-1.5 w-[68px] text-center text-[#296FD8]'>Active</p>
          )}
        </>
      ),
    },
    {
      title: 'PORT LOCATION',
      dataIndex: 'port_location',
      key: 'is_draft',
      render: (_, { process_details }) => (
        <>{process_details ? <span className='black-text capitalize'>{process_details?.[0]?.port_location}</span> : 'N/A'}</>
      ),
    },
    {
      title: 'CREATOR',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (_, { workflow_created_by }) => (
        <>
          {workflow_created_by?.length > 0 ? (
            <span className='black-text capitalize'>{workflow_created_by?.[0]?.firstname + ' ' + workflow_created_by?.[0]?.lastname}</span>
          ) : (
            'N/A'
          )}
        </>
      ),
    },
    {
      title: 'DATE CREATED',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt }) => <>{createdAt ? <span className='black-text'>{moment(createdAt).format('ddd, Do MMMM YYYY')}</span> : 'N/A'}</>,
    },
    {
      title: '',
      dataIndex: 'operation',
      fixed: 'right',
      width: '100px',
      key: 'operation',
      render: (_, { _id }) => (
        <div onClick={() => setSelectedWorkflow(filteredWorkflow.filter((item) => item._id === _id)?.[0])}>
          <Dropdown className='cursor-pointer' menu={{ items, onClick }} trigger={['click']}>
            <img src={dot} alt='' />
          </Dropdown>
        </div>
      ),
    },
  ]

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu, then close the menu
      if (isChosen && actionref.current && !actionref.current.contains(e.target)) {
        setIsChosen({})
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isChosen])

  const MobileActions = () => (
    <div className='bg-white rounded-lg px-1 py-1 shadow-lg'>
      <p
        onClick={() => navigate(`/workflow-configuration/create/${selectWorkflow?._id}`)}
        className='text-sm py-2 black-text-3 font-light px-4 cursor-pointer hover:bg-gray-100 rounded'
      >
        Edit Details
      </p>
      <p
        onClick={() => navigate(`/workflow-configuration/details/${selectWorkflow?._id}`)}
        className='text-sm py-2 black-text-3 font-light px-4 cursor-pointer hover:bg-gray-100 rounded'
      >
        View Details
      </p>
    </div>
  )

  return (
    <div>
      <div className='mb-8'>
        <div className='flex flex-col gap-y-6 md:gap-y-0 md:flex-row md:justify-between md:items-center'>
          <div className='flex items-center gap-x-[16px] '>
            <div className='flex items-center gap-x-1 form-input px-4 py-[7px] custom-input w-full black-text md:w-[264px]'>
              <img src={search} alt=''></img>
              <input placeholder='Search for workflow' className='w-full' onChange={(e) => handleSearch(e.target.value, 'search')} />
            </div>

            <ExportCsv csvBookingData={csvBookingData ? csvBookingData : csvData} name='workflow' />
          </div>
          {admin_data.role === 'super_admin' && (
            <div className='md:ml-auto'>
              <PrimaryButtons
                title='Add New Configuration'
                style={{ padding: '10px 0px', backgroundColor: '#007003' }}
                onClick={() => setShowConfigurationModal(true)}
                disabled={false}
                loading={false}
                icon={cross}
              />
            </div>
          )}
        </div>
      </div>
      <div className='desktop-only'>
        <Table
          columns={columns}
          dataSource={filteredWorkflow}
          pagination={{
            defaultPageSize: 10,
            total: total_workflow,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: page + 1,
            showSizeChanger: true,
            pageSizeOptions: ['10', '15', '20', '30'],
            onChange: (page, pageSize) => {
              handlePageChange(page - 1)
              handlePerRowsChange(pageSize, page - 1)
            },
          }}
          scroll={{ x: 1280 }}
          locale={{
            emptyText: (
              <div className='flex flex-col justify-center items-center my-5'>
                <div>
                  <img src={norate} alt='noconfigurations' />
                </div>
                <p className='black-text-4 text-xl mt-12'>No Available Configurations</p>
                <p className='grey-text-1 text-sm font-light mt-3 max-w-[264px] text-center'>
                  Sorry there are no configurations, create a <span className='green-text'>new configuration.</span>{' '}
                </p>
              </div>
            ),
          }}
        />
      </div>
      {/* the caveat with the else of the ternary operator is that if this filteredWorkflow when searched doesn't return any value, 
      it shows an element which doesn't neccessary cover for the fact that there's no data, it prompts you to create a new workflow (talk to design about this) */}
      <div className='mobile-only'>
        {filteredWorkflow?.length > 0 ? (
          filteredWorkflow.map((item, idx) => (
            <div
              key={item?._id}
              className={`border-solid border-[1px] border-[#E5E7EB] ${idx === 0 ? 'rounded-t' : 'border-t-[0px]'} ${
                idx === filteredWorkflow?.length - 1 || filteredWorkflow?.length === 1 ? 'rounded-b' : ''
              } p-6`}
            >
              <div className='flex justify-between items-start relative'>
                <div className='flex items-center gap-x-2 black-text-3 '>
                  <p className='capitalize'>{item.shipment_type}</p>
                  <img src={divider} className='' alt='' />
                  <p>{getJobCombination(item.process_details)}</p>
                </div>
                <span
                  className='cursor-pointer '
                  onClick={() => {
                    setIsChosen((prev) => ({ ...prev, [item._id]: !prev[item._id] }))
                    setSelectedWorkflow(item)
                  }}
                >
                  <img src={dots} alt='' />
                </span>
                {isChosen[item?._id] && (
                  <div ref={actionref} className='absolute right-0 top-6'>
                    <MobileActions />
                  </div>
                )}
              </div>
              <p className='grey-text font-light text-sm mt-1'>{item?.createdAt ? moment(item?.createdAt).format('ddd, Do MMMM YYYY') : 'N/A'}</p>
              <p className='text-[#4B83F0] text-sm py-2 px-3 mt-10 border-[#D0F5FF] border-solid border-[1px] rounded-full w-fit capitalize'>
                {item?.department}
              </p>
            </div>
          ))
        ) : (
          <div className='flex flex-col justify-center items-center mt-40'>
            <div>
              <img src={norate} alt='noconfigurations' />
            </div>
            <p className='black-text-4 text-xl mt-12'>No Available Configurations</p>
            <p className='grey-text-1 text-sm font-light mt-3 max-w-[264px] text-center'>
              Sorry there are no configurations, create a <span className='green-text'>new configuration.</span>{' '}
            </p>
          </div>
        )}
        <div>
          {filteredWorkflow?.length > 0 && (
            <div className='mt-4 mx-7 py-6 flex justify-between items-center'>
              {/* left */}
              {page === 0 ? (
                <div className='rotate-180 pt-2' onClick={() => {}}>
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#d6dfed'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className='rotate-180'
                  onClick={() => {
                    handlePageChange(page)
                    setPage(page - 1)
                    setStartNum(startNum - 10)
                    setEndNum(endNum - 10)
                  }}
                >
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#374151'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              )}

              {/* middle */}
              <p>
                {startNum} - {filteredWorkflow.length >= 10 ? endNum : endNum - (10 - (filteredWorkflow.length % 10))} of {total_workflow}
              </p>

              {/* right */}
              {page >= Math.ceil(total_workflow / 10 - 1) ? (
                <div className='' onClick={() => {}}>
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#d6dfed'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              ) : (
                <div
                  className=''
                  onClick={() => {
                    handlePageChange(page + 2)
                    setPage(page + 1)
                    setStartNum(startNum + 10)
                    setEndNum(filteredWorkflow.length >= 10 ? endNum + 10 : endNum - (10 - (filteredWorkflow.length % 10)))
                  }}
                >
                  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.957 2.917 9.04 7.001l-4.083 4.083'
                      stroke='#374151'
                      stroke-width='1.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <WorkflowConfigurationModal isOpen={showConfigurationModal} closeModal={closeModal} />
    </div>
  )
}

export default WorkflowConfigurationTable
