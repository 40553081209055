import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Layout from 'components/layout/Layout'
import PageLoading from 'components/partials/pageLoading'

//components
import TasksFilters from 'components/tasks/TasksFilters'
import TasksHeader from 'components/tasks/TasksHeader'
import TasksColumn from 'components/tasks/TasksColumn'
import { getAllTasks, getDelayedWorkflowTasks, getTasks } from 'store/actions'
import { SingleTaskProp } from 'types/tasks/Tasks'

interface TasksProps {
  loading: boolean
  admin_tasks: any
  tasks: SingleTaskProp[]
  delayed_workflow_tasks: SingleTaskProp[]
  getTasks: () => {
    type: string
    payload: null
  }
  getAllTasks: () => {
    type: string
    payload: null
  }
  getDelayedWorkflowTasks: (query: string) => {
    type: string
    payload: {
      query: string
    }
  }
}

const Tasks = ({ loading, getAllTasks, getTasks, admin_tasks, delayed_workflow_tasks, tasks, getDelayedWorkflowTasks }: TasksProps) => {
  console.log('Tasks>>>', tasks)

  const [status, setStatus] = useState<{ status: string; color: string }>({ status: 'open tasks', color: '#4B83F0' })
  const [shipmentType, setShipmentType] = useState('')
  const [time, setTime] = useState<'all time' | 'last 24 hours' | 'last week' | 'last month' | 'last year' | ''>('')

  const [filterObject, setFilterObject] = useState({ status: 'delayed' })

  const [openTasks, setOpenTasks] = useState<SingleTaskProp[] | []>([])
  const [incomingTasks, setIncomingTasks] = useState<SingleTaskProp[] | []>([])
  const [completedTasks, setCompletedTasks] = useState<SingleTaskProp[] | []>([])
  const [delayedTasks, setDelayedTasks] = useState<SingleTaskProp[] | []>([])

  const fetchDelayedWorkflowTasks = () => {
    const filterString = new URLSearchParams(filterObject).toString()
    getDelayedWorkflowTasks(filterString)
  }

  useEffect(() => {
    fetchDelayedWorkflowTasks()
  }, [filterObject])

  useEffect(() => {
    getTasks()
  }, [])

  console.log('admin tasks>>>', admin_tasks)
  // console.log('delayed_workflow_tasks', delayed_workflow_tasks)

  useEffect(() => {
    setOpenTasks([...admin_tasks?.filter((task) => task?.status === 'on-track'), ...delayed_workflow_tasks])
    // setOpenTasks([...admin_tasks?.filter((task) => task?.status === 'on-track')])
    console.log('openTasks>>>', openTasks)
    setIncomingTasks(admin_tasks?.filter((task) => task?.status === 'incoming'))
    setCompletedTasks(admin_tasks?.filter((task) => task?.status === 'completed'))
    setDelayedTasks([...delayed_workflow_tasks])
  }, [admin_tasks])

  console.log('completed Tasks>>>', completedTasks)

  // filter by shipment type
  useEffect(() => {
    setOpenTasks([
      ...admin_tasks?.filter((task) => task?.status === 'on-track' && task?.shipment_details?.shipment_type === shipmentType),
      ...delayed_workflow_tasks.filter((task) => task?.shipment_details?.shipment_type === shipmentType),
    ])
    // console.log('openTasks>>>', openTasks)
    setIncomingTasks(admin_tasks?.filter((task) => task?.status === 'incoming' && task?.shipment_details?.shipment_type === shipmentType))
    setCompletedTasks(admin_tasks?.filter((task) => task?.status === 'completed' && task?.shipment_details?.shipment_type === shipmentType))
  }, [shipmentType])

  console.log('completedTasks>>', completedTasks)
  return (
    <Layout>
      <main className='px-4 lg:pt-10 lg:px-10'>
        {loading ? (
          <PageLoading title='tasks' />
        ) : (
          <>
            <h1 className='black-text-4 text-2xl font-medium'>Assigned Tasks</h1>
            <div className='mt-6 mb-8 '>
              <TasksFilters shipmentType={shipmentType} setShipmentType={setShipmentType} status={status} setStatus={setStatus} />
            </div>
            <div className='h-[calc(100vh-236px)]'>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-6 p-3'>
                <div className='grid grid-cols-1 rounded-2xl bg-[#F9FAFB] lg:grid-cols-2 gap-x-6 p-5 lg:max-w-[650px]'>
                  <div className={`${status?.status === 'open tasks' ? 'block' : 'hidden lg:block'}`}>
                    <TasksHeader text='Open Tasks' color='#296FD8' number={openTasks?.length} />
                    <div className='overflow-y-scroll scrollbar'>
                      <TasksColumn tasks={openTasks} />
                    </div>
                  </div>
                  <div className={`${status?.status === 'incoming tasks' ? '' : 'hidden lg:block'}`}>
                    <TasksHeader text='Incoming Tasks' color='#DB8900' number={incomingTasks?.length} />
                    <div className='overflow-y-scroll scrollbar'>
                      <TasksColumn tasks={incomingTasks} />
                    </div>
                  </div>
                </div>
                <div className={`${status?.status === 'completed tasks' ? '' : 'hidden lg:block'}`}>
                  <div className='bg-[#F9FAFB] p-5 rounded-2xl'>
                    <TasksHeader text='Completed Tasks' color='#139C33' number={completedTasks?.length} />
                    <div className='overflow-y-scroll scrollbar'>
                      <TasksColumn
                        iscompletedTasks
                        tasks={completedTasks.sort((a, b) => new Date(b.completion_date).getTime() - new Date(a.completion_date).getTime())}
                      />
                    </div>
                  </div>
                </div>
                <div className={`${status?.status === 'delayed tasks' ? '' : 'hidden lg:block'}`}>
                  <div className='bg-[#F9FAFB] p-5  rounded-2xl'>
                    <TasksHeader text='Delayed Tasks' color='#FF0000' number={delayedTasks?.length} />
                    <div className='overflow-y-scroll scrollbar'>
                      <TasksColumn tasks={delayedTasks} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </Layout>
  )
}

const mapStateToProps = (state: any) => {
  const { error, loading, tasks, admin_tasks, delayed_workflow_tasks } = state.tasks

  return { error, loading, tasks, admin_tasks, delayed_workflow_tasks }
}

export default connect(mapStateToProps, { getAllTasks, getTasks, getDelayedWorkflowTasks })(Tasks)
