import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import { useForm } from 'react-hook-form'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import { escalateWorkflowTask, getWorkflowTaskById } from 'store/actions'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    transform: 'translate(-50%, -50%)',
    width: '434px',
    height: '485px',
    borderRadius: '10px',
    border: '0.01px solid #888',
    backgroundColor: '#F3F4F6',
  },
  overlay: {
    backgroundColor: 'rgba(6, 24, 2, 0.55)',
    zIndex: '99999999999',
  },
}

const EscalateModal = (props) => {
  const { escalateModal, setEscalateModal, task_data, workflow_task_data, single_workflow_task_data, escalateWorkflowTask } = props
  console.log('row data', task_data)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const newData = {
      id: task_data?.[0]?._id,
      reason: data?.reason,
    }
    // console.log('newdata', newData)
    escalateWorkflowTask(newData)
  }

  const task_id = task_data?.[0]?._id

  console.log('escalate_task_data', task_data)

  console.log('escalate_task-id', task_id)

  useEffect(() => {
    getWorkflowTaskById(task_id)
  }, [task_id])

  console.log('single_workflow_task_data', single_workflow_task_data)

  console.log('escalateModal', task_data)

  return (
    <Modal
      isOpen={escalateModal}
      style={customStyles}
      contentLabel='Task Management Modal'
      onRequestClose={() => {
        setEscalateModal(false)
      }}
    >
      <div className='flex items-center p-[24px]'>
        <div>
          <p className='text-[16px text-[#1F2937]]'>Escalate Task</p>
          <p className='text-[12px] text-[#6B7280]'>Please provide a reason for escalation</p>
        </div>
        <div className='ml-auto'>
          <i className='ion-ios-close py-1 text-3xl black-text cursor-pointer' onClick={() => setEscalateModal(false)}></i>
        </div>
      </div>
      <div className='bottom-divider'></div>
      <div className='p-[24px]'>
        <p className='text-[14px] text-[#6B7280]'>Escalation Contact</p>
        <div className='solid-br bg-[#E5E7EB]'>
          <p className='py-[12px] px-[16px] text-[14px] text-[#6B7280]'>
            {workflow_task_data?.[0]?.escalation_contact_details?.firstname} {workflow_task_data?.[0]?.escalation_contact_details?.lastname}
          </p>
        </div>

        <form className='pt-4' onSubmit={handleSubmit(onSubmit)}>
          <CustomTextarea
            control={control}
            name={'reason'}
            id={'reason'}
            label={'Reason'}
            placeholder={'Add a comment or update'}
            isRequired={false}
            errors={errors}
            isDisabled={false}
            defaultValue={''}
            icon=''
            // style={{ height: '88px' }}
          />
          <div className='py-6 text-center'>
            <button
              type='submit'
              className='bg-[#8A0000] transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-white text-sm font-normal rounded w-full mb-3'
            >
              Yes, I want to escalate this task
            </button>
            <button
              type='submit'
              className='bg-[#F9FAFB] transition-all duration-150 hover:scale-95 active:scale-95 py-3 text-[#8A0000] text-sm font-normal rounded w-full mb-3'
              onClick={() => setEscalateModal(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

// export default EscalateModal

const mapStateToProps = (state: any) => {
  const { workflow_task_data, single_workflow_task_data, loading } = state.tasks
  return { workflow_task_data, single_workflow_task_data, loading }
}

export default connect(mapStateToProps, { escalateWorkflowTask, getWorkflowTaskById })(EscalateModal)
