export const taskbgHelper = (status, isDelayed, isBlocked) => {
  if (status === 'delayed') {
    return 'bg-[#FFE4E1] text-[#8A0000]'
  }
  if (status === 'on-track' && (status !== 'delayed' || !isBlocked)) {
    return `text-[#4B83F0] bg-[#d0f5ff4d]`
  }
  if (status === 'incoming') {
    return `text-[#DB8900] bg-[#ffe75d1a]`
  }
  if (status === 'completed') {
    return `text-[#059C01] bg-[#059c010d]`
  }
}
