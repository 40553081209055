import React from 'react'

import littlecross from 'assets/icons/littlecross.svg'

import MilestoneForm from 'components/workflowConfiguration/MilestoneForm'
import PrimaryButtons from 'components/buttons/PrimaryButtons'
import { useWorkflowConfigurationContext } from 'pages/reports/shipments/workflowConfiguration/CreateWorkflowConfiguration'

const MilestoneDetails = () => {
  const { selectedMilestoneId, addMilestoneTask } = useWorkflowConfigurationContext()

  return (
    <div className=''>
      <p className='green-text-4 px-10 py-6 font-medium bg-[#F9FAFB] desktop-only'>Milestone Details</p>
      <div className='lg:h-[calc(100vh-217px)] overflow-y-scroll'>
        <div className='lg:px-10 py-6'>
          <MilestoneForm />
        </div>
        <div className='lg:px-10 pb-6'>
          <PrimaryButtons
            title='Add a new task'
            disabled={false}
            icon={littlecross}
            style={{ backgroundColor: '#37b2481a', color: '#00861E' }}
            onClick={() => addMilestoneTask(selectedMilestoneId)}
          />
        </div>
      </div>
    </div>
  )
}

export default MilestoneDetails
