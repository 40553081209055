import PrimaryButtons from 'components/buttons/PrimaryButtons'
import CustomTextarea from 'components/textInputs/CustomTextarea'
import React from 'react'
import { useForm } from 'react-hook-form'
import Moment from 'react-moment'
import { ReportShipmentType, ShipmentModalType } from 'types/reports/Shipments'
import AdminComp from './AdminComp'
interface Props {
  shipment_report_data: any
  onSubmit: (data: any) => void
  loading: boolean
}
const EscalationRemarkComp = ({ shipment_report_data, onSubmit, loading }: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()
  const assignee_members = shipment_report_data?.milestones?.flatMap(({ tasks }) =>
    tasks.flatMap(({ assignee_details }) => assignee_details?.map(({ firstname, lastname }) => ({ firstname, lastname })))
  )
  return (
    <div className='col-span-1 '>
      <div className=' p-[24px]'>
        <div className=' flex flex-col gap-[16px]'>
          <p className='text-[#1F2937] font-medium  text-[10px]'>ESCALATION CONTACT</p>

          <AdminComp
            firstname={shipment_report_data?.escalation_contact?.[0]?.firstname}
            lastname={shipment_report_data?.escalation_contact?.[0]?.lastname}
          />
        </div>
        <div className='w-full h-[1px] bg-[#F3F4F6] my-[16px]'></div>

        <div className='flex flex-col gap-[16px]'>
          <p className='text-[#6B7280]  font-medium text-[10px]'>MEMBERS INVOLVED</p>
          {assignee_members &&
            (() => {
              // Create a map to count occurrences of each name
              const nameCountMap = assignee_members.reduce((acc, assignee) => {
                const fullName = `${assignee?.firstname} ${assignee?.lastname}`
                acc[fullName] = (acc[fullName] || 0) + 1
                return acc
              }, {})

              // Render the names with the count appended
              return assignee_members.map((assignee, index) => {
                const fullName = `${assignee?.firstname} ${assignee?.lastname}`
                const count = nameCountMap[fullName]

                // Only render each name once, and append the count
                if (nameCountMap[fullName]) {
                  nameCountMap[fullName] = 0 // Set to 0 to prevent rendering duplicates
                  return (
                    <div key={index} className=''>
                      <AdminComp firstname={assignee?.firstname} lastname={assignee?.lastname} count={count} />
                    </div>
                  )
                } else {
                  return null
                }
              })
            })()}
        </div>
      </div>
      <div className='w-full h-[1px] bg-[#F3F4F6] mt-[16px]'></div>
      <div className=' p-[24px]'>
        <p className='text-[#1F2937] font-medium text-[10px]'>STATUS REMARK</p>
        {shipment_report_data?.remark_details?.[0]?.remarks === undefined ? (
          <></>
        ) : (
          <>
            <div className=' flex flex gap-[12px] mt-[27px]'>
              <div className=''>
                <span className='rounded-full bg-[#D0F5FF] w-[25px] h-[25px] text-xs font-medium flex flex-col items-center justify-center'>
                  {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.firstname?.charAt(0)}
                  {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.lastname?.charAt(0)}
                </span>
              </div>
              <div className=' flex flex-col gap-[6px]'>
                <span className='text-[12px] font-medium'>
                  {' '}
                  {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.firstname}{' '}
                  {shipment_report_data?.remark_details?.[0]?.updated_by_details?.[0]?.lastname}
                </span>
                <p className='text-[#4B5563] text-[12px]'> {shipment_report_data?.remark_details?.[0]?.remarks}</p>
                <p className=' text-[#9CA3AF] text-[10px]'>
                  <Moment format='hh:mm a, MMMM DD YYYY'>{shipment_report_data?.createdAt}</Moment>
                </p>
              </div>
            </div>
          </>
        )}
        <form className='pt-14' onSubmit={handleSubmit(onSubmit)}>
          <CustomTextarea
            control={control}
            name={'remarks'}
            id={'remarks'}
            label={''}
            placeholder={'Add a comment or update'}
            isRequired={false}
            errors={errors}
            isDisabled={false}
            defaultValue={''}
            icon=''
          />
          <div className='text-end'>
            <PrimaryButtons title={'Comment'} style={{ width: 'max-content' }} disabled={false} loading={loading} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default EscalationRemarkComp
