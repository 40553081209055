import React from 'react'
import search from 'assets/icons/search.svg'
import { handleSearch } from 'helpers/tables/fetchTableData'

const SearchComp = (props: any) => {
  const { filterObject, setFilterObject } = props
  return (
    <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input black-text w-full sm:w-[300px] '>
      <div>
        <img src={search} alt=''></img>
      </div>
      <input placeholder='Search Shipment Tasks' onChange={(e) => handleSearch(e.target.value, setFilterObject, filterObject)} />
    </div>
  )
}

export default SearchComp
