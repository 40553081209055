import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

//icons
import search from 'assets/icons/search.svg'
import filter from 'assets/icons/Filter-grey.svg'
import TaskModal from 'components/reports/tasks/TaskModal'
import type { TableProps } from 'antd'
import { ReportTaskTypeTableType, TableType } from 'types/reports//Tasks'
import { getMultipleServiceCombinations } from 'helpers/getMultipleServiceCombinations'
import ExportCsv from 'components/buttons/ExportCsv'

// function TasksAntTable(props: any) {
const TasksAntTable = ({
  filteredShipmentTasks,
  task_report_csv,
  total_tasks,
  handlePerRowsChange,
  handlePageChange,
  handleSearch,
  setFilterDisplay,
  filterObject,
  tab,
}: ReportTaskTypeTableType) => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const [page, setPage] = useState(parseInt(filterObject.page))
  const [data, setData] = useState(filteredShipmentTasks)
  const [selectedData, setSelectedData] = useState(null)
  const [csvData, setCsvData] = useState<any>([
    {
      status: '',
      name: '',
      shipment_details: {
        with_haulage: false,
        with_tracker: false,
        customs_brokerage: false,
        ocean_freight: false,
        air_freight: false,
        warehousing: false,
        shipment_type: '',
        shipment_transport_type: '',
      },
      milestone_details: {
        milestone_name: '',
      },
      assignee_details: {
        firstname: '',
        lastname: '',
      },

      completion_timeline: '',
      actual_timeline: '',
      current_timeline: '',
    },
  ])

  useEffect(() => {
    setCsvData(task_report_csv)
  }, [task_report_csv])

  const csvBookingData = csvData?.reverse().map((item) => ({
    'Task Name': item?.name,
    'Service Type': getMultipleServiceCombinations(item?.shipment_details),
    'Shipment Type': item?.shipment_details?.shipment_type,
    'Task Assignee': `${item?.assignee_details?.firstname} ${item?.assignee_details?.firstname}`,
    'Milestone Name': item?.milestone_details?.milestone_name,
    Status: item?.status,
    'Current Timeline': item?.current_timeline,
    'Completion Timeline': item?.completion_timeline,
    'Actual Timeline': item?.actual_timeline,
  }))

  useEffect(() => {
    setData(filteredShipmentTasks)
  }, [filteredShipmentTasks])

  useEffect(() => {
    setPage(parseInt(filterObject.page))
  }, [filterObject])

  const handleRowClick = (record) => {
    setIsOpen(true)
    setSelectedData(record)
  }

  console.log('task_report_csv', task_report_csv)

  const columns: TableProps<TableType>['columns'] = [
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
      // width: 150,
      render: (_, { name }) => (
        <div>
          <p className='text-xs capitalize'>{name}</p>
        </div>
      ),
    },

    // {
    //   title: 'Task Name',
    //   dataIndex: 'milestone_details',
    //   key: 'milestone_details',
    //   // width: 150,
    //   render: (_, { milestone_details }) => (
    //     <div>
    //       <p className='text-xs capitalize'>{milestone_details?.milestone_name}</p>
    //     </div>
    //   ),
    // },

    {
      title: 'Service Type',
      dataIndex: 'service_type',
      key: 'service_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{getMultipleServiceCombinations(shipment_details)}</p>
          <p className='text-xs capitalize text-[#296FD8]'>#{shipment_details.bl_number ? shipment_details.bl_number : 'N/A'}</p>
        </div>
      ),
    },

    {
      title: 'Shipment Type',
      dataIndex: 'shipment_type',
      key: 'shipment_type',
      render: (_, { shipment_details }) => (
        <div>
          <p className='text-xs capitalize'>{shipment_details.shipment_type ? shipment_details.shipment_type : 'N/A'} </p>
        </div>
      ),
    },

    {
      title: <>{tab === 'Ongoing' ? 'Milestone' : 'Completion Timeline'}</>,
      dataIndex: '',
      key: '',
      render: (_, { completion_timeline, milestone_details }) => (
        <>
          {tab === 'Ongoing' ? (
            <p className='text-xs capitalize'>{milestone_details?.milestone_name}</p>
          ) : (
            <p className='text-xs capitalize'>{completion_timeline ? completion_timeline : 'N/A'}</p>
          )}
        </>
      ),
    },

    {
      title: <> {tab === 'Ongoing' ? 'Current Timeline' : 'Actual Timeline'}</>,
      dataIndex: '',
      key: '',
      render: (_, { actual_timeline, current_timeline }) => (
        <>
          {tab === 'Ongoing' ? (
            <p className='text-xs capitalize'>{current_timeline ? current_timeline : 'N/A'}</p>
          ) : (
            <p className='text-xs capitalize'>{actual_timeline ? actual_timeline : 'N/A'}</p>
          )}
        </>
      ),
    },

    {
      title: 'Task Assignee',
      dataIndex: 'assignee_details',
      key: 'assignee_details',
      // width: 150,
      render: (_, { assignee_details }) => (
        <>
          <p className='text-xs'>
            {assignee_details?.firstname ? assignee_details?.firstname : 'N/A'}&nbsp;
            {assignee_details?.lastname ? assignee_details?.lastname : 'N/A'}
          </p>
        </>
      ),
    },

    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   render: (_, { status }) => (
    //     <>
    //       {status === 'completed' ? (
    //         <span className='rounded-full bg-[#059C010D] text-[#059C01] p-2 text-xs font-light'>{status}</span>
    //       ) : status === 'cancelled' ? (
    //         <span className='rounded-full bg-light-red red-text p-2 text-xs font-light capitalize'>{status}</span>
    //       ) : status === 'on-track' ? (
    //         <span className='rounded-full bg-yellow yellow-text p-2 text-xs font-light capitalize'>{status}</span>
    //       ) : status === 'incoming' ? (
    //         <span className='rounded-full bg-purple purple-text p-2 text-xs font-light capitalize'>{status}</span>
    //       ) : status === 'delayed' ? (
    //         <span className='rounded-full background-blue blue-text p-2 text-xs font-light capitalize'>{status}</span>
    //       ) : (
    //         <></>
    //       )}
    //     </>
    //   ),
    // },
  ]
  if (tab === 'Ongoing') {
    columns.push({
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <>
          {status === 'completed' ? (
            <span className='rounded-full bg-[#059C010D] text-[#059C01] p-2 text-xs font-light'>{status}</span>
          ) : status === 'cancelled' ? (
            <span className='rounded-full bg-light-red red-text p-2 text-xs font-light capitalize'>{status}</span>
          ) : status === 'on-track' ? (
            <span className='rounded-full bg-yellow yellow-text p-2 text-xs font-light capitalize'>{status}</span>
          ) : status === 'incoming' ? (
            <span className='rounded-full bg-purple purple-text p-2 text-xs font-light capitalize'>{status}</span>
          ) : status === 'delayed' ? (
            <span className='rounded-full background-blue blue-text p-2 text-xs font-light capitalize'>{status}</span>
          ) : (
            <></>
          )}
        </>
      ),
    })
  }

  return (
    <div>
      <div className='mb-1'>
        <div className='mb-[34px] flex justify-between items-center'>
          <div className='flex items-center gap-x-[16px]'>
            {/* search */}
            <div className='flex items-center gap-x-1 form-input px-4 py-1.5 custom-input black-text w-[264px]'>
              <div>
                <img src={search} alt=''></img>
              </div>
              <input placeholder='Search Shipment Tasks' onChange={(e) => handleSearch(e.target.value, 'search')} />
            </div>

            {/* filter */}
            <div
              className='cursor-pointer px-4 flex items-center gap-x-1 border border-[#e5e7eb] rounded  h-[37.6px] mt-[-20px]  mb-[-20px]'
              onClick={() => {
                setFilterDisplay(true)
              }}
            >
              {/* image */}
              <div>
                <img className='min-w-[16px]' src={filter} alt=''></img>
              </div>
              {/* filter */}
              <p className='text-xs text-[#9CA3AF]'>Filter</p>
            </div>
          </div>

          <div className=' desktop-only'>
            <ExportCsv csvBookingData={csvBookingData ? csvBookingData : csvData} name='task_report' />
          </div>
        </div>
      </div>
      <div>
        <Table
          //   @ts-ignore
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            total: total_tasks,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            current: page + 1,
            showSizeChanger: true,
            pageSizeOptions: ['10', '15', '20', '30'],
            onChange: (page, pageSize) => {
              handlePageChange(page - 1)
              handlePerRowsChange(pageSize, page - 1)
              // console.log("page", page - 1);
              // console.log("pageSize", pageSize);
            },
          }}
          scroll={{ x: 1500 }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
        {isOpen && <TaskModal isOpen={isOpen} setIsOpen={setIsOpen} taskData={selectedData} />}
      </div>
    </div>
  )
}

export default TasksAntTable
