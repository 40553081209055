import { capitalizeFirstLetter } from './capitalizeFirstLetter'

export const getMultipleServiceCombinations = (shipment_details) => {
  const { air_freight, customs_brokerage, with_haulage, ocean_freight, with_tracker, warehousing } = shipment_details

  const processMap = {
    ocean_freight: 'Ocean Freight',
    customs_brokerage: 'CB',
    with_haulage: 'Haulage',
    air_freight: 'Air Freight',
    with_tracker: 'Tracking',
    warehousing: 'Warehousing',
  }

  let combo = ''

  if (air_freight) {
    combo += `${processMap['air_freight']} X `
  }
  if (ocean_freight) {
    combo += `${processMap['ocean_freight']} X `
  }
  if (customs_brokerage) {
    combo += `${processMap['customs_brokerage']} X `
  }
  if (with_haulage) {
    combo += `${processMap['with_haulage']} X `
  }
  if (warehousing) {
    combo += `${processMap['warehousing']} X `
  }
  if (with_tracker) {
    combo += `${processMap['with_tracker']} X `
  }

  return combo.slice(0, -2)
}
